import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Container, Box, Typography, Button, Stack } from '@mui/material'
import { styled } from '@mui/material/styles';
import RoutePathNames from 'routes/routePathNames'
import { getHeaderLinks } from 'routes'
import { useAuthContext } from 'context/auth-context'
import Logo from 'static/images/logo.png'
import { CookieConsent } from 'react-cookie-consent'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { LightTooltip } from 'components/styledComponents/LightTooltip';

const logoStyle = {
  width: '180px',
  height: 'auto',
  cursor: 'pointer',
};

const containedSx = {
  fontWeight: 'fontWeightBold',
  bgcolor: 'common.white',
  color: 'primary.main',
  '&:hover': {
    bgcolor: 'common.white',
  },
}

const outlinedSx = {
  fontWeight: 'fontWeightBold',
  color: 'common.white',
  '&:hover': {
    bgcolor: 'common.white',
    color: 'primary.main',
  },
}

const urlsThatShouldNotBeRedirectedBackTo = [
  '/privacy-policy',
  '/terms-conditions',
  '/auth/welcome',
  '/auth/registration/company',
  '/auth/registration/consultant',
  '/auth/registration/manager',
  '/auth/login',
  '/auth/forgot-password',
  '/auth/reset-password',
];

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  bgcolor: 'primary.main',
  '@media all': {
    minHeight: 84,
  },
}));

export default function Header() {
  const location = useLocation();
  // If the user pressed login on a page not included in the list, redirect to that url after login
  const pathname = urlsThatShouldNotBeRedirectedBackTo.includes(location.pathname) ? "" : location.pathname;

  const navigate = useNavigate();

  const { t } = useTranslation()

  const isJobListingsRoute = useMatch(RoutePathNames.consultant.jobListings)

  const isConsultantListingsRoute = useMatch(RoutePathNames.company.consultantListings)
  const isCompanyCreateAssignmentRoute = useMatch(RoutePathNames.company.assignment.create)

  const isManagerConsultantJobListingsRoute = useMatch(
    RoutePathNames.manager.consultantJobListings
  )
  const isManagerConsultantListingsRoute = useMatch(RoutePathNames.manager.consultantListings)
  const isManagerCreateConsultantRoute = useMatch(RoutePathNames.manager.consultant.create)

  const uploadCvs = useMatch(RoutePathNames.consultant.uploadCvs)

  const {
    isAuth,
    role,
    isUserRoleConsultant,
    isUserRoleCompany,
    isUserRoleManager,
    isUserRoleAdmin,
    logout,
    isCompanyProfileComplete
  } = useAuthContext()

  const headerLinks = useMemo(() => getHeaderLinks(role), [role])

  const getLink = useCallback(() => {
    if (isUserRoleConsultant) {
      return RoutePathNames.consultant.home
    } else if (isUserRoleCompany) {
      return RoutePathNames.company.home
    } else if (isUserRoleManager) {
      return RoutePathNames.manager.home
    } else {
      return ''
    }
  }, [isUserRoleCompany, isUserRoleConsultant, isUserRoleManager])

  const handleLogout = () => {
    logout()
    navigate(RoutePathNames.auth.login);
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar position="static">
      <CookieConsent
        buttonStyle={{ borderRadius: '20px', backgroundColor: '#cf6' }}
        location="bottom"
      >
        {t('general.siteCookies1')}
        <Link
          to={RoutePathNames.privacyPolicy}
          style={{ color: '#fff' }}
          
        >
          <u>{t('general.privacyPolicy')}</u>
        </Link>
        {t('general.siteCookies2')}
      </CookieConsent>
      <Container
        maxWidth="2lg"
        sx={{
          px: { xs: 1, sm: 2, lg: 10 }
        }}
      >
        <StyledToolbar disableGutters>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexGrow: 1 }}
          >
            <Stack direction="row" spacing={2} >
              <Link to={getLink()}>
                <img
                  id="header-benchy"
                  src={Logo}
                  style={logoStyle}
                  alt="Logo of Benchy"
                />
              </Link>
              {!uploadCvs && (
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {isAuth &&
                    headerLinks.map(({ path, label }, index, arr) => {
                      const lastIndex = arr.length - 1

                      if (index === lastIndex && !isUserRoleAdmin) {
                        return null
                      }
                      return (
                        <MenuItem
                          sx={{ py: '6px', px: '12px' }}
                          key={path}
                        >
                          <NavLink to={path}>
                            {({ isActive }) => {
                              return (
                                <Typography
                                  id={`header-${label}`}
                                  variant="body"
                                  color="white"
                                  sx={{ opacity: isActive ? 1 : 0.4, '&:hover': { opacity: 1 } }}
                                >
                                  {label ? t(label) : ''}
                                </Typography>
                              )
                            }}
                          </NavLink>
                        </MenuItem>
                      )
                    })}
                </Box>
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {isAuth && isUserRoleConsultant && (
                <>
                  {!isJobListingsRoute && (
                    <Link to={RoutePathNames.consultant.jobListings}>
                      <Button id="header-find-assignments" variant="contained" size="large" sx={containedSx}>
                        {t('Common.FindAssignments')}
                      </Button>
                    </Link>
                  )}
                </>
              )}

              {isAuth && isUserRoleCompany && (
                <>
                  {!isConsultantListingsRoute && (
                    <Link to={RoutePathNames.company.consultantListings}>
                      <Button id="header-find-consultants" variant="outlined" size="large" sx={outlinedSx}>
                        {t('Common.FindConsultants')}
                      </Button>
                    </Link>
                  )}
                  {!isCompanyCreateAssignmentRoute && (
                    <LightTooltip
                      arrow
                      placement='top'
                      title={isCompanyProfileComplete ? '' : t('ProfileCommon.CompleteYourProfileFirst')}
                    >
                      <div>
                        <Button
                          component={Link}
                          to={RoutePathNames.company.assignment.create}
                          id="header-create-assignment"
                          disabled={!isCompanyProfileComplete}
                          variant="contained"
                          size="large"
                          sx={containedSx}
                        >
                          {t('Common.CreateAssignments')}
                        </Button>
                      </div>
                    </LightTooltip>
                  )}
                </>
              )}

              {isAuth && isUserRoleManager && (
                <>
                  {!isManagerConsultantJobListingsRoute && (
                    <Link to={RoutePathNames.manager.consultantJobListings}>
                      <Button id="header-find-assignments" variant="outlined" size="large" sx={outlinedSx}>
                        {t('Common.FindAssignments')}
                      </Button>
                    </Link>
                  )}
                  {!isManagerConsultantListingsRoute && (
                    <Link to={RoutePathNames.manager.consultantListings}>
                      <Button id="header-find-consultants" variant="outlined" size="large" sx={outlinedSx}>
                        {t(isUserRoleAdmin
                        ? 'Common.FindConsultants'
                        : 'Common.YourConsultants'
                        )}
                      </Button>
                    </Link>
                  )}
                  {!isManagerCreateConsultantRoute && (
                    <Link to={RoutePathNames.manager.consultant.create}>
                      <Button id="header-create-consultant" variant="contained" size="large" sx={containedSx}>
                        {t('general.createConsultant')}
                      </Button>
                    </Link>
                  )}
                </>
              )}
              {!isAuth ? (
                <>
                  <Link
                    to={RoutePathNames.auth.login}
                    state={{ pathname }}
                  >
                    <Button id="header-login" variant="outlined" size="large" sx={outlinedSx}>
                      {t('Registration.LogIn')}
                    </Button>
                  </Link>
                </>
              ) : (
                <Button
                  id="header-logout"
                  variant="outlined"
                  size="large"
                  sx={{ ...outlinedSx, ml: 2 }}
                  onClick={handleLogout}
                >
                  {t('Common.LogOut')}
                </Button>
              )}
            </Stack>
            <Box sx={{ display: { xs: '', md: 'none' }, flexDirection: 'column' }}>
              <Button
                id="mobile-navbar"
                color="primary"
                aria-label="menu"
                variant="contained"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon sx={{ fontSize: '36px' }}/>
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  onClick={toggleDrawer(false)}
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'primary.main',
                    flexGrow: 1,
                    justifyContent: 'flex-start',
                  }}
                >
                  {isAuth &&
                    headerLinks.map(({ path, label }, index, arr) => {
                      const lastIndex = arr.length - 1

                      if (index === lastIndex && !isUserRoleAdmin) {
                        return null
                      }
                      return (
                        <MenuItem
                          sx={{ py: '6px', px: '12px' }}
                          key={path}
                        >
                          <NavLink to={path}>
                            {({ isActive }) => {
                              return (
                                <Typography
                                  variant="body"
                                  color="white"
                                  sx={{ opacity: isActive ? 1 : 0.4, '&:hover': { opacity: 1 } }}
                                >
                                  {label ? t(label) : ''}
                                </Typography>
                              )
                            }}
                          </NavLink>
                        </MenuItem>
                      )
                    })}
                  <Divider color="white" />
                  {isAuth && isUserRoleConsultant && (
                    <>
                      {!isJobListingsRoute && (
                        <MenuItem>
                          <Link to={RoutePathNames.consultant.jobListings}>
                            <Button id="mobile-header-find-assignments" variant="contained" size="large" sx={containedSx}>
                              {t('Common.FindAssignments')}
                            </Button>
                          </Link>
                        </MenuItem>
                      )}
                    </>
                  )}
                  {isAuth && isUserRoleCompany && (
                    <Box sx={{ gap: 2 }}>
                      {!isConsultantListingsRoute && (
                        <MenuItem>
                          <Link to={RoutePathNames.company.consultantListings}>
                            <Button id="mobile-header-find-consultants" variant="outlined" size="large" sx={outlinedSx}>
                              {t('Common.FindConsultants')}
                            </Button>
                          </Link>
                        </MenuItem>
                      )}
                      {!isCompanyCreateAssignmentRoute && (
                        <MenuItem>
                          <LightTooltip
                            arrow
                            placement='top'
                            title={isCompanyProfileComplete ? '' : t('ProfileCommon.CompleteYourProfileFirst')}
                          >
                            <div>
                              <Button
                                component={Link}
                                to={RoutePathNames.company.assignment.create}
                                id="mobile-header-create-assignment"
                                variant="contained"
                                size="large"
                                sx={containedSx}
                                disabled={!isCompanyProfileComplete}
                              >
                                {t('Common.CreateAssignments')}
                              </Button>
                            </div>
                          </LightTooltip>
                        </MenuItem>
                      )}
                    </Box>
                  )}

                  {isAuth && isUserRoleManager && (
                    <Box sx={{ gap: 2 }}>
                      {!isManagerConsultantJobListingsRoute && (
                        <MenuItem>
                          <Link to={RoutePathNames.manager.consultantJobListings}>
                            <Button id="mobile-header-find-assignments" variant="outlined" size="large" sx={outlinedSx}>
                              {t('Common.FindAssignments')}
                            </Button>
                          </Link>
                        </MenuItem>
                      )}
                      {!isManagerConsultantListingsRoute && (
                        <MenuItem>
                          <Link to={RoutePathNames.manager.consultantListings}>
                          <Button id="mobile-header-find-consultants" variant="outlined" size="large" sx={outlinedSx}>
                            {t(isUserRoleAdmin ?
                              'Common.FindConsultants' :
                              'Common.YourConsultants'
                            )}
                        </Button>
                          </Link>
                        </MenuItem>
                      )}
                      {!isManagerCreateConsultantRoute && (
                        <MenuItem>
                          <Link to={RoutePathNames.manager.consultant.create}>
                            <Button id="mobile-header-create-consultant" variant="contained" size="large" sx={containedSx}>
                              {t('general.createConsultant')}
                            </Button>
                          </Link>
                        </MenuItem>
                      )}
                    </Box>
                  )}
                  {!isAuth ? (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <MenuItem>
                        <Link
                          to={RoutePathNames.auth.login}
                          state={{ pathname }}
                        >
                          <Button id="mobile-header-login" variant="outlined" size="large" sx={outlinedSx}>
                            {t('Registration.LogIn')}
                          </Button>
                        </Link>
                      </MenuItem>
                    </Box>
                  ) : (
                    <MenuItem>
                      <Button
                        id="mobile-header-logout"
                        variant="outlined"
                        size="large"
                        sx={{ ...outlinedSx }}
                        onClick={handleLogout}
                      >
                        {t('Common.LogOut')}
                      </Button>
                    </MenuItem>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Stack>
        </StyledToolbar>
      </Container>
    </AppBar>
  )
}
