import { QueryFunctionContext, useQuery } from 'react-query'
import { Role } from '../utils'
import { api } from '../api'
import { UserCvsBody } from '../cvs/queries'

type UserRole = {
  role_id: number
  title: Role
}

export type User = {
  user_id: number | string
  name: string
  profile_photo: string | null
  email: string
  role: UserRole
  password: string
  cvsBody: UserCvsBody
}

export type UpdateUser = Partial<User>

export type ApplyForJob = {
  job_id: string | number
}

export type ContactConsultant = {
  consultant_id: string | number
}

export const userKeys = {
  user: (isAuth = true) => ['user', isAuth] as const,
  applyForJob: () => [...userKeys.user(), 'apply-for-a-job'] as const,
  latestAssignment: () => [...userKeys.user(), 'latest-assignment'] as const,
  contactConsultant: () => [...userKeys.user(), 'contact-consultant'] as const,
  deleteProfile: () => [...userKeys.user(), 'delete-profile'] as const,
  createCv: () => [...userKeys.user(), 'create-cv'] as const,
  sendVerifyEmailRequest: () => [...userKeys.user(), 'send-verify-email-request'] as const,
  renewToken: () => [...userKeys.user(), 'renew-token'] as const
}

export const fetchUser = async ({ queryKey }: QueryFunctionContext): Promise<User | undefined> => {
  const isAuth = queryKey[1]

  if (isAuth) {
    const response = await api.get('user')
    return response.data
  }

  return Promise.resolve(undefined)
}

export const fetchLatestAssignment = async () => {
  const response = await api.get('user/get-latest-assignment')
  return response.data
}

export const useUserQuery = (isAuth = true) =>
  useQuery(userKeys.user(isAuth), fetchUser, {
    refetchOnWindowFocus: false,
  })

export const useLatestAssignmentsQuery = () =>
  useQuery(userKeys.latestAssignment(), fetchLatestAssignment)
