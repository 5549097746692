import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FastFieldProps, useField, FastField } from 'formik'
import { NumericFormat, NumberFormatValues, NumericFormatProps } from 'react-number-format'
import { Typography, TextField } from '@mui/material'

const defaultSetFormattedValue = (formattedValue: NumberFormatValues) =>
  formattedValue?.floatValue !== undefined && formattedValue.floatValue >= 0
    ? formattedValue?.floatValue
    : ''

type NumericFormatComponentProps = Omit<FastFieldProps, 'form'> &
  Omit<NumberFieldProps, 'name' | 'setFormattedValue'>

function NumericFormatComponent({
  id,
  field,
  meta,
  type,
  label = '',
  inputMode = 'numeric',
  allowNegative = false,
  valueIsNumericString = true,
  decimalScale = 0,
  placeholder = '',
  suffix,
  fullWidth,
  onValueChange,
}: NumericFormatComponentProps) {
  const error = meta.touched && meta.error

  const { t } = useTranslation()

  return (
    <NumericFormat
      type={type}
      label={label}
      allowNegative={allowNegative}
      valueIsNumericString={valueIsNumericString}
      decimalScale={decimalScale}
      placeholder={placeholder}
      suffix={suffix ? t(suffix, { count: field.value }) : ''}
      inputMode={inputMode}
      name={field.name}
      id={id}
      value={field.value}
      onBlur={field.onBlur}
      onValueChange={onValueChange}
      error={!!error}
      helperText={error ? t(`${error}`) : ' '}
      fullWidth={fullWidth}
      customInput={TextField}
    />
  )
}

type NumberFieldProps = Omit<NumericFormatProps, 'form'> & {
  name: string
  id: string
  label?: string
  customLabel?: string
  suffix?: string
  fullWidth?: boolean
  placeholder?: string
  setFormattedValue?: (formattedValue: NumberFormatValues) => number | string | undefined
}

export default function NumberField({
  name,
  id,
  customLabel,
  setFormattedValue = defaultSetFormattedValue,
  ...restProps
}: NumberFieldProps) {
  const [field, meta, helpers] = useField({ name })

  const onValueChange = useCallback(
    (formattedValue: NumberFormatValues) => {
      helpers.setValue(setFormattedValue(formattedValue))
    },
    [helpers, setFormattedValue]
  )

  return (
    <>
      {customLabel && (
        <Typography variant="h5" mb={1.5}>
          {customLabel}
        </Typography>
      )}
      <NumericFormatComponent
        id={id}
        field={field}
        meta={meta}
        onValueChange={onValueChange}
        {...restProps}
      />
    </>
  )
}

type FastNumericFormatFieldProps = NumberFieldProps

export function FastNumericFormatField({
  name,
  id,
  setFormattedValue = defaultSetFormattedValue,
  ...restProps
}: FastNumericFormatFieldProps) {
  const onValueChange = useCallback(
    ({ form, field }: FastFieldProps) =>
      (formattedValue: NumberFormatValues) => {
        form.setFieldValue(field.name, setFormattedValue(formattedValue))
      },
    [setFormattedValue]
  )

  return (
    <FastField name={name}>
      {(props: FastFieldProps) => (
        <NumericFormatComponent id={id} onValueChange={onValueChange(props)} {...props} {...restProps} />
      )}
    </FastField>
  )
}
