import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useField, FastFieldProps, FastField } from 'formik'
import { NumberFormatValues, PatternFormat, PatternFormatProps } from 'react-number-format'
import { TextField } from '@mui/material'

const defaultSetFormattedValue = (formattedValue: NumberFormatValues) =>
  formattedValue?.formattedValue || ''

type PatternFormatComponentProps = Omit<FastFieldProps, 'form'> &
  Omit<PatternFormatFieldProps, 'name' | 'setFormattedValue'>

function PatternFormatComponent({
  id,
  field,
  meta,
  label = '',
  inputMode = 'numeric',
  format,
  allowEmptyFormatting = false,
  valueIsNumericString = false,
  placeholder = '',
  fullWidth,
  onValueChange,
}: PatternFormatComponentProps) {
  const error = meta.touched && meta.error

  const { t } = useTranslation()

  return (
    <PatternFormat
      label={label}
      format={format}
      allowEmptyFormatting={allowEmptyFormatting}
      valueIsNumericString={valueIsNumericString}
      placeholder={placeholder}
      inputMode={inputMode}
      name={field.name}
      id={id}
      value={field.value}
      onBlur={field.onBlur}
      onValueChange={onValueChange}
      error={!!error}
      helperText={error ? t(`${error}`) : ' '}
      fullWidth={fullWidth}
      customInput={TextField}
    />
  )
}

type PatternFormatFieldProps = Omit<PatternFormatProps, 'form'> & {
  name: string
  id: string
  label?: string
  customLabel?: string
  fullWidth?: boolean
  placeholder?: string
  setFormattedValue?: (formattedValue: NumberFormatValues) => number | string | undefined
}

export default function PatternFormatField({
  name,
  id,
  setFormattedValue = defaultSetFormattedValue,
  ...restProps
}: PatternFormatFieldProps) {
  const [field, meta, helpers] = useField({ name })

  const onValueChange = useCallback(
    (formattedValue: NumberFormatValues) => {
      helpers.setValue(setFormattedValue(formattedValue))
    },
    [helpers, setFormattedValue]
  )

  return (
    <PatternFormatComponent
      id={id}
      field={field}
      meta={meta}
      onValueChange={onValueChange}
      {...restProps}
    />
  )
}

type FastPatternFormatFieldProps = PatternFormatFieldProps

export function FastPatternFormatField({
  name,
  id,
  setFormattedValue = defaultSetFormattedValue,
  ...restProps
}: FastPatternFormatFieldProps) {
  const onValueChange = useCallback(
    ({ form, field }: FastFieldProps) =>
      (formattedValue: NumberFormatValues) => {
        form.setFieldValue(field.name, setFormattedValue(formattedValue))
      },
    [setFormattedValue]
  )

  return (
    <FastField name={name}>
      {(props: FastFieldProps) => (
        <PatternFormatComponent id={id} onValueChange={onValueChange(props)} {...props} {...restProps} />
      )}
    </FastField>
  )
}
