import React from 'react'
import { useMatch, Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import RoutePathNames from 'routes/routePathNames'
import Box from '@mui/material/Box'
import DefaultFallback from '../components/common/DefaultFallback'
import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'

export default function MainLayout() {
  const isUploadCvRoute = useMatch(RoutePathNames.consultant.uploadCvs)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Box flexGrow={1}>
          <ErrorBoundary FallbackComponent={DefaultFallback}>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
      {!isUploadCvRoute && <Footer />}
    </Box>
  )
}
