import React, { useEffect, useRef } from 'react'
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { SuccessDto } from 'api/types';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutePathNames from 'routes/routePathNames';
import { useAuthContext } from 'context/auth-context';
import CenteredProgress from 'components/common/CenteredProgress';

export default function VerifyEmailResult() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const stateRef = useRef<SuccessDto | null | undefined>(state);

  const { isUserRoleConsultant, isUserRoleManager, isUserRoleCompany } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (stateRef.current) {
      window.history.replaceState(null, '', pathname);
    } else {
      let homePath = '';
      if (isUserRoleConsultant) {
        homePath = RoutePathNames.consultant.home;
      } else if (isUserRoleCompany) {
        homePath = RoutePathNames.company.home;
      } else if (isUserRoleManager) {
        homePath = RoutePathNames.manager.home;
      }
      navigate(homePath, { replace: true });
    }
  }, [])
  
  return (
    stateRef.current
    ?
    <Stack
      mt={2}
      direction="column"
      spacing={2}
      alignItems="center"
    >
      {stateRef.current.success
      ?
      <>
        <VerifiedUserIcon
          sx={{ width: 80, height: 80, mx: 'auto' }}
        />
        <Typography variant="h3">
          {stateRef.current.msg}
        </Typography>
        <Typography component="div" variant="body" color="primary.60">
          {t('verifyEmail.canBeContacted')}
        </Typography>
        <Typography component="div" variant="body" color="primary.60">
          {t('verifyEmail.toSeeChanges')}
        </Typography>
      </>
      :
      <Typography variant="h3" align="center">
        {t('verifyEmail.somethingWentWrong')}
      </Typography>
      }
    </Stack>
    :
    <CenteredProgress/>
  )
}
