import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import InputField from '../InputField';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAiAssignmentEnhancementMutation, useAiResumeEnhancementMutation } from 'api/ai/mutations';

export enum EnhanceType {
    Assignment,
    Resume
};

type AiEnhancedTextFieldProps = {
    name: string;
    id: string;
    label: string;
    enhanceType: EnhanceType;
    sx?: SxProps;
};

const AiEnhancedTextField: React.FC<AiEnhancedTextFieldProps> = ({ name, id, label, enhanceType, sx }: AiEnhancedTextFieldProps) => {
    const { getFieldMeta, setFieldValue } = useFormikContext();

    const useAiEnhancementMutation = enhanceType === EnhanceType.Resume
        ? useAiResumeEnhancementMutation
        : useAiAssignmentEnhancementMutation;

    const { data, isLoading, mutateAsync } = useAiEnhancementMutation();

    const [showResetButton, setShowResetButton] = useState(false);
    const initialValueRef = useRef<string>('');

    const { t } = useTranslation();

    const fieldValue: any = getFieldMeta(name).value;

    const onEnhance = async () => {
        if (!initialValueRef.current && fieldValue) {
            initialValueRef.current = fieldValue;
        }
        await mutateAsync({ prompt: fieldValue });
        setShowResetButton(true);
    };

    useEffect(() => {
        if (data) {
            setFieldValue(name, data);
        }
    }, [data]);

    const onReset = () => {
        setShowResetButton(false);
        setFieldValue(name, initialValueRef.current);
        initialValueRef.current = '';
    };

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    border: 1,
                    borderColor: 'grey.400',
                    borderRadius: 1,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    ...sx
                }}
            >
                <InputField
                    name={name}
                    id={`${id}-input`}
                    label={label}
                    multiline
                    variant="standard"
                    disabled={isLoading}
                    fullWidth
                />
                {isLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress
                            size={48}
                            sx={{ color: '#ff561e' }}
                        />
                        <Typography
                            sx={{
                                mt: 2,
                                color: '#ffffff',
                                backgroundColor: 'rgba(255, 86, 30, 0.9)',
                                padding: '8px',
                                borderRadius: '4px',
                                maxWidth: '300px',
                                fontSize: '0.9rem',
                                textAlign: 'center',
                            }}
                        >
                            {t('general.ai.loadingText')}
                        </Typography>
                    </Box>
                )}
                <Stack direction="row" justifyContent="space-between">
                    <Button
                        id={`${id}-enhance`}
                        variant="contained"
                        onClick={onEnhance}
                        disabled={isLoading || fieldValue === ''}
                        sx={{
                            backgroundColor: '#ff561e',
                            color: '#ffffff',
                            '&:hover': { backgroundColor: '#eb420a' },
                            '&:disabled': { backgroundColor: 'rgba(255, 86, 30, 0.5) !important' }
                        }}
                    >
                        {t('general.ai.enhance')}
                    </Button>
                    {showResetButton && (
                        <Button
                            id={`${id}-reset`}
                            variant="outlined"
                            onClick={onReset}
                            disabled={isLoading}
                            sx={{
                                borderColor: 'rgba(255, 86, 30, 0.5)',
                                color: '#ff561e',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 86, 30, 0.05)',
                                    borderColor: '#ff561e'
                                },
                                '&:disabled': {
                                    borderColor: 'rgba(255, 86, 30, 0.3)',
                                    color: 'rgba(255, 86, 30, 0.3)'
                                }
                            }}
                        >
                            {t('general.ai.reset')}
                        </Button>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};

export default AiEnhancedTextField;
