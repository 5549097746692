import React from 'react'
import { Formik, Form, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Typography, Stack, Button } from '@mui/material'
import * as schemas from 'validation/schemas'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import ScrollToError from 'components/common/ScrollToError'
import StyledPasswordStrengthBar from 'components/common/StyledPasswordStrengthBar'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import InputField from 'components/ui/FormikFields/InputField'
import PasswordField from 'components/ui/FormikFields/PasswordField'
import CheckboxField from 'components/ui/FormikFields/CheckboxField'

export const validSchema = Yup.object().shape({
  email: Yup.string().email('Registration.EnterValidEmail').required('Registration.EnterEmail'),
  password: schemas.requiredPassword(),
  confirmPassword: Yup.string()
    .required('Registration.EnterConfirmPass')
    .oneOf([Yup.ref('password'), null], 'Registration.PassMustMatch'),
  agreement: Yup.boolean()
    .required('Registration.Agreement')
    .oneOf([true], 'Registration.Agreement'),
})

export const initValues = {
  email: '',
  password: '',
  confirmPassword: '',
  agreement: false,
}

export default function RegistrationForm<T extends typeof initValues>({
  validationSchema = validSchema,
  initialValues = initValues as T,
  onSubmit,
}: FormikConfig<T>) {
  const { isUserRoleManager, isUserRoleCompany } = useAuthContext()

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  const { t } = useTranslation()

  return (
    <Formik
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, dirty, isSubmitting, values } = formikProps
        return (
          <AuthBoxWrapper>
            <ScrollToError />
            <Form onSubmit={handleSubmit}>
              <Typography variant="h3" align="center">
                {t('Registration.CreateAccount')}
              </Typography>
              <Stack direction="column" spacing={1} mt={4} mb={1}>
                {isUserRoleManager ? (
                  <>
                    <InputField id="manager-name" name="manager_name" label={t('Common.FullName')} fullWidth />
                    <InputField id="org-number" name="org_number" label={t('Common.OrgNumber')} fullWidth />
                  </>
                ) : (
                  <InputField id="company-name" name="company_name" label={t('Common.CompanyName')} fullWidth />
                )}
              </Stack>
              <Stack direction="column" spacing={1} mb={1}>
                {isUserRoleCompany && (
                  <InputField id="name" name="name" label={t('Common.FullName')} fullWidth />
                )}
                <InputField id="email" name="email" label={t('Common.Email')} fullWidth />
                <div>
                  <PasswordField name="password" label={t('Common.Password')} fullWidth />
                  {values.password && <StyledPasswordStrengthBar password={values.password} />}
                </div>
                <PasswordField
                  name="confirmPassword"
                  label={t('Common.ConfirmPassword')}
                  fullWidth
                />
              </Stack>
              {isUserRoleManager &&
                <Stack direction="column" spacing={1}>
                  <InputField id="address-1" name="address_line" label={t('Common.Address1')} fullWidth />
                  <InputField id="address-2" name="address_line_2" label={t('Common.Address2')} fullWidth />
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 1.5 }}
                  >
                    <InputField id="city" name="city" label={t('Common.City')} fullWidth />
                    <InputField id="zip-code" name="zip_code" label={t('Common.ZipCode')} fullWidth />
                  </Stack>
                  <InputField id="country" name="country" label={t('Common.Country')} fullWidth />
                </Stack>
              }
              <Stack direction="row" >
                <CheckboxField id="agreement" name="agreement" color="secondary" />
                <label htmlFor="agreement" style={{ marginTop: 10 }}>
                  {t('Registration.AgreeToTerms')}{' '}
                  <b>
                    <Link to={RoutePathNames.termsConditions} target="_blank">
                      {t('Registration.TermsAndConditions')}
                    </Link>
                  </b>
                </label>
              </Stack>
              <Button
                id="create-account"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                sx={{ mt: 1.5, mb: 4 }}
                disabled={!dirty || isSubmitting}
                onClick={manualSubmitForm(formikProps)}
              >
                {t('Registration.CreateAccount')}
              </Button>
              <Typography component="div" variant="body" align="center">
                {t('Registration.AlreadyHaveAccount')}{' '}
                <Link to={RoutePathNames.auth.login}>
                  <b id="login">{t('Registration.LogIn')}</b>
                </Link>
              </Typography>
            </Form>
          </AuthBoxWrapper>
        )
      }}
    </Formik>
  )
}
