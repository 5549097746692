import React from 'react'
import RoutePathNames from './routePathNames'
import Welcome from 'pages/auth/welcome'
import RegistrationCompany from 'pages/auth/registration/company'
import RegistrationConsultant from 'pages/auth/registration/consultant'
import RegistrationManager from 'pages/auth/registration/manager'
import Login from 'pages/auth/login'
import ForgotPassword from 'pages/auth/forgotPassword'
import ResetPassword from 'pages/auth/resetPassword'
import VerifyEmail from 'pages/auth/verifyEmail'
import VerifyEmailResult from 'pages/auth/verifyEmailResult'

const {
  welcome,
  registrationCompany,
  registrationConsultant,
  registrationManager,
  login,
  forgotPassword,
  resetPassword,
  verifyEmail,
  verifyEmailResult,
} = RoutePathNames.auth

const authRoutes = [
  {
    path: welcome,
    element: <Welcome />,
  },
  {
    path: registrationCompany,
    element: <RegistrationCompany />,
  },
  {
    path: registrationConsultant,
    element: <RegistrationConsultant />,
  },
  {
    path: registrationManager,
    element: <RegistrationManager />,
  },
  {
    path: login,
    element: <Login />,
  },
  {
    path: forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: resetPassword,
    element: <ResetPassword />,
  },
  {
    path: verifyEmail,
    element: <VerifyEmail />,
  },
  {
    path: verifyEmailResult,
    element: <VerifyEmailResult />,
  },
]

export const getAuthRoutes = () => authRoutes
