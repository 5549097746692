import { MutableRefObject, useState, useEffect, useMemo, useCallback } from 'react'

export default function useValidateAfterFirstSubmit(
  btnRef?: MutableRefObject<HTMLButtonElement | null>
) {
  const [validateOnBlur, setValidateOnBlur] = useState(false)
  const [validateOnChange, setValidateOnChange] = useState(false)

  const onSubmit = useCallback(
    ({
        submitForm,
        submitCount,
      }: {
        submitForm: () => void
        submitCount: number
        setSubmitting: (isSubmitting: boolean) => void
      }) =>
      async () => {
        await submitForm()
        if (submitCount === 0) {
          setValidateOnBlur(true)
          setValidateOnChange(true)
        }
      },
    []
  )

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.key === 'Enter' && btnRef) {
        event.preventDefault()
        btnRef.current?.click()
      }
    }

    if (btnRef) {
      document.addEventListener('keydown', onKeyDown)
    }

    return () => {
      if (btnRef) {
        document.removeEventListener('keydown', onKeyDown)
      }
    }
    // eslint-disable-next-line
  }, [])

  return useMemo(
    () => ({
      validateOnBlur,
      validateOnChange,
      onSubmit,
    }),
    [validateOnBlur, validateOnChange, onSubmit]
  )
}
