import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik'
import * as yup from 'yup'
import { enqueueSnackbar } from 'notistack'
// import RoutePathNames from 'routes/routePathNames';
import { useManagerByUserIdQuery } from 'api/manager/queries'
import { useUpdateManagerMutation, UpdateManager } from 'api/manager/mutations'
import { useAuthContext } from 'context/auth-context'
import useUploadProfileCoverPhotos from 'hooks/useUploadProfileCoverPhotos'
import SettingsForm, { validSchema, initValues } from 'components/common/forms/SettingsForm'
import { useNavigate } from 'react-router-dom'
import RoutePathNames from 'routes/routePathNames'
import { setVerifyEmailReminder } from 'api/utils'

const validationSchema = validSchema.shape({
  manager_name: yup.string().min(2, 'Common.CharsMinimum2').required('manager.enterManagerName'),
  manager_description: yup.string().min(5, 'Common.CharsMinimum5').required('manager.enterDesc'),
})

const initialValues = {
  ...initValues,
  manager_name: '',
  manager_description: '',
}

type FormValues = typeof initialValues

export default function ManagerSettings() {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const {
    user: { data: userData },
    renewToken
  } = useAuthContext()

  const { data: managerData } = useManagerByUserIdQuery()

  const manager = useUpdateManagerMutation()

  const { uploadPhoto } = useUploadProfileCoverPhotos()

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { confirmNewPassword, profile_photo, cover_picture, newPassword, ...restValues } = values

    const newManagerData: UpdateManager = {
      ...restValues,
    }

    if (newPassword) {
      newManagerData.password = newPassword
    }

    try {
      //Photo processing logo - uploading to amazon / deleting from amazon / or returning the old value
      const profilePhotoData = await uploadPhoto(profile_photo, managerData?.profile_photo)
      newManagerData.profile_photo = profilePhotoData.photo
      //Photo processing cover - uploading to amazon / deleting from amazon / or returning the old value
      const coverPictureData = await uploadPhoto(cover_picture, managerData?.cover_picture)
      newManagerData.cover_picture = coverPictureData.photo

      await manager.mutateAsync(newManagerData, {
        onSuccess: () => {
          setSubmitting(false)
          enqueueSnackbar(t('general.changesAreSaved'), { variant: 'success' })
          renewToken()

          if (userData?.email !== values.email) {
            setVerifyEmailReminder(true)
          }
          navigate(RoutePathNames.manager.home)
        },
      })
    } catch (err) {}
  }

  const initialValues = useMemo(
    () => ({
      // isNewPassword: '',
      manager_name: (managerData && managerData.manager_name) ?? '',
      org_number: (managerData && managerData.org_number) ?? '',
      address_line: (managerData && managerData.address_line) ?? '',
      address_line_2: (managerData && managerData.address_line_2) ?? '',
      city: (managerData && managerData.city) ?? '',
      zip_code: (managerData && managerData.zip_code) ?? '',
      country: (managerData && managerData.country) ?? '',
      manager_description: (managerData && managerData.manager_description) ?? '',
      profile_photo: {
        url: (managerData && managerData.profile_photo) ?? '',
        file: null,
      },
      cover_picture: {
        url: (managerData && managerData.cover_picture) ?? '',
        file: null,
      },
      name: (userData && userData?.name) ?? '',
      email: (userData && userData?.email) ?? '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [managerData, userData]
  )

  return (
    <SettingsForm<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}
