import React from 'react'
import { useConsultantCvQuery } from 'api/cvs/queries'
import CvForm from 'components/common/forms/CvForm'
import CenteredProgress from 'components/common/CenteredProgress'

export default function VerifyConsultantCv() {
  const consultantCv = useConsultantCvQuery()

  const { data: userCvData } = consultantCv

  return (userCvData === undefined
    ? <CenteredProgress />
    : <CvForm userCvBody={userCvData?.body} />
  )
}
