import React from 'react'
import { Outlet } from 'react-router-dom'
import RequireAdmin from 'routes/RequireAdmin'

export default function AdminPrivateLayout() {
  return (
    <RequireAdmin>
      <Outlet />
    </RequireAdmin>
  )
}
