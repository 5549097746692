import React from 'react'
import { useField } from 'formik'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    boxShadow: `0px 0px 0px 8px ${theme.palette.secondary[20]}`,
    '&:focus, &.Mui-active': {
      boxShadow: `0px 0px 0px 10px ${theme.palette.secondary[20]}`,
    },
  },
  '& .MuiSlider-valueLabel': {
    ...theme.typography.body2,
    fontSize: '12px',
    top: -12,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'block',
      width: '0px',
      height: '0px',
      bottom: '-1px',
      borderStyle: 'solid',
      marginLeft: '-5px',
      borderWidth: '3px 5px 0 5px',
      borderColor: '#FFFFFF transparent transparent transparent',
      transform: 'rotate(0deg)',
    },
    '& *': {
      // background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
    '& .MuiSlider-valueLabelLabel': {
      backgroundColor: '#fff',
      padding: '4px 4px',
      borderRadius: '6px',
      boxShadow: '0px 2px 5px 0px rgba(50, 50, 50, 0.25)',
    },
  },
  '& .MuiSlider-track': {},
  '& .MuiSlider-rail': {
    height: 6,
    opacity: 1,
    backgroundColor: theme.palette.secondary[20],
  },
  '& .MuiSlider-mark': {},
}))

type SliderFieldProps = {
  name: string
  id: string
  label?: string
  step?: number
  min?: number
  max?: number
  valueLabelFormat?: string | ((value: number, index: number) => React.ReactNode)
}

export default function SliderField({
  id,
  label,
  step = 1,
  min = 1,
  max = 10,
  valueLabelFormat,
  ...restProps
}: SliderFieldProps) {
  const [field, meta, helpers] = useField(restProps)

  return (
    <div>
      {label && <Typography variant="h5">{label}</Typography>}
      <StyledSlider
        sx={{ mt: '32px', maxWidth: '90%', ml: '10px' }}
        id={id}
        step={step}
        min={min}
        max={max}
        valueLabelDisplay="on"
        color="secondary"
        valueLabelFormat={valueLabelFormat}
        name={field.name}
        value={field.value}
        onChange={(event, value) => helpers.setValue(value)}
      />
    </div>
  )
}
