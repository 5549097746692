import { SuccessDto } from 'api/types'
import { api } from '../api'
import { useMutation } from 'react-query'

export type RegistrationConsultant = {
  name: string
  password: string
  email: string
  org_number?: string
}

export type RegistrationCompany = {
  name: string
  password: string
  email: string
  company_name: string
}

export type RegistrationManager = {
  manager_name: string
  name: string
  password: string
  email: string
  org_number: string
  address_line: string
  address_line_2?: string
  city: string
  zip_code: string
  country: string
}

export type Login = {
  email: string
  password: string
}

export type LoginAndRedirect = Login & {
  previousPath: string
}

export type ForgotPassword = {
  email: string
}

export type ResetPassword = {
  password: string
  uuid: string
}

export const authKeys = {
  auth: ['auth', 'registration'] as const,
  consultant: () => [...authKeys.auth, 'consultant'] as const,
  company: () => [...authKeys.auth, 'company'] as const,
  manager: () => [...authKeys.auth, 'manager'] as const,
  login: () => [authKeys.auth[0], 'login'] as const,
  forgotPassword: () => [authKeys.auth[0], 'forgot-password'] as const,
  resetPassword: () => [authKeys.auth[0], 'change-password-after-forgot'] as const,
  verifyEmail: () => [authKeys.auth[0], 'verify-email'] as const
}

export const registrationConsultant = async (body: RegistrationConsultant) => {
  const response = await api.post('auth/registration/consultant', body)
  return response.data
}

export const registrationCompany = async (body: RegistrationCompany) => {
  const response = await api.post('auth/registration/company', body)
  return response.data
}

export const registrationManager = async (body: RegistrationManager) => {
  const response = await api.post('auth/registration/manager', body)
  return response.data
}

export const login = async (body: Login) => {
  const response = await api.post('auth/login', body)
  return response.data
}

export const forgotPassword = async (body: ForgotPassword) => {
  const response = await api.post('auth/forgot-password', body)
  return response.data
}

export const resetPassword = async (body: ResetPassword) => {
  const response = await api.post('auth/change-password-after-forgot', body)
  return response.data
}

const verifyEmail = async (uuid: string): Promise<SuccessDto> => {
  const body = { uuid };

  const response = await api.post('auth/verify-email', body);

  return response.data
}

export const useRegistrationConsultantMutation = () =>
  useMutation(authKeys.consultant(), registrationConsultant)
export const useRegistrationCompanyMutation = () =>
  useMutation(authKeys.company(), registrationCompany)
export const useRegistrationManagerMutation = () =>
  useMutation(authKeys.manager(), registrationManager)
export const useLoginMutation = () => useMutation(authKeys.login(), login)
export const useForgotPasswordMutation = () =>
  useMutation(authKeys.forgotPassword(), forgotPassword)
export const useResetPasswordMutation = () => useMutation(authKeys.resetPassword(), resetPassword)
export const useVerifyEmailMutation = () =>
  useMutation(authKeys.verifyEmail(), verifyEmail);
