import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import * as Yup from 'yup'
import RoutePathNames from 'routes/routePathNames'
import { UpdateCompany } from 'api/company/queries'
import { useUpdateCompanyMutation } from 'api/company/mutations'
import SetUpProfileForm, { validSchema, initValues } from 'components/common/forms/SetUpProfileForm'
import useUploadProfileCoverPhotos from '../../../hooks/useUploadProfileCoverPhotos'
import { deleteEmptyStringValues } from 'api/utils'
import { notRequiredOrgNumber } from 'validation/schemas'
import { useAuthContext } from 'context/auth-context'

const validationSchema = validSchema.shape({
  company_description: Yup.string()
    .min(5, 'Common.CharsMinimum5'),
  org_number: notRequiredOrgNumber(),
  address_line: Yup.string().min(2, 'Common.CharsMinimum2'),
  address_line_2: Yup.string(),
  city: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .matches(/^(.(?!([0-9])))+$/, 'Common.NotNumber'),
  zip_code: Yup.string().min(2, 'Common.CharsMinimum2'),
  country: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .matches(/^(.(?!([0-9])))+$/, 'Common.NotNumber'),
})

const initialValues = {
  ...initValues,
  company_description: '',
  org_number: '',
  address_line: '',
  address_line_2: '',
  city: '',
  zip_code: '',
  country: '',
}

type FormValues = typeof initialValues

export default function SetUpCompanyProfile() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const { refetchCompany } = useAuthContext()

  const company = useUpdateCompanyMutation()

  const { uploadPhoto } = useUploadProfileCoverPhotos()

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { profile_photo, cover_picture, ...restValues  } = values
    
    const userData: UpdateCompany = {
      ...restValues
    }

    try {
      if (profile_photo.file) {
        const profilePhoto = await uploadPhoto(profile_photo)
        userData.profile_photo = profilePhoto?.photo || ''
      }
      if (cover_picture.file) {
        const coverPicture = await uploadPhoto(cover_picture)
        userData.cover_picture = coverPicture?.photo || ''
      }
      await company.mutateAsync(deleteEmptyStringValues(userData), {
        onSuccess: () => {
          setSubmitting(false)
          refetchCompany()
          
          if (previousPath) {
            navigate(previousPath)
          } else {
            navigate(RoutePathNames.company.home)
          }
        },
      })
    } catch (err) {}
  }

  return (
    <SetUpProfileForm<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}
