import { Avatar, Chip } from '@mui/material'
import React from 'react'

type ChipWithIconProps = {
  img?: string
  imgType?: 'svg' | 'jpg' | 'png'
  label?: string | number
}
export default function ChipWithIcon({ img, imgType, label }: ChipWithIconProps) {
  let src
  img && (src = require('static/svg/ChipIcons/' + img + '.' + imgType))
  return (
    <Chip
      icon={<Avatar src={src} sx={{ width: 21, height: 21, borderRadius: '0' }} />}
      label={label}
      sx={{
        bgcolor: 'secondary.106',
        borderRadius: '6px',
        color: 'primary.80',
        fontSize: '16px',
        lineHeight: '22px',
      }}
    />
  )
}
