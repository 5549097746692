import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as schemas from 'validation/schemas'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import PasswordField from 'components/ui/FormikFields/PasswordField'

const validationSchema = Yup.object({
  password: schemas.requiredPassword(),
  confirmPassword: Yup.string()
    .required('Registration.EnterConfirmPass')
    .oneOf([Yup.ref('password'), null], 'Registration.PassMustMatch'),
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

export default function ResetPassword() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const expires = Number.parseInt(searchParams.get('expires') ?? '', 10)
  const isExpired = Date.now() > expires

  const auth = useAuthContext()

  const onSubmit = async (values: typeof initialValues) => {
    const uuid = searchParams.get('uuid')
    try {
      if (uuid) {
        await auth.resetPassword(
          {
            password: values.password,
            uuid,
          },
          () => {
            navigate(RoutePathNames.auth.login)
          }
        )
      }
    } catch (error: any) {}
  }

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  const { t } = useTranslation()

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, dirty, isSubmitting } = formikProps
        return (
          <>
            {isExpired ? (
              <Typography sx={{ maxWidth: 500, position: 'absolute', top: 200 }}>
                {t('general.resetPasswordExpiredDesc')}
              </Typography>
            ) : (
              <AuthBoxWrapper>
                <Form onSubmit={handleSubmit}>
                  <Stack direction="column" spacing={4}>
                    <Typography variant="h3" align="center">
                      {t('Registration.ResetPassword')}
                    </Typography>
                    <Stack direction="column" spacing={1}>
                      <PasswordField name="password" label={t('Common.Password')} fullWidth />
                      <PasswordField
                        name="confirmPassword"
                        label={t('Common.ConfirmPassword')}
                        fullWidth
                      />
                    </Stack>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      disabled={!dirty || isSubmitting}
                      onClick={manualSubmitForm(formikProps)}
                    >
                      {t('Registration.ResetPassword')}
                    </Button>
                  </Stack>
                </Form>
              </AuthBoxWrapper>
            )}
          </>
        )
      }}
    </Formik>
  )
}
