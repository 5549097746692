export const NAME_LENGTH_MIN = 5
export const NUM_ROLES_MIN = 1
export const NUM_SKILLS_MIN = 3
export const NUM_PRIMARY_SKILLS_MIN = 3
export const NUM_PRIMARY_SKILLS_MAX = 20
export const DISTANCE_TO_WORK_DEFAULT = 80 // [km]
export const SKILLRANK_NONPRIMARY = 1
export const SKILLRANK_PRIMARY = 5
export const WORK_FROM_HOME_DAYS_MIN = 0
export const WORK_FROM_HOME_DAYS_MAX = 5
export const COMMUTE_DISTANCE_MIN = 0 // [km]
export const COMMUTE_DISTANCE_MAX = 100 // [km]
export const WORKLOAD_MIN = 10 // [%]
export const WORKLOAD_MAX = 100 // [%]
export const WORKLOAD_DEFAULT = 100 // [%]
export const HOURLY_RATE_DEFAULT = 500 // [skr]
export const AVAILABILITY_DATE_MAX = new Date('2100-01-01')
export const REMOTE_DAYS_PER_WEEK_MIN = 0
export const REMOTE_DAYS_PER_WEEK_MAX = 5
export const TOTAL_YEARS_EXPERIENCE_MIN = 0
export const TOTAL_YEARS_EXPERIENCE_MAX = 50
export const NUM_LANGUAGES_MIN = 1
