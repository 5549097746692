import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useField, FastFieldProps, FastField } from 'formik'
import { TextField, TextFieldProps } from '@mui/material'
import Typography from '@mui/material/Typography'

export type TextFieldComponentProps = Omit<FastFieldProps, 'form'> & {
  id: string
  label?: string
  labelOnFocus?: string
}

export function TextFieldComponent({
  id,
  label,
  labelOnFocus,
  field,
  meta,
  ...restProps
}: TextFieldComponentProps) {
  const [textFieldLabel, setTextFieldLabel] = useState(label)

  const error = meta.touched && meta.error

  const { t } = useTranslation()

  return (
    <TextField
      id={id}
      label={field.value && labelOnFocus ? labelOnFocus : textFieldLabel}
      {...field}
      onBlur={(event) => {
        event.target.value = event.target.value.trim()
        field.onChange(event)
        field.onBlur(event)
        if (labelOnFocus) {
          setTextFieldLabel(label)
        }
      }}
      onFocus={() => {
        if (labelOnFocus) {
          setTextFieldLabel(labelOnFocus)
        }
      }}
      error={!!error}
      helperText={error ? t(`${error}`) : ' '}
      {...restProps}
    />
  )
}

type InputFieldProps = TextFieldProps & {
  name: string
  id: string
  label?: string
  labelOnFocus?: string
  customLabel?: string
}

export default function InputField({ name, id, customLabel = '', ...restProps }: InputFieldProps) {
  const [field, meta] = useField({ name })

  return (
    <>
      {customLabel && (
        <Typography variant="h5" mb={1.5}>
          {customLabel}
        </Typography>
      )}
      <TextFieldComponent id={id} field={field} meta={meta} {...restProps} />
    </>
  )
}

type FastTextFieldProps = InputFieldProps
export function FastTextField({ name, id, customLabel, ...restProps }: FastTextFieldProps) {
  return (
    <>
      {customLabel && (
        <Typography variant="h5" mb={1.5}>
          {customLabel}
        </Typography>
      )}
      <FastField name={name}>
        {(props: FastFieldProps) => <TextFieldComponent id={id} {...props} {...restProps} />}
      </FastField>
    </>
  )
}
