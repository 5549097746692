import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import styles from './Chip.module.scss'

type ChipProps = {
  variant?: 'filled' | 'outlined' | 'custom'
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'error'
  size?: 'medium' | 'large' | 'small'
  href?: string
  fullWidth?: boolean
  disabled?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  label: ReactNode
}
// WIP
export default function Chip({
  variant = 'outlined',
  color = 'primary',
  size = 'small',
  href,
  fullWidth,
  disabled,
  startIcon,
  endIcon,
  label,
}: ChipProps) {
  const chip = (
    <button
      style={{ pointerEvents: 'none' }}
      className={classNames(
        { [styles.chipOutlined]: variant === 'outlined' },
        { [styles.chipFilled]: variant === 'filled' },
        { [styles.chipCustom]: variant === 'custom' },
        { [styles.chipSizeSmall]: size === 'small' },
        { [styles.chipSizeMedium]: size === 'medium' },
        { [styles.chipSizeLarge]: size === 'large' },
        { [styles.success]: color === 'success' },
        { [styles.warning]: color === 'warning' },
        { [styles.error]: color === 'error' },
        { [styles.chipFullWidth]: fullWidth }
      )}
      disabled={disabled}
    >
      {variant === 'custom' && <div className={styles.dot} />}
      {startIcon && <span className={styles.chipStartIcon}>{startIcon}</span>}
      <span>{label}</span>
      {endIcon && <span className={styles.chipEndIcon}>{endIcon}</span>}
    </button>
  )

  if (href) {
    return (
      <Link className={styles.chipRoot} to={href}>
        {chip}
      </Link>
    )
  }

  return <span className={styles.chipRoot}>{chip}</span>
}
