import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en_locale from './locales/en/translation.json'
import se_locale from './locales/se/translation.json'

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      translation: en_locale,
    },
    se: {
      translation: se_locale,
    },
  },
})

export default i18n
