import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { CheckboxProps } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'

type InputFieldProps = CheckboxProps & {
  label?: string
}

export default function CheckboxField({ label = '', ...restProps }: InputFieldProps) {
  // @ts-ignore
  const [field, meta] = useField(restProps)

  const { t } = useTranslation()

  const error = meta.touched && meta.error

  return (
    <div>
      <FormControlLabel
        id={field.name}
        label={label}
        {...field}
        control={<Checkbox {...restProps} checked={field.value} />}
      />
      <FormHelperText error={!!error}>{error ? t(`${error}`) : ' '}</FormHelperText>
    </div>
  )
}
