import { CSSProperties } from 'react'
import { createTheme } from '@mui/material/styles'
// import type {} from '@mui/lab/themeAugmentation';
// import type {} from '@mui/x-date-pickers/themeAugmentation';
// import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
// import { purple } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadius: number
      rounded: number
      'rounded-md': number
      'rounded-full': string
    }
  }

  interface ThemeOptions {
    shape?: {
      borderRadius?: number
      rounded?: number
      'rounded-md'?: number
      'rounded-full'?: string
    }
  }

  interface BreakpointOverrides {
    '2lg': true
  }

  interface Palette {
    cream: Palette['primary']
    black: Palette['primary']
  }

  interface PaletteOptions {
    cream?: PaletteOptions['primary']
    black?: PaletteOptions['primary']
  }

  interface PaletteColor {
    20?: string
    40?: string
    60?: string
    80?: string
    106?: string
    108?: string
  }

  interface SimplePaletteColorOptions {
    20?: string
    40?: string
    60?: string
    80?: string
    106?: string
    108?: string
  }

  interface TypeBackground {
    zinc?: string
    color1?: string
    focus?: string
  }

  interface TypographyVariants {
    body: CSSProperties
    'body-sm': CSSProperties
    'body-xs': CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body?: CSSProperties
    'body-sm'?: CSSProperties
    'body-xs'?: CSSProperties
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cream: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true
    'body-sm': true
    'body-xs': true
  }
}

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

const white = '#fff'

const primary = '#10122D'
const primary20 = 'rgba(16, 18, 45, 0.2)'
const primary40 = 'rgba(16, 18, 45, 0.4)'
const primary60 = 'rgba(16, 18, 45, 0.6)'
const primary80 = 'rgba(16, 18, 45, 0.8)'

const secondary = '#3937c0'
const secondary20 = 'rgba(57, 55, 192, 0.2)'
const secondary40 = 'rgba(57, 55, 192, 0.4)'
const secondary60 = 'rgba(57, 55, 192, 0.6)'
const secondary80 = 'rgba(57, 55, 192, 0.8)'
const secondary106 = 'rgba(57, 55, 192, 0.06)'
const secondary108 = 'rgba(57, 55, 192, 0.08)'

const cream = '#FEF1E5'
const cream20 = 'rgba(254, 241, 229, 0.2)'
const cream40 = 'rgba(254, 241, 229, 0.4)'
const cream60 = 'rgba(254, 241, 229, 0.6)'
const cream80 = 'rgba(254, 241, 229, 0.8)'

const black = '#000'
const black20 = 'rgba(0, 0, 0, 0.2)'
const black40 = 'rgba(0, 0, 0, 0.4)'
const black60 = 'rgba(0, 0, 0, 0.6)'
const black80 = 'rgba(0, 0, 0, 0.8)'

const zinc = '#F4F4F4'
const color1 = '#CAC4D3'
const focus = '#E1EBEE'

const theme = createTheme({
  typography: {
    fontFamily: ['museo-sans', 'Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: '50px',
      fontWeight: fontWeightBold,
    },
    h2: {
      fontSize: '38px',
      fontWeight: fontWeightBold,
    },
    h3: {
      fontSize: '28px',
      fontWeight: fontWeightBold,
    },
    h4: {
      fontSize: '21px',
      fontWeight: fontWeightBold,
    },
    h5: {
      fontSize: '16px',
      fontWeight: fontWeightBold,
    },
    h6: {
      fontSize: '12px',
      fontWeight: fontWeightBold,
    },
    // subtitle1: {},
    // subtitle2: {},
    // body1: {},
    // body2: {},
    // caption: {},
    // button: {},
    body: {
      fontSize: '16px',
      fontWeight: fontWeightLight,
    },
    'body-sm': {
      fontSize: '12px',
      fontWeight: fontWeightLight,
    },
    'body-xs': {
      fontSize: '9px',
      fontWeight: fontWeightLight,
    },
    overline: {
      fontSize: '9px',
      fontWeight: fontWeightRegular,
      textTransform: 'unset',
    },
  },
  palette: {
    primary: {
      main: primary,
      20: primary20,
      40: primary40,
      60: primary60,
      80: primary80,
    },
    secondary: {
      main: secondary,
      20: secondary20,
      40: secondary40,
      60: secondary60,
      80: secondary80,
      106: secondary106,
      108: secondary108,
    },
    cream: {
      main: cream,
      20: cream20,
      40: cream40,
      60: cream60,
      80: cream80,
    },
    black: {
      main: black,
      20: black20,
      40: black40,
      60: black60,
      80: black80,
    },
    background: {
      zinc,
      color1,
      focus
    },
  },
  shape: {
    rounded: 1,
    'rounded-md': 1.5,
    'rounded-full': '9999px',
  },
  // shadows: [
  //   ...theme.shadows,
  // ],

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      '2lg': 1440,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
        body {
          background-color: ${themeParam.palette.background.zinc};
          font-family: museo-sans, sans-serif;
          font-weight: ${themeParam.typography.fontWeightLight};
          color: ${themeParam.palette.primary.main};
        }
        a {
          color: ${themeParam.palette.primary.main};
        }
        a,a:link,a:hover,a:visited,a:active {
          text-decoration: none;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.MuiButton-contained': {
            '&.Mui-disabled': {
              backgroundColor: secondary,
              color: white,
              opacity: 0.3,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          maxHeight: 28,
          fontSize: 12,
          fontWeight: 400,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: primary,
          fontWeight: fontWeightBold,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-root .MuiFormLabel-root': {
            '&.Mui-focused, &.MuiInputLabel-shrink': {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
          },
          '&.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
            {
              paddingRight: '40px',
            },
          '& .MuiAutocomplete-popupIndicator': {
            '& .MuiSvgIcon-root': {
              display: 'none',
            },
          },
        },
        inputRoot: {
          paddingTop: '13px',
          paddingBottom: '12px',
          paddingLeft: '16px',
          '& .MuiAutocomplete-input.MuiOutlinedInput-input': {
            padding: '0px 4px',
          },
        },
        option: {
          fontSize: '14px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: fontWeightMedium,
          color: 'rgba(16, 18, 45, 0.6)',
          transform: 'translate(14px, 12.5px) scale(1)',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(14px, -9px) scale(0.75)',
            fontWeight: fontWeightBold,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: fontWeightLight,
          color: primary,
          '&.MuiInputBase-multiline': {
            padding: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '13px 16px 12px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'none',
        },
      },
    },
  },
})

const getTheme = () => theme

export default getTheme
