import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UseQueryResult, UseMutationResult, useQueryClient } from 'react-query'
import { fromUnixTime } from 'date-fns'
import jwt_decode from 'jwt-decode'
import { createCtx } from './helpers'
import RoutePathNames from 'routes/routePathNames'
import { ApproveAssignment, useApproveAssignmentMutation } from 'api/assignments/mutations'
import {
  Role,
  setAccessToken,
  getAccessToken,
  removeAccessToken,
  isResponseUnauthorized,
  isResponseStatusNotFound,
  ROLES,
  notifyReload,
  setVerifyEmailReminder,
  setCompleteProfileReminder,
} from 'api/utils'
import { User, UpdateUser, userKeys, useUserQuery, fetchUser } from 'api/user/queries'
import { Company, useCompanyByUserIdQuery } from 'api/company/queries'
import { managerKeys, fetchManagerByUserId } from 'api/manager/queries'
import {
  useUpdateConsultantMutation,
  useConsultantDeleteProfileMutation,
  useCreateConsultantCvMutation,
  useRenewTokenMutation
} from 'api/user/mutations'

import {
  useUpdateManagerMutation,
  useUpdateConsultantByManagerMutation,
  UpdateConsultantByManagerBody,
  UpdateManager,
  useManagerDeleteConsultantProfileMutation,
  useCreateConsultantCvByManagerMutation,
} from 'api/manager/mutations'
import { CreateCvDto, cvsKeys, fetchUserCvs } from 'api/cvs/queries'
import {
  RegistrationConsultant,
  RegistrationCompany,
  RegistrationManager,
  Login,
  LoginAndRedirect,
  ForgotPassword,
  ResetPassword,
  useRegistrationCompanyMutation,
  useRegistrationConsultantMutation,
  useForgotPasswordMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useRegistrationManagerMutation
} from 'api/auth/mutations'
import defaultPicture from 'static/images/no-avatar.png'

type AuthContextType = {
  isAuth: boolean
  role: Role | undefined
  user: UseQueryResult<User | undefined, unknown>
  companyData?: Company
  refetchCompany: () => void
  setIsAuth: (isAuth: boolean) => void
  setRole: (role: Role) => void
  registrationConsultant: (body: RegistrationConsultant, callback: VoidFunction) => void
  registrationCompany: (body: RegistrationCompany, callback: VoidFunction) => void
  registrationManager: (body: RegistrationManager, callback: VoidFunction) => void
  login: (body: Login, callback?: VoidFunction) => void
  loginAndRedirectToPrevious: (body: LoginAndRedirect, callback?: VoidFunction) => void
  approveAssignment: (body: ApproveAssignment, callback?: VoidFunction) => void
  logout: (callback?: VoidFunction) => void
  forgotPassword: (body: ForgotPassword, callback: VoidFunction) => void
  resetPassword: (body: ResetPassword, callback: VoidFunction) => void
  updateConsultant: (body: UpdateUser, callback?: VoidFunction) => void
  updateManager: (body: UpdateManager, callback?: VoidFunction) => void
  updateConsultantByManager: (body: UpdateConsultantByManagerBody, callback?: VoidFunction) => void
  createConsultantCv: (body: CreateCvDto, onSuccess?: VoidFunction) => void
  createConsultantCvByManager: (body: CreateCvDto, onSuccess?: VoidFunction) => void
  deleteConsultantProfileMutation: UseMutationResult<
    unknown,
    unknown,
    { user_id?: string | number | undefined },
    unknown
  >
  deleteConsultantProfile: (callback?: VoidFunction) => void
  renewToken: (onError?: VoidFunction) => void
  isUserRoleConsultant: boolean
  isUserRoleCompany: boolean
  isUserRoleManager: boolean
  isUserRoleAdmin: boolean
  isUserEmailVerified: boolean
  isCompanyProfileComplete?: boolean
}

const [useAuthContext, AuthContextProvider] = createCtx<AuthContextType>()

export { useAuthContext }

function isProfileComplete(companyData: Partial<Company> | undefined): boolean {
  if (companyData === undefined) return false;

  const {
    profile_photo,
    cover_picture,
    company_description,
    company_name,
    city,
    country,
    address_line,
    org_number,
    zip_code,
  } = companyData

  return (
    !!profile_photo &&
    !!cover_picture &&
    !!company_description &&
    !!company_name &&
    !!city &&
    !!country &&
    !!address_line &&
    !!org_number &&
    !!zip_code
  );
}

type DecodedToken = {
  email: string
  exp: number
  role: { title: Role }
  is_admin: boolean
  is_email_verified: boolean
}

const getAuthDecodedToken = (): DecodedToken | undefined => {
  const token = getAccessToken()
  return token ? jwt_decode(token) : undefined
}

const isUserAuthenticated = () => {
  const decodedToken = getAuthDecodedToken()

  if (decodedToken && decodedToken?.exp) {
    return fromUnixTime(decodedToken.exp).getTime() > new Date().getTime()
  } else {
    return false
  }
}

//if token exists and has is_admin set to true, the user is considered admin
export const checkIfUserIsAdmin = (): boolean => {
  const decodedToken = getAuthDecodedToken()
  return !!decodedToken?.is_admin
}

export const checkIfUserIsEmailVerified = (): boolean => {
  const decodedToken = getAuthDecodedToken()
  return !!decodedToken?.is_email_verified
}

const getUserRoleFromDecodedToken = () => {
  const decodedToken = getAuthDecodedToken()
  return (decodedToken && decodedToken?.role?.title) ?? undefined
}

export default function AuthProvider({ children }: React.PropsWithChildren<unknown>) {
  const [isAuth, setIsAuth] = useState(isUserAuthenticated)
  const [role, setRole] = useState<Role | undefined>(getUserRoleFromDecodedToken)

  const navigate = useNavigate()
  const params = useParams()

  const queryClient = useQueryClient()

  const user = useUserQuery(isAuth)
  const usr = useMemo(
    () =>
      user?.data
        ? {
          ...user,
          data: {
            ...user.data,
            profile_photo: user.data?.profile_photo || defaultPicture,
          },
        }
        : user,
    [user, user.data]
  )

  const companyByUserId = useCompanyByUserIdQuery()
  const { data: companyData, refetch: refetchCompany } = companyByUserId

  const isCompanyProfileComplete = isProfileComplete(companyData)

  const regConsultant = useRegistrationConsultantMutation()
  const regCompany = useRegistrationCompanyMutation()
  const regManager = useRegistrationManagerMutation()
  const logIn = useLoginMutation()
  const forgotPass = useForgotPasswordMutation()
  const resetPass = useResetPasswordMutation()
  const updateCons = useUpdateConsultantMutation()
  const approveAsgmnt = useApproveAssignmentMutation()
  const updateMgr = useUpdateManagerMutation()
  const updateConsByManager = useUpdateConsultantByManagerMutation(params?.consultantId)
  const managerDeleteProfileMutation = useManagerDeleteConsultantProfileMutation()
  const consultantDeleteProfileMutation = useConsultantDeleteProfileMutation()
  const createConsCv = useCreateConsultantCvMutation()
  const createConsCvByManager = useCreateConsultantCvByManagerMutation()
  const tokenRenewer = useRenewTokenMutation();

  const onRegistrationLoginSuccess = useCallback(
    async (data: any, callback?: VoidFunction) => {
      if (data?.token && data?.user) {
        setVerifyEmailReminder(true)
        setCompleteProfileReminder(true)
        setAccessToken(data.token)
        setIsAuth(true)
        const role: Role = data?.user?.role?.title
        setRole(role)
        queryClient.setQueryData(userKeys.user(true), data.user)
        if (callback) {
          callback()
        }
      }
    },
    [queryClient]
  )

  const registrationConsultant = useCallback(
    async (body: RegistrationConsultant, callback: VoidFunction) => {
      await regConsultant.mutateAsync(body, {
        onSuccess: (data) => onRegistrationLoginSuccess(data, callback),
      })
    },
    [regConsultant, onRegistrationLoginSuccess]
  )

  const registrationCompany = useCallback(
    async (body: RegistrationCompany, callback: VoidFunction) => {
      await regCompany.mutateAsync(body, {
        onSuccess: (data) => onRegistrationLoginSuccess(data, callback),
      })
    },
    [regCompany, onRegistrationLoginSuccess]
  )

  const registrationManager = useCallback(
    async (body: RegistrationManager, callback: VoidFunction) => {
      await regManager.mutateAsync(body, {
        onSuccess: (data) => onRegistrationLoginSuccess(data, callback),
      })
    },
    [regManager, onRegistrationLoginSuccess]
  )

  const login = useCallback(
    async (body: Login, callback?: VoidFunction) => {
      await logIn.mutateAsync(body, {
        onSuccess: async (data) => {
          await onRegistrationLoginSuccess(data)

          const role = data?.user?.role?.title
          // Here we check out if a user is the CONSULTANT and uploaded cvs before,
          // if so, we redirect to the consultant home page,
          // else, we redirect to the upload cvs page.
          if (role === ROLES.CONSULTANT) {
            try {
              const userCvs = await queryClient.fetchQuery(cvsKeys.cv(), fetchUserCvs)
              if (userCvs?.cvs_id) {
                navigate(RoutePathNames.consultant.home, { replace: true })
              }
            } catch (error) {
              if (isResponseStatusNotFound(error)) {
                navigate(RoutePathNames.consultant.uploadCvs, { replace: true })
              }
            }
            // Here we check out if a user is the COMPANY and uploaded profile_photo, cover_picture, company_description before,
            // if so, we redirect to the company home page,
            // else, we redirect to the setup profile page.
          } else if (role === ROLES.COMPANY) {
            navigate(RoutePathNames.company.home, { replace: true })

            // Here we check out if a user is the MANAGER and uploaded profile_photo, cover_picture, company_description before,
            // if so, we redirect to the manager home page,
            // else, we redirect to the setup profile page.
          } else if (role === ROLES.MANAGER) {
            const user = await queryClient.fetchQuery(userKeys.user(), fetchUser)
            const managerByUserId = await queryClient.fetchQuery(
              managerKeys.managerByUserId(user?.user_id),
              fetchManagerByUserId
            )
            const {
              profile_photo = '',
              cover_picture = '',
              manager_description = '',
            } = managerByUserId || {}

            if (profile_photo || cover_picture || manager_description) {
              navigate(RoutePathNames.manager.home, { replace: true })
            } else {
              navigate(RoutePathNames.manager.setupProfile, { replace: true })
            }
          }
        },
      })
    },
    [logIn, onRegistrationLoginSuccess, queryClient, navigate]
  )

  const loginAndRedirectToPrevious = useCallback(
    async (body: LoginAndRedirect, callback?: VoidFunction) => {
      const { email, password, previousPath } = body;
      await logIn.mutateAsync({ email, password }, {
        onSuccess: async (data) => {
          await onRegistrationLoginSuccess(data)
          navigate(previousPath, { replace: true });
        },
      })
    },
    [logIn, onRegistrationLoginSuccess, navigate]
  )

  const approveAssignment = useCallback(
    async (body: ApproveAssignment, callback?: VoidFunction) => {
      await approveAsgmnt.mutateAsync(body, {
        onSuccess: () => {
          if (callback) {
            callback()
          }
        },
      })
    },
    [approveAsgmnt]
  )

  const logout = useCallback(
    (callback?: VoidFunction) => {
      removeAccessToken()
      setIsAuth(false)
      setRole(undefined)
      queryClient.setQueryData(userKeys.user(false), null)
      queryClient.clear()
      if (callback) {
        callback()
      }
    },
    [queryClient]
  )

  const forgotPassword = useCallback(
    async (body: ForgotPassword, callback: VoidFunction) => {
      await forgotPass.mutateAsync(body, {
        onSuccess: () => callback(),
      })
    },
    [forgotPass]
  )

  const resetPassword = useCallback(
    async (body: ResetPassword, callback: VoidFunction) => {
      await resetPass.mutateAsync(body, {
        onSuccess: () => callback(),
      })
    },
    [resetPass]
  )

  const updateConsultant = useCallback(
    async (body: UpdateUser, callback?: VoidFunction) => {
      await updateCons.mutateAsync(body, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(userKeys.user())
          if (callback) {
            callback()
          }
        },
      })
    },
    [updateCons, queryClient]
  )

  const updateManager = useCallback(
    async (body: UpdateManager, callback?: VoidFunction) => {
      await updateMgr.mutateAsync(body, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(managerKeys.manager)
          if (callback) {
            callback()
          }
        },
      })
    },
    [updateMgr, queryClient]
  )

  const updateConsultantByManager = useCallback(
    async (body: UpdateConsultantByManagerBody, callback?: VoidFunction) => {
      await updateConsByManager.mutateAsync(body, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(managerKeys.manager)
          if (callback) {
            callback()
          }
        },
      })
    },
    [updateConsByManager, queryClient]
  )

  const createConsultantCv = useCallback(
    async (body: CreateCvDto, callback?: VoidFunction) => {
      await createConsCv.mutateAsync(body, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(userKeys.user())
          callback?.();
        }
      });
    },
    [createConsCv, queryClient]
  )

  const createConsultantCvByManager = useCallback(
    async (body: CreateCvDto, onSuccess?: VoidFunction) => {
      await createConsCvByManager.mutateAsync(body, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(managerKeys.manager)
          onSuccess?.();
        }
      });
    },
    [createConsCvByManager, queryClient]
  )

  const isUserRoleConsultant = useMemo(() => role === ROLES.CONSULTANT, [role])

  const isUserRoleCompany = useMemo(() => role === ROLES.COMPANY, [role])

  const isUserRoleManager = useMemo(() => role === ROLES.MANAGER, [role])

  const isUserRoleAdmin = useMemo(checkIfUserIsAdmin, [isAuth, getAccessToken()])

  const isUserEmailVerified = useMemo(checkIfUserIsEmailVerified, [isAuth, getAccessToken()])

  const deleteConsultantProfileMutation = isUserRoleConsultant
    ? consultantDeleteProfileMutation
    : managerDeleteProfileMutation

  const deleteConsultantProfile = useCallback(
    async (callback?: VoidFunction) => {
      await deleteConsultantProfileMutation.mutate(
        { user_id: isUserRoleConsultant ? usr.data?.user_id : params?.userId },
        {
          onSuccess: async () => {
            if (isUserRoleConsultant) {
              logout()
            }
            if (callback) {
              callback()
            }
          },
        }
      )
    },
    [
      deleteConsultantProfileMutation,
      isUserRoleConsultant,
      logout,
      params?.userId,
      usr.data?.user_id,
    ]
  )

  const renewToken = useCallback(
    async (onError?: VoidFunction) => {
      await tokenRenewer.mutateAsync(void {}, {
        onSuccess: (data) => {
          setAccessToken(data.token)
          notifyReload();
        },
        onError: () => onError?.()
      })
    }, [queryClient, tokenRenewer]
  )

  useEffect(() => {
    if (isResponseUnauthorized(usr.error)) {
      logout()
    }
  }, [usr, usr.error, logout])

  const authValue = useMemo(
    () => ({
      isAuth,
      role,
      user: usr,
      companyData,
      refetchCompany,
      setIsAuth,
      setRole,
      registrationConsultant,
      registrationCompany,
      registrationManager,
      login,
      loginAndRedirectToPrevious,
      approveAssignment,
      logout,
      forgotPassword,
      resetPassword,
      updateConsultant,
      updateManager,
      updateConsultantByManager,
      deleteConsultantProfile,
      createConsultantCv,
      createConsultantCvByManager,
      deleteConsultantProfileMutation,
      renewToken,
      isUserRoleConsultant,
      isUserRoleCompany,
      isUserRoleManager,
      isUserRoleAdmin,
      isUserEmailVerified,
      isCompanyProfileComplete
    }),
    [
      isAuth,
      role,
      usr,
      companyData,
      refetchCompany,
      registrationConsultant,
      registrationCompany,
      registrationManager,
      login,
      loginAndRedirectToPrevious,
      approveAssignment,
      logout,
      forgotPassword,
      resetPassword,
      updateConsultant,
      updateManager,
      updateConsultantByManager,
      deleteConsultantProfile,
      createConsultantCv,
      createConsultantCvByManager,
      deleteConsultantProfileMutation,
      renewToken,
      isUserRoleConsultant,
      isUserRoleCompany,
      isUserRoleManager,
      isUserRoleAdmin,
      isUserEmailVerified,
      isCompanyProfileComplete
    ]
  )

  return <AuthContextProvider value={authValue}>{children}</AuthContextProvider>
}
