import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { Theme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import defaultPicture from 'static/images/no-avatar.png'

const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

type UploadAvatarFieldProps = {
  name: string
  id: string
}

const avatarSx = (theme: Theme) => ({
  width: 120,
  height: 120,
  borderRadius: theme.shape['rounded-md'],
})

export default function UploadAvatarField({name, id}: UploadAvatarFieldProps) {
  const [field, meta, helpers] = useField({ name })
  const inputFileRef: any = useRef(undefined)

  const { t } = useTranslation()

  const uploadImage = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      getBase64(file, (url) => {
        helpers.setValue({ url, file })
      })
    }
  }

  const onDeletePhoto = async () => {
    helpers.setValue({ url: undefined, file: null })
    inputFileRef.current.value = ''
  }

  return (
    <Stack direction="row" alignItems="center" spacing={{ xs: 1.5, sm: 4 }}>
      {field.value.url || field.value.url === undefined ? (
        <Avatar
          src={field.value.url || defaultPicture}
          alt="Profile avatar"
          variant="square"
          sx={avatarSx}
        />
      ) : (
        <Skeleton variant="rectangular" sx={avatarSx} />
      )}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="stretch"
        spacing={{ xs: 2, sm: 1.5 }}
      >
        <Button
          variant="contained"
          color="secondary"
          component="label"
          sx={{ textAlign: 'center' }}
        >
          {t('general.uploadPicture')}
          <input
            hidden
            type="file"
            accept="image/*"
            ref={inputFileRef}
            onChange={(e) => {
              uploadImage(e)
            }}
          />
        </Button>
        <Button id={`${id}-remove`} variant="outlined" color="secondary" onClick={onDeletePhoto}>
          {t('general.delete')}
        </Button>
      </Stack>
    </Stack>
  )
}
