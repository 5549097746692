import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import {
  SelectProps,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

type Opt = { value: string | number | null; label: string | number } | string | number | null;

type InputFieldProps = SelectProps & {
  name: string;
  id: string;
  customLabel?: string;
  options?: Opt[];
  getOptionLabel?: (option: Opt) => string | number;
  getOptionValue?: (option: Opt) => string | number;
  hasI18Keys?: boolean;
};

export default function SelectField({
  name,
  id,
  label,
  fullWidth,
  required,
  customLabel,
  options = [],
  placeholder,
  getOptionLabel = (option: Opt) => (typeof option === 'object' && option ? option.label : option) || '',
  getOptionValue = (option: Opt) => (typeof option === 'object' && option ? option.value : option) || '',
  hasI18Keys = false,
}: InputFieldProps) {
  const [field, meta] = useField({ name });

  const { t } = useTranslation();

  const error = meta.touched && meta.error;
  const helperText = error ? t(`${error}`) : ' ';

  return (
    <>
      {customLabel && (
        <Typography variant="h5" mb={1.5}>
          {customLabel}
        </Typography>
      )}
      <FormControl fullWidth={fullWidth} required={required} error={!!error}>
        {label && <InputLabel id={field.name}>{label}</InputLabel>}
        <Select 
          id={id}
          labelId={field.name} 
          label={label} 
          {...field} 
          displayEmpty
          value={field.value == null ? '' : getOptionValue(field.value)}
        >
          {placeholder && (
            <MenuItem key="placeholder" value="">
              {placeholder}
            </MenuItem>
          )}
          {options.map((option) => {
            const value = getOptionValue(option);
            const label = getOptionLabel(option);
            return (
              <MenuItem key={value} value={value}>
                {hasI18Keys ? t(`${label}`) : label}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
}
