import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import * as Yup from 'yup'
import RoutePathNames from 'routes/routePathNames'
import { UpdateManager } from 'api/manager/mutations'
import { useAuthContext } from 'context/auth-context'
import SetUpProfileForm, { validSchema, initValues } from 'components/common/forms/SetUpProfileForm'
import useUploadProfileCoverPhotos from 'hooks/useUploadProfileCoverPhotos'

const validationSchema = validSchema.shape({
  manager_description: Yup.string().min(5, 'Common.CharsMinimum5').required('manager.enterDesc'),
})

const initialValues = {
  ...initValues,
  manager_description: '',
}

type FormValues = typeof initialValues

export default function SetUpManagerProfile() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const auth = useAuthContext()

  const { uploadPhoto } = useUploadProfileCoverPhotos()

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { manager_description, profile_photo, cover_picture } = values

    const userData: UpdateManager = {
      manager_description,
    }

    try {
      if (profile_photo.file) {
        const profilePhoto = await uploadPhoto(profile_photo)
        userData.profile_photo = profilePhoto?.photo || ''
      }
      if (cover_picture.file) {
        const coverPicture = await uploadPhoto(cover_picture)
        userData.cover_picture = coverPicture?.photo || ''
      }
      await auth.updateManager(userData, () => {
        setSubmitting(false)
        if (previousPath) {
          navigate(previousPath)
        } else {
          navigate(RoutePathNames.manager.home)
        }
      })
    } catch (err) {}
  }

  return (
    <SetUpProfileForm<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}
