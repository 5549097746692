import React, { useEffect } from 'react'
import CenteredProgress from 'components/common/CenteredProgress';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthContext } from 'context/auth-context';
import { enqueueSnackbar } from 'notistack';
import { useVerifyEmailMutation } from 'api/auth/mutations';
import RoutePathNames from 'routes/routePathNames';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { renewToken, isAuth } = useAuthContext();

  const uuid = searchParams.get('uuid') ?? '';

  const { mutateAsync } = useVerifyEmailMutation();

  useEffect(() => {
    mutateAsync(uuid, {
      onSuccess: (data) => {
        if (isAuth) {
          renewToken(() =>
            enqueueSnackbar(
              "Unable to renew access token, please log out to finish verifying your email",
              { variant: "error" }
            )
          );
        }
        navigate(
          RoutePathNames.auth.verifyEmailResult,
          { replace: true, state: data }
        );
      },
      onError: () => {
        navigate(
          RoutePathNames.auth.verifyEmailResult,
          { replace: true, state: { success: false } }
        );
      }
    })
  }, [])

  return <CenteredProgress/>
}
