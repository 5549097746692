import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { TextFieldProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

type InputFieldProps = TextFieldProps & {
  label?: string
}

export default function PasswordField({ label = '', ...restProps }: InputFieldProps) {
  // @ts-ignore
  const [field, meta] = useField(restProps)

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const { t } = useTranslation()

  const error = meta.touched && meta.error

  return (
    <TextField
      {...restProps}
      {...field}
      id={field.name}
      type={showPassword ? 'text' : 'password'}
      label={label}
      error={!!error}
      helperText={error ? t(`${error}`) : ' '}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
