import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { enqueueSnackbar } from 'notistack'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'context/auth-context'
import { ApproveAssignment } from 'api/assignments/mutations'
import ScrollToError from 'components/common/ScrollToError'
import InputField from 'components/ui/FormikFields/InputField'
import NumberField from 'components/ui/FormikFields/NumberField/NumberField'
import AdminLoadUsers from 'components/admin/AdminLoadUsers'

const validationSchema = yup.object().shape({
  assignmentId: yup.string().required('AdminChangeStatus.EnterAssignmentId'),
  consultantId: yup.number().required('AdminChangeStatus.EnterConsultantId'),
})

const initialValues = {
  assignmentId: '',
  consultantId: '',
}

export default function AdminChangeStatus() {
  const auth = useAuthContext()

  const logout = () => {
    auth.logout()
  }

  const { t } = useTranslation()

  const onSubmit = async (
    values: ApproveAssignment,
    { setSubmitting, resetForm }: FormikHelpers<ApproveAssignment>
  ) => {
    const { assignmentId, consultantId } = values

    try {
      if (assignmentId && consultantId) {
        await auth.approveAssignment(values)
        setSubmitting(false)
        enqueueSnackbar(t('general.approvedSuccessfully'), { variant: 'success' })
        resetForm()
      }
    } catch (err) { }
  }

  return (
    <Box
      sx={{
        p: { md: '80px 0px 40px 0px', xs: '40px 0px 40px 0px' },
        mx: 'auto',
        maxWidth: 1200,
      }}
    >
      {/*@ts-ignore*/}
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <ScrollToError />
            <Stack
              direction="row"
              sx={{ p: { md: '0 0 32px 0', xs: '0 32px 32px 32px' } }}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h2">{t('AdminChangeStatus.PageName')}</Typography>
              <Button id="logout" variant="outlined" size="large" onClick={logout}>
                {t('Common.LogOut')}
              </Button>
            </Stack>
            <Box
              sx={(theme) => ({
                maxWidth: 1200,
                mx: 'auto',
                bgcolor: theme.palette.common.white,
                borderRadius: theme.shape.rounded,
                p: '32px',
              })}
            >
              <Stack direction="column" spacing={1.5}>
                <Stack
                  direction={{ sm: 'row', xs: 'column' }}
                  justifyContent="space-between"
                  spacing={2}
                  alignItems="flex-start"
                >
                  <InputField
                    id="assignment-id"
                    name="assignmentId"
                    label={t('AdminChangeStatus.AssignmentID')}
                    fullWidth
                  />
                  <NumberField
                    id="consultant-id"
                    name="consultantId"
                    label={t('AdminChangeStatus.ConsultantID')}
                    fullWidth
                  />
                  <Button
                    id="approve"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ p: '10px 40px' }}
                    disabled={isSubmitting}
                  >
                    {t('AdminChangeStatus.Approve')}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
      <AdminLoadUsers />
    </Box>
  )
}
