import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import InputField from 'components/ui/FormikFields/InputField'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import SendMail from 'static/svg/send-mail.svg'

const validationSchema = Yup.object({
  email: Yup.string().email().required('Registration.EnterEmail'),
})

const initialValues = {
  email: '',
}

export default function ForgotPassword() {
  const auth = useAuthContext()

  const onSubmit = async (
    values: typeof initialValues,
    { setStatus }: FormikHelpers<typeof initialValues>
  ) => {
    const user = {
      email: values.email,
    }
    try {
      await auth.forgotPassword(user, () => {
        setStatus('sent')
      })
    } catch (error: any) {}
  }

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  const { t } = useTranslation()

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, status, dirty, isSubmitting } = formikProps
        return (
          <AuthBoxWrapper>
            {status === 'sent' ? (
              <div>
                <Avatar
                  alt="Send mail"
                  src={SendMail}
                  variant="square"
                  sx={{ width: 80, height: 80, mx: 'auto' }}
                />
                <Typography variant="h3" align="center">
                  {t('Registration.CheckMail')}
                </Typography>
                <Typography component="div" variant="body" color="primary.60" align="center" mt={2}>
                  {t('Registration.WeSentInstructions')}
                </Typography>
              </div>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Typography variant="h3" align="center">
                  {t('Registration.ForgotPassword')}
                </Typography>
                <Stack direction="column" spacing={4} mt={2}>
                  <Typography component="div" variant="body" color="primary.60" align="center">
                    {t('Registration.SendInstructions')}
                  </Typography>
                  <InputField id="email" name="email" label={t('Common.Email')} fullWidth />
                </Stack>
                <Stack direction="column" spacing={4} mt={1.5}>
                  <Button
                    id="reset-password"
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    disabled={!dirty || isSubmitting}
                    onClick={manualSubmitForm(formikProps)}
                  >
                    {t('Registration.ResetPassword')}
                  </Button>
                  <Typography align="center">
                    <Button component={Link} to={RoutePathNames.auth.login}>
                      <b id="back-to-login">{t('Registration.BackToLogIn')}</b>
                    </Button>
                  </Typography>
                </Stack>
              </Form>
            )}
          </AuthBoxWrapper>
        )
      }}
    </Formik>
  )
}
