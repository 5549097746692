import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { FormikHelpers } from 'formik';
import * as Yup from 'yup'
import { ROLES } from 'api/utils'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import RegistrationForm, { validSchema, initValues } from 'components/common/forms/RegistrationForm'
import * as schemas from 'validation/schemas'

const validationSchema = validSchema.shape({
  manager_name: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterManagerName'),
  org_number: schemas.requiredOrgNumber(),
  address_line: Yup.string().min(2, 'Common.CharsMinimum2').required('Registration.EnterAddress'),
  address_line_2: Yup.string(),
  city: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterCity')
    .matches(/^(.(?!([0-9])))+$/, 'Common.NotNumber'),
  zip_code: Yup.string().min(2, 'Common.CharsMinimum2').required('Registration.EnterZipCode'),
  country: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterCountry')
    .matches(/^(.(?!([0-9])))+$/, 'Common.NotNumber'),
})

const initialValues = {
  ...initValues,
  manager_name: '',
  org_number: '',
  address_line: '',
  address_line_2: '',
  city: '',
  zip_code: '',
  country: '',
}

type FormValues = typeof initialValues

export default function RegistrationManager() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const { setRole, registrationManager } = useAuthContext()

  useEffect(() => {
    setRole(ROLES.MANAGER)
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (
    values: FormValues /* , { setSubmitting }: FormikHelpers<FormValues> */
  ) => {
    const { address_line_2, confirmPassword, agreement, ...restValues } = values

    try {
      await registrationManager(
        { ...restValues, address_line_2, name: restValues.manager_name },
        () => {
          navigate(RoutePathNames.manager.setupProfile, { replace: true, state: {pathname: previousPath} })
        }
      )
    } catch (error: any) {}
  }

  return (
    <RegistrationForm<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}
