import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import SkillList from './SkillList'
import LatestAssignmentCard from '../LatestAssignmentCard'
import VerifyEmailDialog from 'components/common/VerifyEmailDialog'
import pencilIcon from 'static/svg/pencil.svg'
import { Stack } from '@mui/material'

// const mockLatestAssignment = {}

const avatarSx = (theme: Theme) => ({
  width: 189,
  height: 189,
  borderRadius: theme.spacing(1),
  border: `4px solid ${theme.palette.secondary['20']}`,
})

export default function ProfileOverviewCard() {
  const { t } = useTranslation()

  const { user, isUserEmailVerified } = useAuthContext()

  const { data: userData } = user

  const fullName = userData?.name || ''
  const profile_photo = userData?.profile_photo || ''
  const email = userData?.email || ''

  return (
    <Box
      sx={(theme) => ({
        boxSizing: 'border-box',
        background: theme.palette.common.white,
        borderRadius: theme.shape.rounded,
        padding: { xs: theme.spacing(2), sm: theme.spacing(4) },
        maxWidth: '1000px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xs: 'column', lg: 'row' },
        gap: theme.spacing(4),
      })}
    >
      <Stack direction="column" spacing={2}>
        {profile_photo ? (
          <Avatar
            src={profile_photo}
            alt="Profile photo"
            variant="square"
            sx={(theme) => ({
              width: 189,
              height: 189,
              borderRadius: theme.spacing(1),
              border: `4px solid ${theme.palette.secondary['20']}`,
            })}
          />
        ) : (
          <Skeleton variant="rectangular" sx={avatarSx} />
        )}
        <Stack direction="column" spacing={1}>
          <Button
            component={Link}
            to={RoutePathNames.consultant.settings}
            id="edit"
            variant="contained"
            color="secondary"
            size="large"
            startIcon={
              <Avatar
                alt=""
                src={pencilIcon}
                sx={{ width: 16, height: 16, borderRadius: '0', mr: '1px' }}
              />
            }
          >
            {t('ProfileCommon.EditProfile')}
          </Button>
          {!isUserEmailVerified && (
            <VerifyEmailDialog
              changeEmailLink={RoutePathNames.consultant.settings}
              email={email}
            />
          )}
        </Stack>
      </Stack>

      <Box sx={{ flexGrow: 1, alignSelf: 'center' }}>
        <Typography variant="h4" mb={2}>
          {fullName ? fullName : <Skeleton />}
        </Typography>
        <Typography component="div" variant="body" mb={1.5}>
          {t('landing.yourSkills')}
        </Typography>
        <SkillList />
      </Box>

      <Box
        sx={(theme) => ({
          flexBasis: { xs: 'unset', lg: '311px' },
          flexShrink: 0,
        })}
      >
        <Typography variant="h5" mb={1.5}>
          {t('landing.yourLatestAssignment')}
        </Typography>
        <Box mt={2}>
          <LatestAssignmentCard />
        </Box>
      </Box>
    </Box>
  )
}
