import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Typography, Button, Stack } from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import * as schemas from 'validation/schemas'
import { useAuthContext } from 'context/auth-context'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import InputField from 'components/ui/FormikFields/InputField'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import PasswordField from 'components/ui/FormikFields/PasswordField'

const validationSchema = Yup.object({
  email: schemas.email(),
  password: Yup.string().required('Registration.EnterPass'),
})

const initialValues = {
  email: '',
  password: '',
} 

export default function Login() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const auth = useAuthContext()

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit(btnRef)

  const onSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      // If the page we're on contains a state with a url in it, redirect to that url after login
      if (previousPath) {
        const { email, password } = values;
        auth.loginAndRedirectToPrevious({email, password, previousPath})
      } else {
        auth.login(values)
      }
      setSubmitting(false)
    } catch (error) {}
  }

  const { t } = useTranslation()

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, dirty, isSubmitting } = formikProps
        return (
          <AuthBoxWrapper>
            <Form onSubmit={handleSubmit}>
              <Typography variant="h3" align="center">
                {t('Registration.LogIn')}
              </Typography>
              <Stack direction="column" mt={4}>
                <Stack direction="column" spacing={1}>
                  <InputField id="email" name="email" label={t('Common.Email')} fullWidth />
                  <PasswordField name="password" label={t('Common.Password')} fullWidth />
                </Stack>
                <span>
                  <Typography
                    component={Link}
                    to={RoutePathNames.auth.forgotPassword}
                    variant="h6"
                    color="primary.60"
                  >
                    {t('Registration.ForgotPassword')}
                  </Typography>
                </span>
              </Stack>
              <Button
                id="login"
                ref={(node) => (btnRef.current = node)}
                sx={{ mt: 4 }}
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={!dirty || isSubmitting}
                onClick={manualSubmitForm(formikProps)}
              >
                {t('Registration.LogIn')}
              </Button>
              <Typography component="div" variant="body" align="center" sx={{ mt: 2 }}>
                {t('Registration.DontHaveAccount')}{' '}
                <Link
                  to={RoutePathNames.auth.welcome}
                  state={{ pathname: previousPath }}
                >
                  <b id="create-account">{t('Registration.CreateOne')}</b>
                </Link>
              </Typography>
            </Form>
          </AuthBoxWrapper>
        )
      }}
    </Formik>
  )
}
