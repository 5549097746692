import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,
  Stack,
  Paper,
} from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import { Assignments } from 'api/assignments/queries'
import { BiTimeFive } from 'react-icons/bi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { format, formatDistanceToNow } from 'date-fns'
import ApplicantList from 'components/common/ApplicantList'

export default function AssignmentCardLanding(props: Assignments[0]) {
  const [isHovered, setIsHovered] = useState(false);

  const { assignment_id, body, applicants = [] } = props
  const { jobTitle, duration, applicationDeadline, availableFrom, createdAt, location, workLoad } =
    body
  const { t } = useTranslation()

  const notDeletedApplicants = useMemo(
    () => applicants.filter((applicant) => !applicant.is_delete),
    [applicants]
  )

  return (
    <Paper
      elevation={isHovered ? 8 : 2}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={(theme) => ({
        padding: `${theme.spacing(3.375)} ${theme.spacing(2)} ${theme.spacing(2.875)}`,
        borderRadius: theme.shape.rounded,
        bgcolor: 'common.white',
        maxWidth: { xs: '100%', sm: 276 },
        width: '100%',
      })}
    >
      <Link to={`${RoutePathNames.consultant.jobListings}/${assignment_id}`}>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
          }}
          variant="h5"
        >
          {jobTitle}
        </Typography>
        <Box display="flex" mb="11px" alignItems="center" gap={1} mt={1.5}>
          <Box display="flex" alignItems="center" gap={0.5} color="secondary.main">
            <HiOutlineLocationMarker className="text-violet-blue" size={16} />
            <Typography variant="body-sm" color="primary.60">
              {location.city && location.city}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1} color="secondary.main">
            <BiTimeFive className="text-violet-blue" size={16} />
            <Typography variant="body-sm" color="primary.60">
              {workLoad && workLoad === 100 ? t('landing.fullTime') : t('landing.partTime')}
            </Typography>
          </Box>
        </Box>

        <Box mt="5px" mb="11px" display="flex" flexWrap="wrap" alignItems="center" gap={1}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="body-sm" fontWeight={400} color="primary.80">
              {t('landing.start')}
            </Typography>
            <Typography variant="body-sm" color="primary.60">
              {availableFrom && format(new Date(availableFrom), 'yyyy-MM-dd')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="body-sm" fontWeight={400} color="primary.80">
              {t('landing.duration')}
            </Typography>
            <Typography variant="body-sm" color="primary.60">
              {duration && t('CompanyLanding.MonthsCount', { number: duration })}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" mb="11px" alignItems="center" gap={0.5}>
          <Typography variant="body-sm" fontWeight={400} color="primary.80">
            {t('landing.published')}
          </Typography>
          <Typography variant="body-sm" color="primary.60">
            {createdAt && formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
          </Typography>
        </Box>

        <Box display="flex" mb="11px" alignItems="center" gap={0.5}>
          <Typography variant="body-sm" fontWeight={400} color="primary.80">
            {t('landing.applicationDeadline')}
          </Typography>
          <Typography variant="body-sm" color="primary.60">
            {applicationDeadline && format(new Date(applicationDeadline), 'yyyy-MM-dd')}
          </Typography>
        </Box>
      </Link>

      <Stack
        direction="row"
        pt="10px"
        alignItems="center"
        justifyContent='space-between'
      >
        {notDeletedApplicants.length > 0 ? (
          <ApplicantList
            applicants={notDeletedApplicants}
            max={{ xs: 5, sm: 7 }}
            size="small"
            title={t('landing.applicants', { count: notDeletedApplicants.length })}
          />
        ) : (
          <Typography variant="body-sm" fontWeight={500} flexGrow={1}>
            {t('landing.noApplicants')}
          </Typography>
        )}
        <Link to={`${RoutePathNames.company.assignment.edit.base}/${assignment_id}`}>
          <Button
            id={`assignment-${assignment_id}-edit`}
            variant="contained"
            color="secondary"
            sx={{ fontSize: '12px' }}
          >
            {t('CompanyLanding.EditBtnAssignment')}
          </Button>
        </Link>
      </Stack>
    </Paper>
  )
}
