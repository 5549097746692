import React from 'react'
import { useConsultantCvQuery } from 'api/cvs/queries'
import Profile from 'components/common/Profile'

export default function ConsultantProfile() {
  const { data } = useConsultantCvQuery()
  const userCvsBody = data?.body || {}
  // @ts-ignore
  return <Profile {...userCvsBody} />
}
