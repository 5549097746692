export const WORKLOAD_MIN = 10;
export const WORKLOAD_MAX = 100;
export const DISTANCE_TO_WORK = 100;
export const HOURLY_RATE = 0;
export const REMOTE_DAYS_PER_WEEK_MIN = 0;
export const REMOTE_DAYS_PER_WEEK_MAX = 5;
export const COMPANY_NAME = "";
export const ONLY_MANAGER_CONSULTANTS = true;
export const AVAILABLE_FROM = null;
export const PUBLISH_DATE = 0;
export const LOCATION_LAT = null;
export const LOCATION_LNG = null;
export const LOCATION_CITY = "";
export const CONSULTANT_ID = null;
export const ASSIGNMENT_ID = null;
