import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FastFieldProps, useField } from 'formik'
import { DatePicker } from '@mui/x-date-pickers'
import { Typography, TextField } from '@mui/material'
import { removeTime } from 'api/utils'

type DatePickerComponentProps = Omit<FastFieldProps, 'form'> &
  Omit<SearchDatePickerFieldProps, 'name'> & {
    onChange: (newValue: Date | null) => void
  }

function DatePickerComponent({
  id,
  field,
  meta,
  label = '',
  inputFormat = 'yyyy-MM-dd',
  minDate = new Date(new Date().setHours(0,0,0,0)),
  maxDate = new Date('2099-12-31'),
  disablePast = false,
  fullWidth = false,
  helperText = ' ',
  onChange,
}: DatePickerComponentProps) {
  const error = meta.touched && meta.error

  const { t } = useTranslation()

  return (
    <DatePicker
      label={label}
      inputFormat={inputFormat}
      minDate={minDate}
      maxDate={maxDate}
      disablePast={disablePast}
      value={field.value}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            id={id}
            name={field.name}
            onBlur={field.onBlur}
            error={Boolean(error)}
            helperText={error ? t(`${error}`) : helperText}
            fullWidth={fullWidth}
          />
        )
      }}
    />
  )
}

type SearchDatePickerFieldProps = {
  name: string
  id: string
  label?: string
  customLabel?: string
  inputFormat?: string
  minDate?: Date
  maxDate?: Date
  disablePast?: boolean
  fullWidth?: boolean
  helperText?: string
}

export default function SearchDatePickerField({
  name,
  id,
  customLabel = '',
  ...restProps
}: SearchDatePickerFieldProps) {
  const [field, meta, helpers] = useField({ name });

  const onChange = useCallback(
    (newValue: Date | null) => {
      helpers.setValue( newValue ? 
        removeTime(newValue) :
        null
      );
    },
    [helpers]
  );

  return (
    <>
      {customLabel && (
        <Typography variant="h5" mb={1.5}>
          {customLabel}
        </Typography>
      )}
      <DatePickerComponent id={id} field={field} meta={meta} onChange={onChange} {...restProps} />
    </>
  )
}
