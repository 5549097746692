import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDistanceToNow } from 'date-fns'
import { Avatar, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BiTimeFive } from 'react-icons/bi'
import { useLatestAssignmentsQuery } from 'api/user/queries'
import RoutePathNames from 'routes/routePathNames'

export default function LatestAssignmentCard() {
  const { t } = useTranslation()
  const latestAssignments = useLatestAssignmentsQuery()
  const { data: latestAssignmentData } = latestAssignments

  if (!latestAssignmentData) {
    return <Typography>{t('landing.youDontHaveAssignments')}</Typography>
  }

  return (
    <Box
      sx={(theme) => ({
        padding: '20px 16px 30px',
        background: theme.palette.secondary[108],
        borderRadius: theme.shape.rounded,
      })}
    >
      <Typography variant="h5" color="common.black" gutterBottom>
        {latestAssignmentData?.body.jobTitle}
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Box display="flex" alignItems="center" gap={0.5}>
          <HiOutlineLocationMarker className="text-violet-blue" size={16} />
          <Typography variant="body-sm" color="primary.60">
            {latestAssignmentData?.body.location.city}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <BiTimeFive className="text-violet-blue" size={16} />
          <Typography variant="body-sm" color="primary.60">
            {latestAssignmentData?.body.workLoad && latestAssignmentData?.body.workLoad === 100
              ? t('landing.fullTime')
              : t('landing.partTime')}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'normal',
          maxWidth: '279px',
          WebkitLineClamp: '4',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          fontSize: '12px',
          color: '#6a6b7f',
          fontWeight: '300',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        {latestAssignmentData?.body.summary}
      </Typography>
      <Box display="flex" flexWrap="nowrap" alignItems="center" gap={1}>
        <Avatar
          src={latestAssignmentData?.body.profile_photo}
          alt="Company logo"
          variant="square"
          sx={(theme: { shape: { rounded: any } }) => ({
            width: '28px',
            height: '28px',
            borderRadius: theme.shape.rounded,
          })}
        />
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          gap={0.5}
          fontSize={10}
          color="text.secondary"
          lineHeight="110%"
        >
          <Box display="flex" flexDirection={'column'} gap={0.5}>
            <Box
              component="strong"
              color="common.black"
              flexShrink={1}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '130px',
              }}
            >
              {latestAssignmentData?.body.name.raw},
            </Box>{' '}
            {latestAssignmentData?.body.availableFrom ? (
              <span style={{ flexShrink: '0' }}>
                {formatDistanceToNow(new Date(latestAssignmentData?.body.availableFrom), {
                  addSuffix: true,
                })}
              </span>
            ) : null}
          </Box>
        </Box>
        <Box flexGrow={0}>
          <Link
            to={`${RoutePathNames.consultant.jobListings}/${latestAssignmentData?.assignment_id}`}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ fontSize: '12px', lineHeight: '100%', p: '13px 0', minWidth: '89px' }}
            >
              {t('landing.viewDetails')}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
