import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../context/auth-context'
import RoutePathNames from 'routes/routePathNames'

export default function RequireAdmin({ children }: { children: JSX.Element }) {
  const auth = useAuthContext()
  const {
    user: { data: userData },
    isUserRoleAdmin
  } = auth

  if (userData && !isUserRoleAdmin) {
    return <Navigate to={RoutePathNames.base} replace />
  }

  return children
}
