import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom';
import { useSendVerifyEmailRequestMutation } from 'api/user/mutations';
import { enqueueSnackbar } from 'notistack';
import { action } from 'App'
import { useTranslation } from 'react-i18next';
import { getVerifyEmailReminder, setVerifyEmailReminder } from 'api/utils';

type VerifyEmailDialogProps = {
  changeEmailLink: string
  email: string
  notifyIsOpen?: (isOpen: boolean) => void
}

export default function VerifyEmailDialog({ changeEmailLink, email, notifyIsOpen }: VerifyEmailDialogProps) {
  const openOnMount = getVerifyEmailReminder();
  const [open, setOpen] = useState(openOnMount);

  const { t } = useTranslation();
  
  const { data, isLoading, mutateAsync } = useSendVerifyEmailRequestMutation();

  function handleClickOpen() {
    setOpen(true);
  };

  function handleClose() {
    setVerifyEmailReminder(false);
    setOpen(false);
    if (notifyIsOpen) notifyIsOpen(false);
  };

  function continueAndClose() {
    mutateAsync(void {}, {
      onSettled: () => handleClose()
    });
  };

  useEffect(() => {
    if (notifyIsOpen) notifyIsOpen(openOnMount);
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      const { success, msg } = data;
      enqueueSnackbar(msg, { variant: success ? 'success' : 'error', action });
    }
  }, [data])

  return (
    <>
      <Button
        id='verify-email'
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleClickOpen}
      >
        {t('verifyEmail.verifyEmail')}
      </Button>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          onClick={handleClose}
          sx={{
            p: '4px',
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          <CloseIcon id="close" sx={{ fontSize: '18px' }} />
        </IconButton>
        <DialogTitle id="alert-dialog-title" fontSize={16}>
          {t('verifyEmail.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('verifyEmail.contentTxt1')}
            <br/>
            <br/>
            {t('verifyEmail.contentTxt2')}
            <b>{email}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isLoading && <CircularProgress size={16}/>}
          <Button
            disabled={isLoading}
            onClick={continueAndClose}
          >
            {t('verifyEmail.continue')}
          </Button>
          <Button
            disabled={isLoading}
            component={Link}
            to={changeEmailLink}
            onClick={handleClose}
          >
            {t('verifyEmail.changeEmail')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
