import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../context/auth-context'
import RoutePathNames from 'routes/routePathNames'

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuth, isUserRoleConsultant, isUserRoleCompany, isUserRoleManager } = useAuthContext()

  if (!isAuth) {
    return <Navigate to={RoutePathNames.auth.welcome} replace />
  }

  if (location.pathname === RoutePathNames.base) {
    if (isUserRoleConsultant) {
      return <Navigate to={RoutePathNames.consultant.home} replace />
    } else if (isUserRoleCompany) {
      return <Navigate to={RoutePathNames.company.home} replace />
    } else if (isUserRoleManager) {
      return <Navigate to={RoutePathNames.manager.home} replace />
    }
  }

  return children
}
