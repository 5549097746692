import { SxProps, Theme } from "@mui/material";
import { BeforeCapture, DropResult } from "react-beautiful-dnd";

const queryAttr = "data-rbd-draggable-id";

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getListStyle = (theme: Theme, isDraggingOver: boolean): SxProps<Theme> => ({
  background: isDraggingOver ? theme.palette.background.focus : theme.palette.background.zinc,
  paddingTop: 1.5,
  paddingBottom: 0.5,
  paddingX: 1,
  width: '100%',
  position: "relative",
  borderRadius: 1
});

const getDraggedDom = (draggableId: string): HTMLElement | null => {
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery) as HTMLElement;

  return draggedDOM;
};
const minimizeElement = (element: HTMLElement | null, minimizeSize: number) => {
  if (!element) return;

  element.style.maxHeight = minimizeSize + 'px';
}

const expandElement = (element: HTMLElement | null) => {
  if (!element) return;

  element.style.maxHeight = 'none';
}

export const handleBeforeCapture = (before: BeforeCapture, minimizeSize: number) => {
  const draggedDOM = getDraggedDom(before.draggableId);

  minimizeElement(draggedDOM, minimizeSize);
};

export const handleDragEnd = (list: Array<any>, result: DropResult) => {
  const draggedDOM = getDraggedDom(result.draggableId);
  expandElement(draggedDOM);

  if (!result.destination) return null;

  const items = reorder(list, result.source.index, result.destination.index);
  return items;
};
