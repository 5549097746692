import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const ScrollToError = () => {
  const formik = useFormikContext()
  const submitting = formik?.isSubmitting

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]')
    ;(el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [submitting])

  return null
}

export default ScrollToError
