import { useMemo, useCallback } from 'react'
// @ts-ignore
import * as JSURL from 'jsurl'
import { useSearchParams, NavigateOptions } from 'react-router-dom'
import { FormValues } from 'components/Filter';

// 'URLFormValues' is derived from 'FormValues', but overrides the 'availableFrom' property 
// to use a 'number | null' type instead of 'Date | null'. This is useful for URL serialization 
// where Date objects need to be represented as timestamps.
type Override<T, U> = Omit<T, keyof U> & U;

export type URLFormValues = Override<FormValues, {
  availableFrom: number | null;
}>;

/**
 * This custom hook is a wrapper around `useSearchParams()` that parses and
 * serializes the search param value using the JSURL library, which permits any
 * JavaScript value to be safely URL-encoded.
 */
export default function useQueryParam(key: string): [[FormValues | null, number], (newQuery: FormValues | null, page: number, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = useMemo((): [FormValues | null, number] => {
    const rawPage = searchParams.get('page');
    const page = rawPage ? parseInt(rawPage, 10) : 1;

    const rawValue = searchParams.get(key);
    if (!rawValue) {
      return [null, page];
    }
  
    const parsedValue = JSURL.parse(rawValue) as URLFormValues;
    // Convert 'availableFrom' from timestamp back to Date object, if present
    if (parsedValue && typeof parsedValue.availableFrom === 'number') {
      const formValuesWithDate: FormValues = {
        ...parsedValue,
        availableFrom: new Date(parsedValue.availableFrom),
      };
      return [formValuesWithDate, page];
    }
  
    // If parsedValue doesn't need any conversion or if it's null/undefined, return it directly
    return [parsedValue as FormValues, page];
  }, [searchParams, key]);

  const setValue = useCallback(
    (newValue: FormValues | null, page: number, options?: NavigateOptions) => {
      if (newValue) {
        const urlValue: URLFormValues = {
          ...newValue,
          availableFrom: newValue.availableFrom ? newValue.availableFrom.getTime() : null
        };
        setSearchParams(() => {
          const params = new URLSearchParams(searchParams);
          params.set('page', page.toString())
          params.set(key, JSURL.stringify(urlValue));
          return params
        }, options)
      } else {
        setSearchParams(() => {
          const params = new URLSearchParams(searchParams);
          params.set('page', page.toString())
          params.delete(key);
          return params
        })
      }
    }, [searchParams, setSearchParams, key]
  );

  return [value, setValue];
}
