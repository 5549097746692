import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { Localization, enUS } from '@mui/material/locale'
import { createCtx } from './helpers'
import getTheme from '../theme'

const muiThemeLocalizationMap: { [index: string]: Localization } = {
  en: enUS,
}

export type Languages = {
  value: string
  // dir: string;
  label: string
}[]

const [useLanguagesContext, LanguagesContextProvider] = createCtx<Languages>()

export { useLanguagesContext }

// const lngOptions: { [index: string]: { name: string } } = {
//   en: { name: 'languages.en' },
// };

export default function LanguagesProvider({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  const { i18n, t } = useTranslation()

  const { dir, language } = i18n
  // const direction = dir(language);

  // const [languages] = useState<Languages>(() =>
  //   Object.keys(lngOptions).map((lngKey) => ({
  //     value: lngKey,
  //     // dir: i18n.dir(lngKey),
  //     label: t(lngOptions[lngKey].name),
  //   })),
  // );

  const muiThemeLocale = useMemo(() => muiThemeLocalizationMap[language] || enUS, [language])

  const theme = useMemo(
    () => responsiveFontSizes(createTheme(getTheme(), muiThemeLocale)),
    [muiThemeLocale]
  )

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        {/*<LanguagesContextProvider value={languages}>{children}</LanguagesContextProvider>*/}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
