import React from 'react'
import { useParams } from 'react-router-dom'
import { usePublicConsultantCvByIdQuery } from 'api/cvs/queries'
import CvForm from 'components/common/forms/CvForm'
import CenteredProgress from 'components/common/CenteredProgress'

export default function VerifyConsultantCvByManager() {
  const { consultantId } = useParams<{ consultantId: string }>()
  const consultantCv = usePublicConsultantCvByIdQuery(consultantId)

  const { data: userCvData } = consultantCv

  return (userCvData === undefined
    ? <CenteredProgress />
    : <CvForm userCvBody={userCvData?.body} />
  )
}
