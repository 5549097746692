import React from 'react'
import { Navigate } from 'react-router-dom'
import RoutePathNames from './routePathNames'
import Landing from 'pages/consultant/home'
import VerifyConsultantCv from 'pages/consultant/edit-cv'
import ConsultantProfile from 'pages/consultant/profile'
import PublicConsultantProfile from 'pages/consultant/public-profile'
import ConsultantSettings from 'pages/consultant/settings'
import ConsultantJobListings from 'pages/consultant/job-listings'
import UploadAndEditCV from 'pages/consultant/upload-and-edit-cv'

const { home, uploadCvs, editCvs, settings, jobListings, profile, publicProfileId } =
  RoutePathNames.consultant

const consultantPrivateRoutes = [
  {
    index: true,
    path: home,
    label: 'general.home',
    isHeaderLink: true,
    element: <Landing />,
  },
  {
    path: uploadCvs,
    element: <UploadAndEditCV />,
  },
  {
    path: editCvs,
    element: <VerifyConsultantCv />,
  },
  {
    path: profile,
    label: 'consultant.yourProfile',
    isHeaderLink: true,
    element: <ConsultantProfile />,
  },
  {
    path: publicProfileId,
    element: <PublicConsultantProfile />,
  },
  {
    path: settings,
    label: 'general.editCV',
    isHeaderLink: false,
    element: <ConsultantSettings />,
  },
  {
    path: jobListings,
    element: <ConsultantJobListings />,
  },
  {
    path: '',
    element: <Navigate to={home} />,
  },
]

export const getConsultantPrivateRoutes = () => consultantPrivateRoutes

export const getConsultantHeaderLinks = () =>
  getConsultantPrivateRoutes().filter(({ isHeaderLink }) => isHeaderLink)
