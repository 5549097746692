import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { format, formatDistanceToNow } from 'date-fns'
import {
  Container,
  Avatar,
  Box,
  Skeleton,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  Dialog,
  DialogContent
} from '@mui/material'
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close'
import RoutePathNames from 'routes/routePathNames'
import { getWorkloadStatus, getRemoteDaysPerWeekStatus, checkIsSkillPrimary } from 'api/utils'
import { usePartialAssignmentByIdQuery } from 'api/assignments/queries'
import Chip from 'components/ui/Chip'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'
import rolelistIcon from 'static/svg/rolelist.svg'
import { RWebShare } from 'react-web-share'
import IosShareIcon from '@mui/icons-material/IosShare';
import { blurredTextStyles, buttonOverlayStyles } from 'styles/component.styles'
import CoverImage from 'components/common/Cover'

const BlurredText = styled(Typography)(blurredTextStyles);
const ButtonOverlay = styled(Button)(buttonOverlayStyles);

const skeletonSkills = Array.from(new Array(10), (_, i) => ({ name: `${i}` }))

export default function PartialJobDetails() {
  const { pathname } = useLocation();
  const params = useParams<{ jobListingId: string }>()

  const { t } = useTranslation()
  const { data: assignmentData, isLoading } = usePartialAssignmentByIdQuery(params?.jobListingId ?? '')

  const {
    roleDescription = [],
    createdAt,
    duration = 0,
    location,
    jobTitle = '',
    applicationDeadline,
    workLoad = 0,
    remoteDaysPerWeek = 0,
    skills = [],
    totalYearsExperience = 0,
    roles = [],
    availableFrom = new Date().getTime(),
  } = assignmentData || {}
  const city = location?.city || ''
  const available = format(availableFrom ? new Date(availableFrom) : new Date(), 'yyyy-MM-dd')
  const deadline = format(
    applicationDeadline ? new Date(applicationDeadline) : new Date(),
    'yyyy-MM-dd'
  )

  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const roleDescItems = roleDescription.map((role) => (
    <ListItem key={role} dense={false} sx={{ p: 0, mb: '10px', alignItems: 'normal' }}>
      <ListItemIcon sx={{ minWidth: '24px' }}>
        <Avatar
          alt=""
          src={rolelistIcon}
          sx={{ width: 16, height: 16, mt: '2px', borderRadius: '0' }}
        />
      </ListItemIcon>
      <Typography variant="body" lineHeight={1.4} width="100%" sx={{ whiteSpace: 'break-spaces' }}>
        {role}
      </Typography>
    </ListItem>
  ))

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ pb: 10 }}
    >
      <CoverImage />
      <Dialog
        fullWidth
        open={modalIsOpen}
        onClose={closeModal}
        PaperProps={{ sx: { maxWidth: { xs: 440, sm: 520 }, width: '100%', m: 2 } }}
      >
        <DialogContent sx={{ px: { xs: 1, sm: 6 }, py: 4 }}>
          <IconButton
            onClick={closeModal}
            sx={{
              p: 0,
              top: 16,
              right: 16,
              position: 'absolute',
            }}
          >
            <CloseIcon id="close" sx={{ fontSize: '18px' }} />
          </IconButton>
          <Typography variant="h3" align="center" mb={2}>
            {t('Registration.LogInFirst')}
          </Typography>
          <Typography
            variant="body"
            align="center"
            color="text.secondary"
            display="inline-block"
            mb={4}
          >
            {t('Registration.WeAreGlad')}
            <Link
              to={RoutePathNames.auth.welcome}
              state={{ pathname }}
            >
              <b id="create-account">{t('Registration.createOne')}</b>
            </Link>
          </Typography>
          <Box sx={{ maxWidth: '108px', mx: 'auto' }}>
            <Link
              to={RoutePathNames.auth.login}
              state={{ pathname }}
            >
              <Button
                id="login"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
              >
                {t('Registration.LogIn')}
              </Button>
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
      <Container sx={{ mt: { xs: '-50px', sm: '-75px' } }}>
        <Stack spacing={4}>
          <Stack
            direction={{ xs: 'row', md: 'column' }}
            alignItems={{ xs: 'flex-end', md: 'flex-start' }}
            spacing={4}
          >
            <Avatar
              alt="logo"
              src={''}
              sx={{
                width: { xs: 100, sm: 150 },
                height: { xs: 100, sm: 150 },
                borderRadius: 4
              }}
            />
            <BlurredText variant="h4" color="primary" fontWeight="fontWeightBold">
              {t('general.placeholders.Company')}
            </BlurredText>
          </Stack>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={4}
            justifyContent="space-between"
          >
            <Typography variant="h3">{jobTitle ? jobTitle : <Skeleton width={300} />}</Typography>
            <Stack
              direction="row"
              spacing={{ xs: 2, sm: 4 }}
            >
              {!isLoading && (
                <Button fullWidth id="apply" onClick={openModal} variant="contained" color="secondary" size="large">
                  {t('jobListings.apply')}
                </Button>
              )}
              <RWebShare
                data={{
                  text: 'Check out this assignment on benchy.se',
                  url: window.location.href,
                  title: jobTitle,
                }}
              >
                <IconButton>
                  {' '}
                  <IosShareIcon id="share" sx={{ color: '#10122D' }} />
                </IconButton>
              </RWebShare>
            </Stack>
          </Stack>
          <Stack direction="row" mb={7.5} gap={1.5} flexWrap="wrap">
            <ChipWithIcon label={location?.city} img="location" imgType="svg" />
            <ChipWithIcon
              label={workLoad ? t(getWorkloadStatus(workLoad)) : ''}
              img="clock"
              imgType="svg"
            />
            <ChipWithIcon
              label={t(getRemoteDaysPerWeekStatus(remoteDaysPerWeek))}
              img="earth"
              imgType="svg"
            />
          </Stack>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={{ md: 0, xs: 4 }}
            justifyContent="space-between"
          >
            <Box sx={{ pr: { md: '32px', xs: 0 } }} flexGrow={1} maxWidth="752px">
              <Typography variant="h4" mb="16px">
                {t('Common.AboutThisAssignment')}
              </Typography>
              <div style={{ position: 'relative' }}>
                <BlurredText
                  variant="body"
                  sx={{ display: 'block', whiteSpace: 'break-spaces' }}
                  mb="32px"
                >
                  {t('general.placeholders.Lorem')}
                  <br />
                  <br />
                  {t('general.placeholders.Donec')}
                </BlurredText>
                <Link
                  to={RoutePathNames.auth.login}
                  state={{ pathname }}
                >
                  <ButtonOverlay id="login-to-read" sx={{ top: '30%' }} variant="contained" color="secondary" size="large">
                    {t('Registration.LogInToRead')}
                  </ButtonOverlay>
                </Link>
              </div>

              <Typography variant="h4" mb="16px">
                {t('Common.RoleDescription')}
              </Typography>
              <List>{roleDescItems}</List>
            </Box>
            <Stack direction="column" spacing={4}>
              <Box
                sx={(theme) => ({
                  width: { md: 436, xs: '100%' },
                  bgcolor: theme.palette.common.white,
                  borderRadius: theme.shape.rounded,
                  p: 4,
                })}
              >
                <Stack
                  direction="row"
                  mb={1.5}
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" mr="8px">
                    {t('Common.Skills')}
                  </Typography>
                  <Typography variant="body-sm" color="primary.60">
                    {t('Common.SelectedPrimary')}
                  </Typography>
                  <Typography variant="h5" flexGrow={1} textAlign="right" color="#F4B43F">
                  </Typography>
                </Stack>
                <Stack direction="row" mb={3} flexWrap="wrap" gap={1}>
                  {skills.length > 0
                    ? skills.map(({ name, isPrimary }) => (
                      <Chip
                        key={name}
                        variant={checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'}
                        label={name}
                      />
                    ))
                    : skeletonSkills.map(({ name }) => (
                      <Skeleton key={name} width={80}>
                        <Chip label={name} />
                      </Skeleton>
                    ))}
                </Stack>
                <Stack
                  direction="row"
                  mb={1.5}
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" mr="8px">
                    {t('Common.Roles')}
                  </Typography>
                </Stack>
                <Stack direction="row" mb={3} flexWrap="wrap" gap={1}>
                  {roles.map((role) => (
                    <Chip key={role} label={role} />
                  ))}
                </Stack>
                <Stack direction="row" mb={1.5} justifyContent="space-between">
                  <Typography variant="h5">{t('Common.Experience')}</Typography>
                </Stack>
                <Typography variant="body" sx={{ display: 'block' }} mb={4} color="primary.60">
                  {totalYearsExperience ? (
                    totalYearsExperience > 1 ? (
                      t('Common.YearsExp', { years: totalYearsExperience })
                    ) : (
                      t('Common.YearExp', { years: totalYearsExperience })
                    )
                  ) : (
                    <Skeleton width={100} />
                  )}
                </Typography>
                <Stack direction="row" mb={2} alignItems="center">
                  <Avatar
                    alt="small-logo"
                    src={''}
                    sx={{ width: 48, height: 48, borderRadius: '7px', mr: 1.5 }}
                  />
                  <Stack direction="row" color="primary.40" flexWrap="wrap">
                    <BlurredText variant="body" color="primary" fontWeight="fontWeightBold" mr={1}>
                      {t('general.placeholders.Company')}
                    </BlurredText>
                    <Typography fontWeight={300}>
                      {createdAt ? (
                        t('Common.PublishedDaysAgo', {
                          date: formatDistanceToNow(createdAt, { addSuffix: true }),
                        })
                      ) : (
                        <Skeleton width={130} />
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <BlurredText variant="body" color="primary.60">
                  {t('general.placeholders.Description')}
                </BlurredText>
              </Box>
              <Box
                sx={(theme) => ({
                  width: { md: 436, xs: '100%' },
                  bgcolor: theme.palette.common.white,
                  borderRadius: theme.shape.rounded,
                  p: 4,
                })}
              >
                <Stack direction="column" spacing={2}>
                  <Typography variant="body">
                    <Trans i18nKey="general.location" city={city}>
                      <strong></strong>: {{ city }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.assignmentStart" available={available}>
                      <strong></strong>: {{ available }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.assignmentLength" count={duration}>
                      <strong></strong>: {{ duration }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.latestDateToAnswer" deadline={deadline}>
                      <strong></strong>: {{ deadline }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.workLoad" count={workLoad}>
                      <strong></strong>: {{ workLoad }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.workFromHome" count={remoteDaysPerWeek}>
                      <strong></strong>: {{ remoteDaysPerWeek }}
                    </Trans>
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Container>
  )
}
