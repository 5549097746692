import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type ReadMoreType = {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'body'
    | 'inherit'
    | 'body-sm'
    | 'body-xs'
    | undefined
  text: string
  trimText?: number
  color?: string
  lineHeight?: string
  collapseText?: string
  readMoreText?: string
  btnComponent?: 'span' | 'p' | 'div'
  btnVariant?: 'text' | 'outlined' | 'contained' | undefined
  btnColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'cream'
    | undefined
}

function ReadMoreText({
  text,
  variant = 'body1',
  color = 'primary',
  lineHeight = '120%',
  readMoreText = 'ReadMore',
  collapseText = 'Collapse',
  trimText = 150,
  btnComponent = 'span',
  btnVariant = 'text',
  btnColor = 'primary',
}: ReadMoreType) {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)

  return (
    <Typography
      variant={variant}
      color={color}
      sx={{ display: 'block', lineHeight: { lineHeight } }}
    >
      {text.length < trimText ? (
        <>{text}</>
      ) : (
        <>
          {showMore ? text : `${text.substring(0, trimText)}...`}
          <Button
            sx={{ p: '2px 3px', lineHeight: '100%', fontFamily: 'museo-sans, sans-serif' }}
            component={btnComponent}
            variant={btnVariant}
            color={btnColor}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? t(collapseText) : t(readMoreText)}
          </Button>
        </>
      )}
    </Typography>
  )
}

export default ReadMoreText
