import { styled } from '@mui/material'
import PasswordStrengthBar from 'react-password-strength-bar'

const StyledPasswordStrengthBar = styled(PasswordStrengthBar)(({ theme }) => ({
  display: 'flex',
  marginBottom: '18px',
  width: '100%',
  '& > div': {
    width: '100%',
    flexBasis: '0px',
    flexGrow: '1',
    margin: '0 !important',

    '& > div': {
      height: '4px !important',
      borderRadius: '12px',

      '&:last-child': {
        display: 'none',
      },
    },
  },
  '& > p': {
    width: '160px',
    textAlign: 'left !important',
    paddingLeft: '8px',
    margin: '0 !important',
    fontSize: '12px !important',
    lineHeight: '100%',
    color: 'rgba($dark-purple-color, 0.4) !important',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}))

export default StyledPasswordStrengthBar
