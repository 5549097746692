import { styled, Box } from "@mui/material";
// To use the BlurredText component you first need to create it locally in your file:

// const BlurredText = styled(Typography)(blurredTextStyles);
export const blurredTextStyles = `
  color: transparent;
  text-shadow: 0 0 0.5em #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

// To use the ButtonOverlay component you first need to create it locally in your file:
// const ButtonOverlay = styled(Button)(buttonOverlayStyles);
export const buttonOverlayStyles = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const StyledScrollBox = styled(Box)(() => ({
  overflowY: 'auto',
  overscrollBehavior: 'contain',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '5px',
    backgroundColor: '#e5edef',
  },
  '&::-webkit-scrollbar-track:hover': {
    backgroundColor: '#e5edef',
  },
  '&::-webkit-scrollbar-track:active': {
    backgroundColor: '#e5edef',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    backgroundColor: '#b7b7b7',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#b7b7b7',
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: '#b7b7b7',
  },
}));
