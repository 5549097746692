import { useQuery } from 'react-query'
import { api } from '../api'

export type RoleOptions = {
  id: number
  name: string
}[]

export const roleKeys = {
  roles: ['roles'] as const,
}

export const fetchRoleOptions = async (): Promise<RoleOptions> => {
  const response = await api.get('role-data')
  return response.data
}

export const useRoleOptionsQuery = () =>
  useQuery(roleKeys.roles, fetchRoleOptions, {
    refetchOnWindowFocus: false,
  })
