import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Dropzone, {
  ILayoutProps,
  IFileWithMeta,
  StatusValue
} from 'react-dropzone-uploader'
import { enqueueSnackbar } from 'notistack'
import {
  Container,
  Box,
  Stack,
  Typography,
  LinearProgress,
  useTheme,
} from '@mui/material'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import { CreateCvDto } from 'api/cvs/queries'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import { UseMutationResult } from 'react-query'

const getStatusText = (percent: number) => {
  if (percent === 0) {
    return ''
  }
  if (percent === 100) {
    return 'UploadCv.AlmostComplete'
  }
  if (percent <= 33) {
    return 'UploadCv.Uploading'
  } else if (percent >= 33 && percent <= 66) {
    return 'UploadCv.RunningAI'
  } else {
    return 'UploadCv.ExtractingData'
  }
}

function Layout({ input, dropzoneProps }: ILayoutProps, progress: number, isLoading: boolean) {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;

  const { t } = useTranslation()

  const statusText = getStatusText(progress)

  return (
    <Stack
      spacing={4}
      sx={{
        pointerEvents: isLoading ? 'none' : 'auto',
      }}
    >
      <Typography variant="h3" align="center">
        {t('UploadCv.UploadCv')}
      </Typography>
      <Box sx={{ visibility: isLoading ? 'visible' : 'hidden' }}>
        <Typography component="div" variant="body" color="black" mb={0.5}>
          {statusText ? t(statusText) : <Box sx={{ minHeight: '24px' }} />}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          color="secondary"
          sx={(theme) => ({
            height: '12px',
            borderRadius: theme.shape['rounded-full'],
            '& .MuiLinearProgress-bar': {
              borderRadius: theme.shape['rounded-full'],
            },
          })}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <div id="dropzone" {...dropzoneProps}>{input}</div>
      </Box>
      <Box display="flex" justifyContent="center">
        <Link to={previousPath ? previousPath : '/'}>
          <Typography
            id="skip"
            variant="body"
            color="black"
            sx={{ display: 'flex', alignItems: 'center', gap: '14px' }}
          >
            {t('UploadCv.SkipStep')}
            <TrendingFlatIcon />
          </Typography>
        </Link>
      </Box>
    </Stack>
  )
}

const InputContent = (props: any) => (
  <Typography key={props[0]?.meta?.id || ''} component="div" align="center">
    <Typography variant="h4" color="primary" mb="14px">
      Select a PDF/docx file to upload
    </Typography>
    <Typography component="div" variant="body-sm" color="black">
      Or drag and drop it here
    </Typography>
    {props[0]?.meta?.name && (
      <Typography variant="h6" color="primary" mt="49px">
        {props[0]?.meta?.name}
      </Typography>
    )}
  </Typography>
)

type UploadCvProps = {
  cvUploader: UseMutationResult<CreateCvDto, unknown, FormData, unknown>,
  progress: number
}

const UploadCv = ({ cvUploader, progress }: UploadCvProps) => {

  const { t } = useTranslation()

  const theme = useTheme()

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
    const { file, meta, remove } = fileWithMeta

    if (status === 'done') {
      const body: FormData = new FormData
      body.append('file', file)

      cvUploader.mutate(body, {
        onSuccess: () => {
          enqueueSnackbar(t('UploadCv.statusDone', { name: meta.name }), { variant: 'success' })
          remove()
        },
      })
    } else if (status === 'rejected_file_type') {
      enqueueSnackbar(t('UploadCv.statusRejectedFileType'), { variant: 'error' })
    } else if (status === 'error_file_size') {
      enqueueSnackbar(t('UploadCv.statusErrorFileSize'), { variant: 'error' })
      remove()
    } else if (status === 'exception_upload') {
      enqueueSnackbar(t('UploadCv.statusAiIsResting'), { variant: 'info' })
      remove()
    } else if (status !== 'removed') {
      remove()
    }
  }

  return (
    <Container maxWidth="sm">
      <AuthBoxWrapper>
        <Dropzone
          maxFiles={1}
          multiple={false}
          maxSizeBytes={5e+6}
          onChangeStatus={handleChangeStatus}
          accept=".pdf, .docx"
          styles={{
            dropzone: {
              minHeight: 231,
              border: `1px dashed ${theme.palette.secondary['40']}`,
              overflow: 'hidden',
            },
            dropzoneActive: {},
            dropzoneReject: {},
            dropzoneDisabled: {},
            inputLabelWithFiles: {
              padding: theme.spacing(2),
              margin: 0,
              width: '100%',
              flexGrow: 1,
              backgroundColor: theme.palette.common.white,
            },
          }}
          LayoutComponent={(props: ILayoutProps) => Layout(props, progress, cvUploader.isLoading)}
          inputContent={InputContent}
          inputWithFilesContent={InputContent}
        />
      </AuthBoxWrapper>
    </Container>
  )
}

export default UploadCv
