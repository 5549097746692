import React, { useState } from 'react'
import CvForm from 'components/common/forms/CvForm'
import { useUploadCvMutation } from 'api/cvs/mutations'
import UploadCv from 'components/common/upload-cv'

export default function UploadAndEditCV() {
  const [progress, setProgress] = useState<number>(0);
  const cvUploader = useUploadCvMutation((progress: number) => setProgress(progress))

  return (cvUploader.data === undefined
    ? <UploadCv cvUploader={cvUploader} progress={progress} />
    : <CvForm userCvBody={cvUploader.data.cvsBody} fileName={cvUploader.data.fileName} />
  )
}
