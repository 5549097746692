import * as React from 'react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

type AlertDialogProps = {
  DialogProps?: Partial<DialogProps>
  open: boolean
  title: string
  text?: string
  onClose?: () => void
  onOk?: () => void
  okText?: string
  closeText?: string
  loading?: boolean
}

export default function AlertDialog({
  open,
  title,
  text,
  onClose,
  onOk,
  okText = 'Ok',
  closeText = 'Cancel',
  loading,
  DialogProps,
}: AlertDialogProps) {
  const handleClose = () => {
    if (!loading && onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...DialogProps}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography component="div" variant="h4">
          {title}
        </Typography>
      </DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} size="large" disabled={loading}>
          {closeText}
        </Button>
        <LoadingButton size="large" loading={loading} onClick={onOk}>
          {okText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
