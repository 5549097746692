import React from 'react'
import { Navigate } from 'react-router-dom'
import RoutePathNames from './routePathNames'
import CompanyLanding from 'pages/company/home'
import CompanySettings from 'pages/company/settings'
import CompanyProfile from 'pages/company/profile'
import SetUpCompanyProfile from 'pages/company/setup-profile'
import AssignmentLayout from 'layouts/AssignmentLayout'
import CreateEditAssignment from 'pages/company/assignment/create'
import PreviewAssignment from 'pages/company/assignment/preview'
import ConsultantListings from 'pages/company/consultant-listings'

const { home, settings, profileByUserId, setupProfile, assignment, consultantListings } =
  RoutePathNames.company

const companyPrivateRoutes = [
  {
    index: true,
    path: home,
    label: 'general.home',
    isHeaderLink: true,
    element: <CompanyLanding />,
  },
  {
    path: settings,
    label: 'general.settings',
    isHeaderLink: true,
    element: <CompanySettings />,
  },
  {
    path: profileByUserId,
    element: <CompanyProfile />,
  },
  {
    path: setupProfile,
    element: <SetUpCompanyProfile />,
  },
  {
    path: assignment.base,
    element: <AssignmentLayout />,
    children: [
      {
        path: assignment.create,
        element: <CreateEditAssignment />,
      },
      {
        path: assignment.preview,
        element: <PreviewAssignment />,
      },
      {
        path: assignment.edit.assignmentId,
        element: <CreateEditAssignment />,
      },
      {
        path: '',
        element: <Navigate to={assignment.create} />,
      },
    ],
  },
  {
    path: consultantListings,
    element: <ConsultantListings />,
  },
  {
    path: '',
    element: <Navigate to={home} />,
  },
]

export const getCompanyPrivateRoutes = () => companyPrivateRoutes

export const getCompanyHeaderLinks = () =>
  getCompanyPrivateRoutes().filter(({ isHeaderLink }) => isHeaderLink)
