import React from 'react'
import { Navigate } from 'react-router-dom'
import RoutePathNames from './routePathNames'
import ManagerLanding from 'pages/manager/home'
import ManagerProfile from 'pages/manager/profile'
import ManagerSettings from 'pages/manager/settings'
import SetUpManagerProfile from 'pages/manager/setup-profile'
import VerifyConsultantCvByManager from 'pages/manager/edit-cv'
import ManagerConsultantListings from 'pages/manager/consultant-listings'
import ConsultantJobListings from 'pages/consultant/job-listings'
import UploadAndEditCV from 'pages/consultant/upload-and-edit-cv'

const {
  home,
  profileByUserId,
  settings,
  setupProfile,
  consultantJobListings,
  consultantListings,
  consultant,
} = RoutePathNames.manager

const managerPrivateRoutes = [
  {
    index: true,
    path: home,
    label: 'general.home',
    isHeaderLink: true,
    element: <ManagerLanding />,
  },
  {
    path: profileByUserId,
    // label: 'general.yourResume',
    // isHeaderLink: true,
    element: <ManagerProfile />,
  },
  {
    path: settings,
    label: 'general.settings',
    // isHeaderLink: true,
    element: <ManagerSettings />,
  },
  {
    path: setupProfile,
    element: <SetUpManagerProfile />,
  },
  {
    path: consultant.create,
    element: <UploadAndEditCV />,
  },
  {
    path: consultant.editConsultantId,
    element: <VerifyConsultantCvByManager />,
  },
  {
    path: consultantJobListings,
    element: <ConsultantJobListings />,
  },
  {
    path: consultantListings,
    element: <ManagerConsultantListings />,
  },
  {
    path: '',
    element: <Navigate to={home} />,
  },
]

export const getManagerPrivateRoutes = () => managerPrivateRoutes

export const getManagerHeaderLinks = () =>
  getManagerPrivateRoutes().filter(({ isHeaderLink }) => isHeaderLink)
