import { useQuery } from 'react-query'
import { api } from '../api'

export type SkillOptions = {
  skill_id: number
  title_skill: string
}[]

export const skillsKeys = {
  skills: ['skills'] as const,
}

export const fetchSkillOptions = async (): Promise<SkillOptions> => {
  const response = await api.get('skills')
  return response.data
}

export const useSkillOptionsQuery = () =>
  useQuery(skillsKeys.skills, fetchSkillOptions, {
    refetchOnWindowFocus: false,
  })
