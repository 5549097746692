import * as Yup from 'yup'

const customMessages = {
  VALID_EMAIL: 'general.errors.validEmail',
  REQUIRED_EMAIL: 'general.errors.email',
  REQUIRED_PASSWORD: 'general.errors.password',
  PASSWORD_MIN_CHARS: 'general.errors.passwordMinChars',
  PASSWORD_MIN_NUM: 'general.errors.passwordMinNum',
  PASSWORD_MIN_LOWERCASE: 'general.errors.passwordMinLowercase',
  PASSWORD_MIN_UPPERCASE: 'general.errors.passwordMinUppercase',
  REQUIRED_ORG_NUMBER: 'Registration.EnterOrgNumber',
  ORG_NUMBER_MUST_BE_NUMBER: 'Common.OrgNumberMustBeNumber',
}

export const email = ({ emailMsg = '', requiredMsg = '' } = {}) =>
  Yup.string()
    .email(emailMsg || customMessages.VALID_EMAIL)
    .required(requiredMsg || customMessages.REQUIRED_EMAIL)

export const requiredPassword = ({
  requiredMsg = '',
  minCharsMsg = '',
  minNumMsg = '',
  minLowercaseMsg = '',
  minUppercaseMsg = '',
} = {}) =>
  Yup.string()
    .required(requiredMsg || customMessages.REQUIRED_PASSWORD)
    .min(8, minCharsMsg || customMessages.PASSWORD_MIN_CHARS)
    .matches(/[0-9]/, minNumMsg || customMessages.PASSWORD_MIN_NUM)
    .matches(/[a-z]/, minLowercaseMsg || customMessages.PASSWORD_MIN_LOWERCASE)
    .matches(/[A-Z]/, minUppercaseMsg || customMessages.PASSWORD_MIN_UPPERCASE)

export const notRequiredPassword = ({
  minCharsMsg = '',
  minNumMsg = '',
  minLowercaseMsg = '',
  minUppercaseMsg = '',
} = {}) =>
  Yup.string()
    .min(8, minCharsMsg || customMessages.PASSWORD_MIN_CHARS)
    .matches(/[0-9]/, minNumMsg || customMessages.PASSWORD_MIN_NUM)
    .matches(/[a-z]/, minLowercaseMsg || customMessages.PASSWORD_MIN_LOWERCASE)
    .matches(/[A-Z]/, minUppercaseMsg || customMessages.PASSWORD_MIN_UPPERCASE)

export const notRequiredOrgNumber = ({ orgNumberMsg = '' } = {}) =>
  Yup.string().matches(/^[\d-]+$/, orgNumberMsg || customMessages.ORG_NUMBER_MUST_BE_NUMBER)

export const requiredOrgNumber = ({ orgNumberMsg = '', requiredMsg = '' } = {}) =>
  notRequiredOrgNumber({ orgNumberMsg }).required(requiredMsg || customMessages.REQUIRED_ORG_NUMBER)
