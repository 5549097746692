import { api } from "api/api"
import { useMutation } from "react-query"

export const aiKeys = {
    ai: ['ai'],
    enhanceResume: () => [...aiKeys.ai, 'enhanceResume'] as const,
    enhanceAssignment: () => [...aiKeys.ai, 'enhanceAssignment'] as const
}

const aiResumeEnhancement = async (body: { prompt: string }): Promise<string> => {
    const response = await api.post(`cvs/enhance`, body)
    return response.data
}
const aiAssignmentEnhancement = async (body: { prompt: string }): Promise<string> => {
    const response = await api.post(`assignments/enhance`, body)
    return response.data
}

export const useAiResumeEnhancementMutation = () =>
    useMutation(aiKeys.enhanceResume(), aiResumeEnhancement);

export const useAiAssignmentEnhancementMutation = () =>
    useMutation(aiKeys.enhanceAssignment(), aiAssignmentEnhancement);
