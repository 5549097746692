import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import { TbCloudUpload } from 'react-icons/tb'
import { Box, Typography } from '@mui/material'
import CoverImage from 'components/common/Cover'

const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

type UploadCoverFieldProps = {
  name: string
  id: string
}

export default function UploadCoverField({ name, id }: UploadCoverFieldProps) {
  const [field, meta, helpers] = useField({ name })
  const inputFileRef: any = useRef(undefined)

  const { t } = useTranslation()

  const uploadImage = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      getBase64(file, (url) => {
        helpers.setValue({ url, file })
      })
    }
  }

  const onDeletePhoto = async () => {
    helpers.setValue({ url: undefined, file: null })
    inputFileRef.current.value = ''
  }

  const error = meta.touched && meta.error

  return (
    <>
      {field.value.url ? (
        <CoverImage src={field.value.url} />
      ) : (
        <Box
          sx={(theme) => ({
            position: 'relative',
            // makes the box always have an aspect ratio of 32:9
            paddingTop: `${9 / 32 * 100}%`,
            borderRadius: '0 0 10px 10px',
            bgcolor: theme.palette.background.zinc,
          })}
        >
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            justifyContent="center"
            alignItems="center"
            fontSize="34px"
            color="secondary.80"
            spacing={2}
          >
            <TbCloudUpload />
            <Typography variant="body-sm" color="primary.60">
              {t('Common.RecommendedSize', { size: '1920x540 (32:9)' })}
            </Typography>
          </Stack>
        </Box>
      )}

      {error && (
        <Typography
          variant="body-sm"
          color="error"
          width="100%"
          display="block"
          sx={{ marginLeft: '14px !important', marginTop: '3px !important', fontWeight: 400 }}
        >
          {
            // @ts-ignore
            t(`${error.url}`)
          }
        </Typography>
      )}

      <Stack direction="row" spacing={1.5} sx={{ pb: '20px', pt: '4px' }}>
        <Button
          id={`${id}-upload`}
          variant="contained"
          size="medium"
          color="secondary"
          component="label"
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          {t('Common.UploadPicture')}
          <input
            hidden
            type="file"
            accept="image/*"
            ref={inputFileRef}
            onChange={(e) => {
              uploadImage(e)
            }}
          />
        </Button>

        <Button
          id={`${id}-remove`}
          variant="outlined"
          size="medium"
          color="secondary"
          onClick={onDeletePhoto}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          {t('general.delete')}
        </Button>
      </Stack>
    </>
  )
}
