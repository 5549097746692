import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik, FormikConfig } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import InputField from 'components/ui/FormikFields/InputField'
import { useAuthContext } from 'context/auth-context'
import UploadLogoField from 'components/ui/FormikFields/UploadLogoField/UploadLogoField'
import UploadCoverField from 'components/ui/FormikFields/UploadCoverField/UploadCoverField'

export const validSchema = Yup.object().shape({
  profile_photo: Yup.object().shape({
    url: Yup.string()
  }),
  cover_picture: Yup.object().shape({
    url: Yup.string()
  }),
})

type SetUpProfileFormInitialValues = {
  profile_photo: { url: string; file: File | null }
  cover_picture: { url: string; file: File | null }
}

export const initValues: SetUpProfileFormInitialValues = {
  profile_photo: { url: '', file: null },
  cover_picture: { url: '', file: null },
}

export default function SetUpProfileForm<T extends typeof initValues>({
  validationSchema = validSchema,
  initialValues = initValues as T,
  onSubmit
}: FormikConfig<T>) {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { isUserRoleManager } = useAuthContext()

  const onSkip = () => {
    if (previousPath) {
      navigate(previousPath);
    } else {
      if (isUserRoleManager) {
        navigate(RoutePathNames.manager.home)
      } else {
        navigate(RoutePathNames.company.home)
      }
    }
  }

  return (
    <Container
      maxWidth="md"
      sx={{ py: 4, px: { xs: 1, sm: 3 }}}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={(theme) => ({
                mx: 'auto',
                bgcolor: theme.palette.common.white,
                borderRadius: theme.shape.rounded,
                p: { xs: 1, sm: 4},
              })}
            >
              <Stack direction="column" spacing={1.5}>
                <Stack>
                  <Typography variant="h3" align="center" mb="16px">
                    {t(
                      isUserRoleManager
                        ? 'manager.setUpProfileTitle'
                        : 'CompanySettings.SetUpPageTitle'
                    )}
                  </Typography>
                  <Typography variant="body" color="primary.60" align="center" mb="20px">
                    {t('CompanySettings.SetUpPageSubTitle')}
                  </Typography>
                </Stack>

                <Typography variant="h5" sx={{ pb: '4px' }}>
                  {t('CompanySettings.LogoTitle')}
                </Typography>

                <UploadLogoField name="profile_photo" id="logo" />

                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="baseline"
                  sx={{ pb: '4px' }}
                  spacing={1}
                >
                  <Typography variant="h5">{t('CompanySettings.CoverTitle')}</Typography>
                </Stack>

                <UploadCoverField name="cover_picture" id="cover" />

                <Typography variant="h5" sx={{ pb: '11px' }}>
                  {t(isUserRoleManager ? 'manager.about' : 'CompanySettings.AboutCompany')}
                </Typography>

                {isUserRoleManager ? (
                  <InputField
                    id="manager-description"
                    name="manager_description"
                    multiline
                    sx={{ mb: '25px' }}
                    label={t('CompanySettings.AboutCompanyDescription')}
                    fullWidth
                  />
                ) : (
                  <>
                    <InputField
                      id="company-description"
                      name="company_description"
                      multiline
                      sx={{ mb: '25px' }}
                      label={t('CompanySettings.AboutCompanyDescription')}
                      fullWidth
                    />
                    <Stack direction="column" spacing={1}>
                      <Typography variant="h4" sx={{ pb: '11px' }}>
                        {t('CompanySettings.CompanyInfo')}
                      </Typography>
                      <InputField id="org-number" name="org_number" label={t('Common.OrgNumber')} fullWidth />
                      <InputField id="address-1" name="address_line" label={t('Common.Address1')} fullWidth />
                      <InputField id="address-2" name="address_line_2" label={t('Common.Address2')} fullWidth />
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 2, sm: 1.5 }}
                      >
                        <InputField id="city" name="city" label={t('Common.City')} fullWidth />
                        <InputField id="zip-code" name="zip_code" label={t('Common.ZipCode')} fullWidth />
                      </Stack>
                      <InputField id="country" name="country" label={t('Common.Country')} fullWidth />
                    </Stack>
                  </>
                )}

                <Stack direction="column" sx={{ mt: '9px' }} spacing={2}>
                  <Button
                    id="submit"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ p: '10px 40px' }}
                    disabled={isSubmitting}
                  >
                    {t('CompanySettings.CompleteProfile')}
                  </Button>

                  <Button id="skip" onClick={onSkip} variant="text" size="large" sx={{ p: '10px 40px' }}>
                    {t('CompanySettings.DoThisLater')}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
