import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import { HiOutlineCamera } from 'react-icons/hi'
import { Typography } from '@mui/material'

const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

type UploadLogoFieldProps = {
  name: string
  id: string
}

export default function UploadLogoField({ name, id }: UploadLogoFieldProps) {
  const [field, meta, helpers] = useField({ name })
  const inputFileRef: any = useRef(undefined)

  const { t } = useTranslation()

  const uploadImage = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      getBase64(file, (url) => {
        helpers.setValue({ url, file })
      })
    }
  }

  const onDeletePhoto = async () => {
    helpers.setValue({ url: undefined, file: null })
    inputFileRef.current.value = ''
  }

  const error = meta.touched && meta.error

  return (
    <Stack direction="row" sx={{ pb: '20px', position: 'relative' }} spacing={4}>
      {field.value.url ? (
        <Avatar
          alt={t('CompanySettings.LogoAlt')}
          src={field.value.url}
          sx={{ width: 120, height: 120, borderRadius: '6px' }}
        />
      ) : (
        <Stack
          sx={(theme) => ({
            width: 120,
            height: 120,
            bgcolor: theme.palette.background.zinc,
            borderRadius: '6px',
          })}
          justifyContent="center"
          alignItems="center"
          fontSize="24px"
          color="secondary.80"
        >
          <HiOutlineCamera />
        </Stack>
      )}
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        alignItems={{ sm: 'center', xs: 'stretch' }}
        justifyContent={{ sm: 'normal', xs: 'center' }}
        flexGrow={1}
        spacing={1.5}
      >
        <Button id={`${id}-upload`} variant="contained" size="medium" color="secondary" component="label">
          {t('Common.UploadPicture')}
          <input
            hidden
            type="file"
            accept="image/*"
            ref={inputFileRef}
            onChange={(e) => {
              uploadImage(e)
            }}
          />
        </Button>

        <Button id={`${id}-remove`} variant="outlined" size="medium" color="secondary" onClick={onDeletePhoto}>
          {t('general.delete')}
        </Button>
      </Stack>

      {error && (
        <Typography
          variant="body-sm"
          color="error"
          width="100%"
          display="block"
          sx={{
            marginLeft: '14px !important',
            marginTop: '0px !important',
            fontWeight: 400,
            position: 'absolute',
            bottom: '-1px',
          }}
        >
          {
            // @ts-ignore
            t(`${error.url}`)
          }
        </Typography>
      )}
    </Stack>
  )
}
