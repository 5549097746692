import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { baseURL } from 'api/api'
import { getAccessToken } from 'api/utils'

export default function useUploadProfileCoverPhotos() {
  const uploadFile = useCallback(async (file: File): Promise<string | null> => {
    try {
      const body = new FormData()
      body.append('file', file)

      const response = await axios({
        method: 'POST',
        url: `${baseURL}/user/upload-avatar`,
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        data: body,
      })
      return response?.data?.fileLink || ''
    } catch (error) {
      // console.log('uploadFile error', error)
    }
    return ''
  }, [])

  const uploadPhoto = useCallback(
    async (
      selectedPhoto: { url: string; file: File | null } = { url: '', file: null },
      dataPhoto: string | null = ''
    ) => {
      const newData: { photo?: string | null } = {}
      try {
        if (selectedPhoto?.url !== dataPhoto && selectedPhoto?.file) {
          newData.photo = await uploadFile(selectedPhoto.file)
        }
      } catch (error) {
        // console.log('error uploadPhoto', error);
      }
      // If the photo remains unchanged returns the old value
      if (newData.photo === undefined) {
        newData.photo = dataPhoto
      }
      return newData
    },
    [uploadFile]
  )

  return useMemo(() => ({ uploadPhoto }), [uploadPhoto])
}
