import React from 'react';
import { Box } from '@mui/material';

type VerifyEmailDialogProps = {
  src?: string | null
}

export default function CoverImage({ src }: VerifyEmailDialogProps) {

  return (
    <Box
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${src})`,
        height: 0,
        // makes the box always have an aspect ratio of 32:9
        paddingTop: `${9 / 32 * 100}%`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'secondary.108',
        borderRadius: '0 0 10px 10px'
      }}
    />
  );
}
