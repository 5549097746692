import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import {
  SelectProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  OutlinedInput,
  SelectChangeEvent,
  Chip,
} from '@mui/material';
import { Options } from 'components/Filter';

const ITEM_HEIGHT = 36
const ITEM_PADDING_Y = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_Y * 2
    }
  }
}

type InputFieldProps = SelectProps & {
  name: string;
  id: string;
  options?: Options;
}

export default function MultipleSelectField({
  name,
  id,
  label,
  fullWidth,
  required,
  options = [],
}: InputFieldProps) {
  const [field, meta, helpers] = useField({ name })
  const { t } = useTranslation()

  const error = meta.touched && meta.error
  // Defaults to a whitespace instead of an empty string so that <FormHelperText> gets rendered,
  // therefore not changing the layout once an error occurs
  const helperText = error ? t(`${error}`) : ' '

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value
    helpers.setValue(typeof value === 'string' ? value.split(',') : value);
  }

  return (
    <FormControl fullWidth={fullWidth} required={required} error={!!error}>
      {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
      <Select
        labelId={`${id}-label`}
        id={id}
        multiple
        value={field.value || []}
        onChange={handleChange}
        input={<OutlinedInput id={`${id}-chip`} label={label} />}
        renderValue={(selectedValues: number[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedValues.map((value: number) => (
              <Chip
                id={value.toString()}
                key={value}
                label={options.find(option => option.value === value)?.label || value}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ fontWeight: field.value?.includes(option.value) ? 'bold' : 'normal' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
