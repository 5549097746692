import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from '../api'

export type Educations = {
  id?: number | string
  organization: string
  accreditation: {
    education: string
  }
  dates: {
    years: string
  }
}[]

export type Skills = {
  id: number
  isPrimary: number
  name: string
}[]

export type WorkExperience = {
  jobTitle: string
  jobDescription: string
  organization: string
  dates: {
    years: string
  }
  id?: number | string
}[]

export type Roles = WorkExperience

export type UserCvsBody = {
  fkUserId?: number | string
  name: { raw: string }
  profession: string
  summary: string
  coordinates?: {
    lat?: number
    lon?: number
  }
  location: {
    city: string
    formatted?: string
  }
  distance?: number
  totalYearsExperience: number
  workLoad: number
  hourlyRate: {
    price: number
  }
  remoteDaysPerWeek: number
  availableFrom: string | number
  education: Educations
  skills: Skills
  roles: string[]
  workExperience: WorkExperience
  languages: string[]
  profile_photo: string | null
}

export type CreateCvDto = {
  cvsBody: UserCvsBody
  fileName: string
}

export type UserCvs = {
  resume_file_name?: string
  cvs_id: number
  body: UserCvsBody
}

export const cvsKeys = {
  cv: (shouldFetch = true) => ['cvs', shouldFetch] as const,
  search: ['search'] as const,
  publicConsultantById: (id: string | number) =>
    [...cvsKeys.cv(), 'public', 'consultant', id] as const,
}

export const fetchUserCvs = async ({
  queryKey,
}: QueryFunctionContext): Promise<UserCvs | undefined> => {
  const shouldFetch = queryKey[1]

  if (shouldFetch) {
    const response = await api.get('cvs')
    return response.data
  }

  return Promise.resolve(undefined)
}

const fetchPublicConsultantCvById = async ({
  queryKey,
}: QueryFunctionContext): Promise<UserCvs | undefined> => {
  const id = queryKey[4]

  if (id) {
    const response = await api.get(`cvs/public/consultant/${id}`)
    return response.data
  }

  return Promise.resolve(undefined)
}

export const transformUserBodyCvs = (data?: UserCvs): UserCvs | undefined => {
  if (data) {
    return {
      cvs_id: data.cvs_id,
      resume_file_name: data.resume_file_name,
      body: data.body,
    }
  }
  return undefined
}

type UseConsultantCvQueryProps = {
  shouldFetch?: boolean
  select?: (data?: UserCvs) => UserCvs | undefined
}

export const useConsultantCvQuery = ({
  select = transformUserBodyCvs,
  shouldFetch = true,
}: UseConsultantCvQueryProps = {}) =>
  useQuery(cvsKeys.cv(shouldFetch), fetchUserCvs, {
    select,
    refetchOnWindowFocus: false,
  })

export const usePublicConsultantCvByIdQuery = (id: string | number = '') =>
  useQuery(cvsKeys.publicConsultantById(id), fetchPublicConsultantCvById, {
    refetchOnWindowFocus: false,
  })
