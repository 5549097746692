import React, { useMemo, useState } from 'react'
import { format } from 'date-fns'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Stack, Typography, ButtonBase, Button, Chip as MuiChip, Paper } from '@mui/material'
import { CvSearchResult } from 'api/cvs/mutations'
import { useAuthContext } from '../../context/auth-context'
import { BiTimeFive } from 'react-icons/bi'
import RoutePathNames from 'routes/routePathNames'
import { getMatchColorStatus, isAvailable, checkAvailability, removeTime, getMatchLabel } from 'api/utils'
import Chip from '../ui/Chip'
import useQueryParam from '../../hooks/useQueryParam'

// eslint-disable-next-line react/display-name
const DivButtonBase = React.forwardRef((props, ref) => (
  // @ts-ignore
  <div role="button" {...props} ref={ref} />
))

type ProfilesCardProps = {
  profile: CvSearchResult['data'][0]
  isSearchView?: boolean
}

export default function ProfileCard({ profile, isSearchView }: ProfilesCardProps) {
  const { pathname, search } = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const auth = useAuthContext()

  const [filter] = useQueryParam('filter')
  const filterAvailableFrom = filter[0]?.availableFrom?.getTime() || removeTime(new Date).getTime()

  const { assignment_match, search_match, fkUserId, profile_photo, name, profession, cvAvailableFrom } =
    useMemo(() => {
      const { profile_photo, fkUserId, name, profession, availableFrom } = profile.body

      // Ensure availableFrom is a valid date
      const validAvailableFrom = availableFrom && !isNaN(new Date(availableFrom).getTime())
        ? new Date(availableFrom)
        : new Date(); // Fallback to current date or handle appropriately

      return {
        profile_photo,
        assignment_match: profile.assignment_match,
        search_match: profile.search_match,
        fkUserId,
        name: name.raw,
        profession,
        cvAvailableFrom: removeTime(validAvailableFrom),
      }
    }, [profile])

  const { t } = useTranslation()

  const availableFrom =
    new Date(cvAvailableFrom).getTime() > new Date().getTime()
      ? format(cvAvailableFrom ? new Date(cvAvailableFrom) : new Date(), 'yyyy-MM-dd')
      : t('ProfileCommon.Now')

  return (
    <Paper
      id={`profile-${fkUserId}`}
      elevation={isHovered ? 8 : 2}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      component={Stack}
      direction="column"
      spacing={1}
      alignItems="center"
      sx={{
        padding: '32px 8px',
        bgcolor: 'common.white',
        maxWidth: { xs: '100%', sm: 276 },
        width: '100%',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Box position="absolute" top="8px" right="8px">
        <MuiChip
          label={checkAvailability(filterAvailableFrom, cvAvailableFrom.getTime())}
          color={isAvailable(filterAvailableFrom, cvAvailableFrom.getTime()) ? 'success' : 'default'}
        />
      </Box>
      <Avatar
        src={profile_photo ?? ''}
        alt="Applicant"
        variant="circular"
        sx={{
          width: 100,
          height: 100,
          m: '0 auto 16px',
        }}
      />

      <Typography variant="h5" lineHeight="125%" mb="4px">
        {name}
      </Typography>

      {(assignment_match !== undefined || search_match !== undefined) && (
        <Stack direction='row' spacing={1}>
          {search_match !== undefined && assignment_match === undefined && (
            <Chip
              label={t('landing.searchMatch', { matchLabel: t(getMatchLabel(search_match)) })}
              variant="custom"
              color={getMatchColorStatus(search_match)}
            />
          )}

          {assignment_match !== undefined && search_match === undefined && (
            <Chip
              label={t('landing.assignmentMatch', { matchLabel: t(getMatchLabel(assignment_match)) })}
              variant="custom"
              color={getMatchColorStatus(assignment_match)}
            />
          )}

          {assignment_match !== undefined && search_match !== undefined && (
            <>
              <Chip
                label={t('landing.searchMatch', { matchLabel: t(getMatchLabel(search_match)) })}
                variant="custom"
                color={getMatchColorStatus(search_match)}
              />
              <Chip
                label={t('landing.assignmentMatch', { matchLabel: t(getMatchLabel(assignment_match)) })}
                variant="custom"
                color={getMatchColorStatus(assignment_match)}
              />
            </>
          )}
        </Stack>
      )}

      <Typography variant="body-sm" color="primary.60" mb="7px" lineHeight="140%" display="block" noWrap width="100%">
        {profession}
      </Typography>

      <Stack
        justifyContent="center"
        direction="row"
        alignItems="center"
        gap={0.5}
        color="secondary.main"
      >
        <BiTimeFive className="text-violet-blue" size={16} />
        <Typography variant="body-sm" fontWeight={400} color="primary.80">
          {t('ProfileCommon.AvailableForWork')}:
        </Typography>
        <Typography variant="body-sm" color="primary.60">
          {availableFrom}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
      >
        <Button
          id={`profile-${fkUserId}-view`}
          variant="contained"
          color="secondary"
          component={Link}
          to={`${RoutePathNames.consultant.publicProfile}/${fkUserId}`}
        >
          {t('general.view')}
        </Button>
        {!isSearchView && (
          <Button
            id={`profile-${fkUserId}-edit`}
            variant="contained"
            color="secondary"
            component={Link}
            to={`${RoutePathNames.manager.consultant.edit}/${fkUserId}`}
            state={{ pathname: pathname + search }}
          >
            {t('general.edit')}
          </Button>
        )}
      </Stack>
    </Paper>
  )
}
