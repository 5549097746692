import React from 'react'
import { Navigate } from 'react-router-dom'
import RoutePathNames from './routePathNames'
import AdminChangeStatus from 'pages/admin'

const { changeAssignmentStatus } = RoutePathNames.admin

const adminPrivateRoutes = [
  {
    index: true,
    path: changeAssignmentStatus,
    label: 'general.admin',
    isHeaderLink: true,
    element: <AdminChangeStatus />,
  },
  {
    path: '',
    element: <Navigate to={changeAssignmentStatus} />,
  },
]

export const getAdminPrivateRoutes = () => adminPrivateRoutes

export const getAdminHeaderLinks = () =>
  getAdminPrivateRoutes().filter(({ isHeaderLink }) => isHeaderLink)
