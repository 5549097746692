// import _ from 'lodash';
import { subDays, subMonths, subWeeks, subYears } from 'date-fns'
import { WorkExperience, Roles as CvsBodyRoles, Skills, Educations } from './cvs/queries'
import { LanguageOptions } from './languages/queries'
import { PUBLISH_DATE } from './constants/filterDefaults'
import { LOCAL_STORAGE_KEY_RELOAD_AFTER, SESSION_STORAGE_KEY_LAST_RELOAD } from 'hooks/useReloadEffect'

export type Role = 'admin' | 'consultant' | 'company' | 'manager'

export type Roles = {
  ADMIN: 'admin'
  CONSULTANT: 'consultant'
  COMPANY: 'company'
  MANAGER: 'manager'
}

export const ROLES: Roles = {
  ADMIN: 'admin',
  CONSULTANT: 'consultant',
  COMPANY: 'company',
  MANAGER: 'manager',
}

export type Error = {
  response: {
    status: number
    statusText: string
    data: {
      error: string
      message: string
    }
  }
}

type getTokenType = () => string | null

export const getAccessToken: getTokenType = () => localStorage.getItem('benchy-token')

export const setAccessToken = (token: string) => localStorage.setItem('benchy-token', token)

export const removeAccessToken = () => {
  localStorage.removeItem('benchy-token')
}
// @ts-ignore
export const getResponseErrorMsg = (error: Error | any): string => {
  const defaultErrorText = 'Something went wrong'
  if (error && 'response' in error) {
    const response = error.response
    const data = response?.data
    return typeof data?.message === 'string'
      ? data.message
      : data?.error || response?.statusText || defaultErrorText
  }

  return defaultErrorText
}
// @ts-ignore
export const getResponseStatus = (error: Error | any): number | undefined => {
  if (error && 'response' in error) {
    return error.response?.status
  }
}
// @ts-ignore
export const isResponseStatusNotFound = (error: Error | any) => {
  return getResponseStatus(error) === 404
}
// @ts-ignore
export const isResponseUnauthorized = (error: Error | any) => {
  return getResponseStatus(error) === 401
}
// @ts-ignore
export const isResponseTimeout = (error: Error | any) => {
  return getResponseStatus(error) === 504
}

// export const getUniqueRoles = (roles: WorkExperience = []) => {
//   if (roles.length === 0) {
//     return [];
//   }
//
//   const uniqueRoles = _.differenceWith(roles, roles, _.isEqual);
//
//   return uniqueRoles.length > 0 ? uniqueRoles : roles[0] ? [roles[0]] : [];
// };

export const getRoleNames = (roles: CvsBodyRoles | { name: string }[] = []) =>
  roles.map((role) => ('jobTitle' in role ? role.jobTitle : role.name))

export const getRoleDescriptionNames = (roleDescriptions: { description: string }[] = []) =>
  roleDescriptions.map((role) => role.description)

export const setRoleDescriptionNames = (roleDescriptions: string[] = []) =>
  roleDescriptions.map((role) => ({ description: role }))

export const getLanguageNames = (languages: LanguageOptions = []) =>
  languages.map((language) => language?.name)
export const setLanguageNames = (languages: string[] = []) =>
  languages.map((language) => ({ name: language }))
export const getDefaultLanguage = (languages: LanguageOptions = [], lng = 'English') =>
  languages.find(({ name }) => name === lng)

export const setRoleNames = (roles: WorkExperience | string[] = []) =>
  roles.map((role) =>
    typeof role === 'object' ? { ...role, name: role?.jobTitle ?? '' } : { name: role }
  )

export const getWorkloadStatus = (workload: number) =>
  workload === 100 ? 'landing.fullTime' : 'landing.partTime'

export const getRemoteDaysPerWeekStatus = (days?: number) => {
  if (days === undefined) {
    return ''
  }
  if (days === 0) {
    return 'general.office'
  } else if (days < 5) {
    return 'general.hybrid'
  } else {
    return 'general.remote'
  }
}

export const removeTime = (date = new Date()): Date =>
  new Date(date.setHours(0, 0, 0, 0));

export const setTime = (date: Date | null = null) => {
  if (date) {
    const newDate = new Date()

    date.setHours(newDate.getHours())
    date.setMinutes(newDate.getMinutes())
    date.setSeconds(newDate.getSeconds())
    date.setMilliseconds(newDate.getMilliseconds())
  }

  return date
}

export const getPublishDateOptions = () => [
  { value: removeTime(subDays(new Date(), 1)).getTime(), label: 'general.last24hrs' },
  { value: removeTime(subWeeks(new Date(), 1)).getTime(), label: 'general.lastWeek' },
  { value: removeTime(subMonths(new Date(), 1)).getTime(), label: 'general.lastMonth' },
  { value: removeTime(subYears(new Date(), 1)).getTime(), label: 'general.lastYear' },
  { value: PUBLISH_DATE, label: 'general.allTime' }
]

const matchBreakpointGreat = 80;
const matchBreakpointGood = 50;
const matchBreakpointPartial = 0;

export const getMatchColorStatus = (percent?: number) => {
  if (percent && percent >= matchBreakpointGreat) {
    return 'success'
  } else if (percent && percent >= matchBreakpointGood) {
    return 'warning'
  } else {
    return 'error'
  }
}

export const getMatchColor = (percent?: number) => {
  if (percent && percent >= matchBreakpointGreat) {
    return '#198B1E'
  } else if (percent && percent >= matchBreakpointGood) {
    return '#F4B43F'
  } else {
    return '#F44A3F'
  }
}

export const getMatchLabel = (percent?: number) => {
  if (percent && percent >= matchBreakpointGreat) {
    return 'landing.great'
  } else if (percent && percent >= matchBreakpointGood) {
    return 'landing.good'
  } else if (percent && percent > matchBreakpointPartial) {
    return 'landing.partial'
  } else {
    return 'landing.no'
  }
}

export const pageLimitCount = 8

export const getPaginationOffset = (page = 1, limit = pageLimitCount) => (page - 1) * limit
export const getPaginationCount = (total: number, limit = pageLimitCount) =>
  Math.ceil(total / limit)

export const testYears = (value: string | undefined) => {
  if (value) {
    const years = value.split('-')
    const startYear = Number.parseInt(years[0], 10)
    const endYear = Number.parseInt(years[1], 10)
    return !!(startYear && endYear && startYear <= endYear)
  }
  return true
}
// @ts-ignore
export const checkIsSkillPrimary = (
  opt: { isPrimary: number | boolean },
  isPrimaryDigit = false
) => {
  if (isPrimaryDigit) {
    if (opt?.isPrimary === 5) {
      return true
    } else if (opt?.isPrimary === 1) {
      return false
    }
  } else {
    return opt?.isPrimary
  }
}

export const sortPrimarySkills = (skills: Skills) =>
  skills.sort((a, b) => b.isPrimary - a.isPrimary)

export const isAvailable = (selectedAvailableFrom: number, userAvailableFrom: number) => {
  return !selectedAvailableFrom || !userAvailableFrom || selectedAvailableFrom >= userAvailableFrom;
}

export const checkAvailability = (filterAvailableFrom: number, userAvailableFrom: number) => {
  if (isAvailable(filterAvailableFrom, userAvailableFrom)) {
    return 'Available'
  } else {
    return 'Not Available'
  }
}


// In some contexts crypto.randomUUID() is not a callable function
export const generateUniqueId = () =>
  (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function')
    ? crypto.randomUUID()
    : Date.now().toString();



export function normalizeWorkExperience(workExperience: WorkExperience | undefined) {
  if (!workExperience || workExperience.length === 0) {
    return [{
      organization: "",
      jobTitle: "",
      jobDescription: "",
      dates: {
        years: ""
      }
    }];
  }

  return workExperience.map(work => ({
    organization: work.organization || "",
    jobTitle: work.jobTitle || "",
    jobDescription: work.jobDescription || "",
    dates: {
      years: work.dates && work.dates.years ? work.dates.years : ""
    }
  }));
}

export function normalizeEducation(educations: Educations | undefined) {
  if (!educations || educations.length === 0) {
    return [{
      organization: "",
      accreditation: {
        education: ""
      },
      dates: {
        years: ""
      }
    }]
  }

  return educations.map(edu => ({
    organization: edu.organization || "",
    accreditation: {
      education: edu.accreditation.education || ""
    },
    dates: {
      years: edu.dates && edu.dates.years ? edu.dates.years : ""
    }
  }));
}

export function setReloadAfter(date: Date) {
  const timestampString = date.getTime().toString();
  localStorage.setItem(LOCAL_STORAGE_KEY_RELOAD_AFTER, timestampString);
}

export function setLastReload(date: Date) {
  const timestampString = date.getTime().toString();
  sessionStorage.setItem(SESSION_STORAGE_KEY_LAST_RELOAD, timestampString);
}

export function shouldReloadPage(): boolean {
  const reloadAfterTimestampString = localStorage.getItem(LOCAL_STORAGE_KEY_RELOAD_AFTER);
  const lastReloadTimestampString = sessionStorage.getItem(SESSION_STORAGE_KEY_LAST_RELOAD);

  const reloadAfterTimestamp = reloadAfterTimestampString ? parseInt(reloadAfterTimestampString, 10) : null;
  const lastReloadTimestamp = lastReloadTimestampString ? parseInt(lastReloadTimestampString, 10) : null;

  return (
    (!!reloadAfterTimestamp && !lastReloadTimestamp) ||
    (!!reloadAfterTimestamp && !!lastReloadTimestamp && lastReloadTimestamp < reloadAfterTimestamp)
  );
}

/**
 * Notifies all other tabs to reload the first time they gain focus after this method is called.
 */
export function notifyReload() {
  const currentTime = new Date();
  setLastReload(currentTime);
  setReloadAfter(currentTime);
}

export function setVerifyEmailReminder(setReminder: boolean) {
  if (setReminder) {
    localStorage.setItem('verifyEmailReminder', 'true');
  } else {
    localStorage.setItem('verifyEmailReminder', 'false');
  }
}

export function getVerifyEmailReminder(): boolean {
  return localStorage.getItem('verifyEmailReminder') === 'true';
}

export function deleteEmptyStringValues(obj: { [key: string]: any }): { [key: string]: any } {
  const newObj: { [key: string]: any } = {};
  
  for (const key in obj) {
    if (obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

export function setCompleteProfileReminder(setReminder: boolean) {
  if (setReminder) {
    localStorage.setItem('completeProfileReminder', 'true');
  } else {
    localStorage.setItem('completeProfileReminder', 'false');
  }
}

export function getCompleteProfileReminder(): boolean {
  return localStorage.getItem('completeProfileReminder') === 'true';
}
