const RoutePathNames = {
  base: '/',
  // about: '/about',
  // story: '/story',
  // career: '/career',
  privacyPolicy: '/privacy-policy',
  termsConditions: '/terms-conditions',
  auth: {
    welcome: '/auth/welcome',
    registrationCompany: '/auth/registration/company',
    registrationConsultant: '/auth/registration/consultant',
    registrationManager: '/auth/registration/manager',
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    verifyEmail: '/auth/verify-email',
    verifyEmailResult: '/auth/verify-email-result'
  },
  admin: {
    base: '/admin',
    changeAssignmentStatus: '/admin/change-assignment-status',
  },
  consultant: {
    base: '/consultant',
    uploadCvs: '/consultant/upload-cv',
    editCvs: '/consultant/edit-cv',
    home: '/consultant/home',
    profile: '/consultant/profile',
    publicProfile: '/consultant/public-profile',
    publicProfileId: '/consultant/public-profile/:userId',
    settings: '/consultant/settings',
    jobListings: '/consultant/job-listings',
    jobListingId: '/consultant/job-listings/:jobListingId',
  },
  company: {
    base: '/company',
    home: '/company/home',
    settings: '/company/settings',
    profile: '/company/profile',
    profileByUserId: '/company/profile/:userId',
    setupProfile: '/company/setup-profile',
    assignment: {
      base: '/company/assignment',
      create: '/company/assignment/create',
      preview: '/company/assignment/preview',
      edit: {
        base: '/company/assignment/edit',
        assignmentId: '/company/assignment/edit/:assignmentId',
      },
    },
    consultantListings: '/company/consultant-listings',
  },
  manager: {
    base: '/manager',
    home: '/manager/home',
    profile: '/manager/profile',
    profileByUserId: '/manager/profile/:userId',
    settings: '/manager/settings',
    setupProfile: '/manager/setup-profile',
    consultantListings: '/manager/consultant-listings',
    consultantJobListings: '/manager/consultant-job-listings',
    consultant: {
      base: '/manager/consultant',
      create: '/manager/consultant/upload-cv',
      edit: '/manager/consultant/edit-cv',
      editConsultantId: '/manager/consultant/edit-cv/:consultantId',
    },
  },
}

export default RoutePathNames
