import { QueryFunctionContext, useQuery } from 'react-query'
import { api } from '../api'
import { TabType } from '../types'
import { useUserQuery } from '../user/queries'
import { Applicants } from 'components/common/ApplicantList'
import { Options } from 'components/Filter'

export type Manager = {
  fk_user_id: number
  manager_id: number
  manager_name: string
  org_number: string
  address_line: string
  address_line_2?: string
  city: string
  zip_code: string
  country: string
  profile_photo: string | null
  cover_picture: string | null
  manager_description: string
  password: string
}

export type ManagerApplyForJob = {
  assignment_id: number;
  consultant_ids: Array<number>;
}

export const managerKeys = {
  manager: ['manager'] as const,
  applyForJob: () => [...managerKeys.manager, 'apply-for-a-job'] as const,
  managerByUserId: (id: string | number = '') => [...managerKeys.manager, 'by-user', id] as const,
  updateConsultantByManager: (consultantId: string) =>
    [...managerKeys.manager, 'update', consultantId] as const,
  search: () => [...managerKeys.manager, 'search'] as const,
  listings: ({
    id,
    tab = 'active',
    page = 1,
    limit,
  }: {
    id: string | number
    tab: TabType
    page: number
    limit: number
  }) => [...managerKeys.manager, id, 'listings', tab, page, limit] as const,
  applicantsByAssignmentId: (id: number | string = '') => [...managerKeys.manager, 'applicants', id] as const,
  deleteConsultant: () => [...managerKeys.manager, 'delete-consultant'] as const,
  createConsultantCvByManager: () => [...managerKeys.manager, 'create-cv'] as const,
}

export const fetchManagerByUserId = async ({
  queryKey,
}: QueryFunctionContext): Promise<Manager | undefined> => {
  const userId = queryKey[2]

  if (userId) {
    const response = await api.get(`manager/by-user/${userId}`)
    return response.data
  }

  return Promise.resolve(undefined)
}

export const useManagerByUserIdQuery = (userId: string | number = '') => {
  const user = useUserQuery()
  return useQuery(
    managerKeys.managerByUserId(userId || user?.data?.user_id),
    fetchManagerByUserId,
    {
      refetchOnWindowFocus: false,
    }
  )
}

const fetchConsultantOptions = async (): Promise<Options> => {
  const response = await api.get(`manager/consultant-options`)
  return response.data
}

export const useConsultantOptionsQuery = (isManager = true) => {
  return useQuery('consultant-options', fetchConsultantOptions, {
    enabled: isManager,
    refetchOnWindowFocus: false
  });
};

const fetchApplicantList = async ({
  queryKey,
}: QueryFunctionContext): Promise<Applicants> => {
  const assignment_id = queryKey[2]

  const response = await api.get(`manager/applicants/${assignment_id}`)
  return response.data
}

export const useManagerApplicantListQuery = (isManager: boolean, assignment_id?: number | string) => {
  return useQuery(managerKeys.applicantsByAssignmentId(assignment_id), fetchApplicantList, {
    enabled: isManager,
    refetchOnWindowFocus: false
  });
};
