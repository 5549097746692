import React from 'react'
import Container from '@mui/material/Container'
import ProfileOverviewCard from 'components/ProfileOverviewCard'
import AssignmentList from 'components/AssignmentList'
import { Stack } from '@mui/material'

export default function Landing() {
  return (
    <Container maxWidth="lg" sx={{ p: { xs: 1, sm: 3 } }}>
      <Stack
        direction="column"
        alignItems="center"
        spacing={4}
      >
        <ProfileOverviewCard />
        <AssignmentList />
      </Stack>
    </Container>
  )
}
