import React from 'react'
import { SxProps } from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

type RoleBoxWrapperProps = {
  avatar: {
    src: string
    alt: string
    sx?: SxProps
  }
  text: string
  isSelected: boolean
  onClick: () => void
  id: string
}

const RoleBox = ({
  avatar: { src, alt, sx = {} },
  text,
  isSelected,
  onClick,
  id
}: RoleBoxWrapperProps) => (
  <ButtonBase
    sx={(theme) => ({
      flexDirection: 'column',
      flex: 1,
      gap: '12px',
      py: 3,
      px: 2,
      border: `1px solid ${
        isSelected ? theme.palette.secondary.main : theme.palette.secondary[20]
      }`,
      borderRadius: theme.shape['rounded-md'],
      '& .MuiTouchRipple-child': {
        backgroundColor: theme.palette.secondary.main, // '#c5cae9',
      },
    })}
    onClick={onClick}
  >
    <Avatar src={src} alt={alt} variant="square" sx={sx} />
    <Typography id={id} variant="h5" align="center">
      {text}
    </Typography>
  </ButtonBase>
)

export default RoleBox
