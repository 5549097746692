import React from 'react'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router'
import RoutePathNames from 'routes/routePathNames'

const AuthBoxWrapper = ({ children }: React.PropsWithChildren) => {
  const location = useLocation()
  const welcomePage = location.pathname === RoutePathNames.auth.welcome;

  return (
    <Box
      sx={(theme) => ({
        mx: { xs: 1, sm: 2, md: 3 },
        my: { xs: 4, sm: 6, md: 8 },
        flexBasis: welcomePage ? 700 : 500,
        maxWidth: welcomePage ? { xs: 'fit-content', sm: 'none' } : 'none',
        bgcolor: theme.palette.common.white,
        borderRadius: theme.shape.rounded,
        px: { xs: 2, sm: 4, md: 6 },
        py: { xs: 4, sm: 6, md: 8 }
      })}
    >
      {children}
    </Box>
  )
}

export default AuthBoxWrapper
