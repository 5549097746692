import { useQuery } from 'react-query'
import { api } from '../api'

export type LanguageOptions = {
  id?: number
  name: string
}[]

export const languageKeys = {
  languages: ['languages'] as const,
}

export const fetchLanguageOptions = async (): Promise<LanguageOptions> => {
  const response = await api.get('language')
  return response.data
}

export const useLanguageOptionsQuery = () =>
  useQuery(languageKeys.languages, fetchLanguageOptions, {
    refetchOnWindowFocus: false,
  })
