import React from 'react'
import { useParams } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';
import { Avatar, Box, Container, Stack, Typography, Skeleton } from '@mui/material'
import { useManagerByUserIdQuery } from 'api/manager/queries'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'

export default function ManagerProfile() {
  const params = useParams<{ userId: string }>()
  const { data: managerData } = useManagerByUserIdQuery(params?.userId)

  // const { t } = useTranslation();

  const { profile_photo, cover_picture, manager_name, manager_description, city, country } =
    managerData || {}

  return (
    <>
      <Box
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${cover_picture ?? ''})`,
          height: '218px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'secondary.108',
        }}
      />

      <Container
        maxWidth="2lg"
        sx={{
          p: { xs: '0px 16px 80px', lg: '0px 80px 160px' },
        }}
      >
        <Box mt="-75px">
          <Box mb={4}>
            <Avatar
              alt="logo"
              src={profile_photo ?? ''}
              sx={{ width: 150, height: 150, borderRadius: 4 }}
            />
          </Box>

          <Typography variant="h2" mb={2}>
            {manager_name ? manager_name : <Skeleton width={200} />}
          </Typography>

          <Box my={4}>
            {city && country ? (
              <ChipWithIcon label={`${city}, ${country}`} img="location" imgType="svg" />
            ) : (
              <Skeleton width={200} />
            )}
          </Box>

          <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 4, md: 0 }} mb="60px">
            <Typography
              variant="body"
              sx={{ display: 'inline-block' }}
              color="primary.60"
              maxWidth={720}
            >
              {manager_description ? (
                manager_description
              ) : (
                <>
                  <Skeleton sx={{ mb: 1 }} width={720} />
                  <Skeleton sx={{ mb: 1 }} />
                  <Skeleton sx={{ mb: 1 }} />
                </>
              )}
            </Typography>
          </Stack>
        </Box>
      </Container>
    </>
  )
}
