import { useQuery, QueryFunctionContext } from 'react-query'
import { api } from '../api'

type PartialAssignmentData = {
  totalYearsExperience: number
  workLoad: number
  skills: { type: string; name: string; isPrimary: boolean }[]
  roles: string[]
  remoteDaysPerWeek: number
  location: { city: string; formatted: string }
  roleDescription: string[]
  applicationDeadline: number
  jobTitle: string
  duration: number
  createdAt: number
  availableFrom: number
}

type AssignmentBody = {
  fkUserId: number
  name: { raw: string }
  summary: string
  totalYearsExperience: number
  workLoad: number
  skills: { type: string; name: string; isPrimary: boolean }[]
  roles: string[]
  remoteDaysPerWeek: number
  location: { city: string; formatted: string }
  coordinates: {
    lat: number
    lon: number
  }
  distance: number
  roleDescription: string[]
  applicationDeadline: number
  languages: string[]
  jobTitle: string
  duration: number
  createdAt: number
  availableFrom: number
  hourlyRate: { price: number; currency: string }
  profile_photo: string
}

type Applicants = {
  user_id: number | string
  email: string
  name: string
  profile_photo: string
  fk_role_id: number | string
  is_delete: boolean
}[]

export type Assignments = {
  assignment_id: number
  consultant_match: number
  title: string
  body: AssignmentBody
  applicants: Applicants
  applied?: boolean
  approved?: boolean
}[]

export type AssignmentsResponse = {
  assignments: Assignments
  paginationParams: {
    offset: number
    limit: number
  }
  total: number
  otherTotal: number
}

export const assignmentsKeys = {
  assignments: ['assignments'],
  search: () => [...assignmentsKeys.assignments, 'search'] as const,
  assignment: (id: string | number) => [...assignmentsKeys.assignments, id] as const,
  approve: () => [...assignmentsKeys.assignments, 'approve'] as const,
}

export const getAssignmentById = async ({
  queryKey,
}: QueryFunctionContext): Promise<Assignments[0] | undefined> => {
  const assignmentId = queryKey[1]

  if (assignmentId) {
    const response = await api.get(`assignments/${assignmentId}`)
    return response.data
  }

  return Promise.resolve(undefined)
}

export const getPartialAssignmentById = async ({
  queryKey,
}: QueryFunctionContext): Promise<PartialAssignmentData | undefined> => {
  const assignmentId = queryKey[1]

  if (assignmentId) {
    const response = await api.get(`assignments/partial/${assignmentId}`)
    return response.data
  }

  return Promise.resolve(undefined)
}

const getAssignments = async (): Promise<Assignments> => {
  const response = await api.get('assignments')
  return response.data
}

export const useAssignmentByIdQuery = (id = '') =>
  useQuery(assignmentsKeys.assignment(id), getAssignmentById, {
    refetchOnWindowFocus: false,
})

export const usePartialAssignmentByIdQuery = (id = '') =>
  useQuery(assignmentsKeys.assignment(id), getPartialAssignmentById, {
    refetchOnWindowFocus: false,
})

export const useAssignmentsQuery = () =>
  useQuery(assignmentsKeys.assignments, getAssignments, {
    refetchOnWindowFocus: false,
})
