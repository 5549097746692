import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Typography, Skeleton, Stack } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { isResponseStatusNotFound, checkIsSkillPrimary, sortPrimarySkills } from 'api/utils'
import { useConsultantCvQuery } from 'api/cvs/queries'
import Chip from '../ui/Chip'

const skeletonData = Array.from(new Array(10), (_, i) => ({ name: `${i}` }))

const MoreSkillsTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    boxShadow: '4px 4px 12px 0px rgba(50, 50, 50, 0.25)',
    color: theme.palette.primary.main,
    maxWidth: 350,
    maxHeight: 300,
    overflowY: 'auto',
    padding: '8px 12px',
    borderRadius: 6,
    border: '1px solid',
    borderColor: '#efeffa',
    marginTop: '10px !important',
    scrollbarWidth: 'thin',
    scrollbarColor: '#b7b7b7 #e5edef',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: '#e5edef',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#e5edef',
    },
    '&::-webkit-scrollbar-track:active': {
      backgroundColor: '#e5edef',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#b7b7b7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#b7b7b7',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#b7b7b7',
    },
    '& button': {
      backgroundColor: '#fff',
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&::before': {
        backgroundColor: '#fff',
        border: '1px solid #efeffa',
      },
    },
  },
}))

export default function SkillList() {
  const { t } = useTranslation()
  const userCvs = useConsultantCvQuery()
  const isStatusNotFound = isResponseStatusNotFound(userCvs.error)
  const userCvsBody = userCvs.data?.body
  const skills = useMemo(
    () => (userCvsBody?.skills ? sortPrimarySkills(userCvsBody.skills) : []),
    [userCvsBody?.skills]
  )

  const renderView = () => {
    if ((!userCvs.isLoading && !skills.length) || isStatusNotFound) {
      return (
        <Typography>
          {t('consultant.noCV')}
        </Typography>
      )
    } else if (userCvs.isLoading && !skills.length) {
      return (
        <SkillsWrapper>
          {skeletonData.map(({ name }) => (
            <Skeleton key={name} width={80}>
              <Chip label={name} />
            </Skeleton>
          ))}
        </SkillsWrapper>
      )
    } else if (userCvs.isSuccess && skills.length <= 5) {
      return (
        <SkillsWrapper>
          {skills.map(({ isPrimary, name }) => (
            <Chip
              key={name}
              variant={checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'}
              label={name}
            />
          ))}
        </SkillsWrapper>
      )
    } else if (userCvs.isSuccess && skills.length > 5) {
      return (
        <SkillsWrapper>
          {skills.slice(0, 5).map(({ isPrimary, name }) => (
            <Chip
              key={name}
              variant={checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'}
              label={name}
            />
          ))}
          <MoreSkillsTooltip
            title={
              <SkillsWrapper>
                {skills.slice(5).map(({ isPrimary, name }) => (
                  <Chip
                    key={name}
                    variant={checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'}
                    label={name}
                  />
                ))}
              </SkillsWrapper>
            }
            placement="bottom-end"
            arrow
          >
            <Typography
              variant="body-sm"
              sx={(theme) => ({
                padding: '4px 8px',
                border: '1px solid rgba(57, 55, 192, 0.2)',
                borderRadius: theme.shape['rounded-md'],
                cursor: 'help',
              })}
            >
              {' '}
              + {skills.slice(5).length} {t('Common.more')}
            </Typography>
          </MoreSkillsTooltip>
        </SkillsWrapper>
      )
    }
    return null
  }

  return <div>{renderView()}</div>
}

function SkillsWrapper({ children }: React.PropsWithChildren) {
  return (
    <>
      <Stack direction="row" sx={{ flexWrap: 'wrap', columnGap: 1, rowGap: '6px' }}>
        {children}
      </Stack>
    </>
  )
}
