import React from 'react';
import CompleteJobDetails from './CompleteJobDetails';
import { useAuthContext } from 'context/auth-context';
import PartialJobDetails from './PartialJobDetails';

export default function JobDetails() {
  const { isAuth } = useAuthContext();

  // If authenticated, redirect to normal job details page, otherwise show partial details page
  if (isAuth) {
    return <CompleteJobDetails />;
  } else {
    return <PartialJobDetails />;
  }
}