import { useMutation } from 'react-query'
import { api } from '../api'
import { pageLimitCount } from '../utils'
import { assignmentsKeys } from './queries'
import { Skills } from 'api/cvs/queries'

export type CreateAssignment = {
  jobTitle: string
  roles: string[]
  skills: { name: string; isPrimary: number; type: string }[]
  languages: string[]
  location: {
    formatted: string
    city: string
  }
  coordinates: {
    lat: number
    lon: number
  }
  availableFrom: number
  hourlyRate: {
    price: number
    currency: string
  }
  summary: string
  workLoad: number
  remoteDaysPerWeek: number
  duration: number
  totalYearsExperience: number
  distance?: number
  roleDescription: string[]
  applicationDeadline: number
  createdAt: number
  fkUserId?: number
}

export type AssignmentSearchParams = {
  consultantId?: number;
  availableFrom?: number;
  workLoad?: Array<number>;
  minHourlyRate?: number;
  minRemoteDaysPerWeek?: number;
  languages?: Array<string>;
  coordinates?: { lat: number; long: number; distance: number };
  skills?: Array<string>;
  roles?: Array<string>;
  companyName?: string;
  publishDate?: number;
};

export type AssignmentSearchResult = {
  data: {
    assignment_id: string
    consultant_match?: number
    search_match?: number
    body: {
      fkUserId: number
      jobTitle: string
      availableFrom: number
      workLoad: number
      remoteDaysPerWeek: number
      roles: string[]
      skills: Skills
      name: { raw: string }
      createdAt: number
      duration: number
      location: { city: string }
      profile_photo: string | null
      profession: string
    }
  }[]
  paginationParams: {
    limit: 10
    offset: 0
    total: number
  }
}

export type ApproveAssignment = {
  consultantId: number
  assignmentId: number
}

const assignmentsSearch = async ({
  body,
  offset = 0,
  limit = pageLimitCount
}: {
  body: AssignmentSearchParams
  offset?: number
  limit?: number
}): Promise<AssignmentSearchResult> => {
  const response = await api.post(`assignments/search?offset=${offset}&limit=${limit}`, body)
  return response.data
}
// @ts-ignore
const createAssignment = async (body: CreateAssignment): Promise<any> => {
  const response = await api.post('assignments', body)
  return response.data
}

const updateAssignmentById =
  (assignmentId: string | number) =>
  // @ts-ignore
  async (body: CreateAssignment): Promise<any> => {
    if (assignmentId) {
      const response = await api.put(`assignments/${assignmentId}`, { body })
      return response.data
    }

    return Promise.resolve(undefined)
  }
// @ts-ignore
const approveAssignment = async (body: ApproveAssignment): Promise<any> => {
  const response = await api.post('assignments/approve', body)
  return response.data
}

export const useAssignmentsSearchMutation = () =>
  useMutation(assignmentsKeys.search(), assignmentsSearch)
export const useCreateAssignmentMutation = () =>
  useMutation(assignmentsKeys.assignments, createAssignment)
export const useUpdateAssignmentMutation = (id: string | number = '') =>
  useMutation(assignmentsKeys.assignment(id), updateAssignmentById(id))
export const useApproveAssignmentMutation = () =>
  useMutation(assignmentsKeys.approve(), approveAssignment)
