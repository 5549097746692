import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useSearchConsultantByManagerMutation } from 'api/manager/mutations'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { StyledPagination } from 'components/common/StyledPagination'
import { getPaginationCount, getPaginationOffset } from 'api/utils'

const pageLimit = 12

type ManagerConsultantListingsProps = {
  assignmentId?: number
  title?: string
}

export default function ManagerConsultantListings({ assignmentId, title }: ManagerConsultantListingsProps) {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const offset = getPaginationOffset(page, pageLimit)

  const {
    isLoading,
    data: { data: consultants, paginationParams } = {},
    mutateAsync,
  } = useSearchConsultantByManagerMutation()

  const body = assignmentId ? { assignmentId } : {}

  useEffect(() => {
    const fetchConsultants = async () => {
      await mutateAsync({ body, offset, limit: pageLimit })
    }
    fetchConsultants()
  }, [offset])

  const total = paginationParams?.total || 0
  const paginationCount = getPaginationCount(total, pageLimit)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : consultants?.length ? (
        <>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            sx={{ mb: 4 }}
          >
            <Typography variant="h3">{title || t('manager.yourConsultants')}</Typography>
            <StyledPagination
              id="pagination"
              page={page}
              count={paginationCount}
              onChange={handleChangePage}
              shape='rounded'
              sx={{ flexShrink: 0 }}
            />
          </Stack>
          <Stack
            gap={2}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            {consultants.map((consultant) => (
              <ProfileCard key={consultant.user_id} profile={consultant} />
            ))}
          </Stack>
        </>
      ) : (
        <Typography>{t('manager.noConsultants')}</Typography>
      )}
    </Box>
  )
}
