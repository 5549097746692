import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, useFormikContext } from 'formik'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { FastTextField } from '../InputField'
import { FastPatternFormatField } from '../PatternFormat'
import { generateUniqueId } from 'api/utils'
import { Box, Paper } from '@mui/material'
import { Educations } from 'api/cvs/queries'
import { BeforeCapture, DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { getListStyle, handleBeforeCapture, handleDragEnd } from './DragAndDropUtils'
import DragHandleIcon from '@mui/icons-material/DragHandle';

type EducationFieldArrayProps = {
  id: string
  topHelperText?: string
}

const minimizeSize = 196;

export default function EducationFieldArray({ id, topHelperText }: EducationFieldArrayProps) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<{ education: Educations }>();

  const onDragEnd = (result: DropResult) => {
    const educationArray = handleDragEnd(values.education, result);

    if (educationArray !== null) {
      setFieldValue('education', educationArray);
    }
  }

  const onBeforeCapture = (before: BeforeCapture) => {
    handleBeforeCapture(before, minimizeSize);
  }

  return (
    // Do not remove this div, it helps with the consistency of DND
    <div>
      <FieldArray
        name="education"
        render={({
          form: {
            values: { education },
          },
          push,
          remove,
        }) => {
          return (
            <DragDropContext
              onDragEnd={onDragEnd}
              onBeforeCapture={onBeforeCapture}
            >
              <Droppable droppableId="education">
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    <Box sx={(theme) => ({...getListStyle(theme, droppableSnapshot.isDraggingOver)})}>
                      {education && education.map((educationItem: Educations[0], index: number) => {
                        if (!educationItem.id) educationItem.id = generateUniqueId();

                        return (
                          <Draggable
                            key={educationItem.id}
                            draggableId={educationItem.id.toString()}
                            index={index}
                            isDragDisabled={education.length <= 1}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <Paper
                                elevation={draggableSnapshot.isDragging ? 20 : 2}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                sx={{
                                  userSelect: 'none',
                                  padding: 1,
                                  mb: 1,
                                  overflow: 'hidden'
                                }}
                              >
                                <Stack key={educationItem.id} direction="column" spacing={1.5}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    {...draggableProvided.dragHandleProps}
                                    sx={{ height: 36 }}
                                  >
                                    <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', columnGap: 1 }}>
                                      <Typography variant="h5">
                                        {t('VerifyCv.Education')} {index > 0 && index + 1}
                                      </Typography>
                                      {topHelperText && index === 0 && (
                                        <Typography variant="body-sm" color="primary.60">
                                          {topHelperText}
                                        </Typography>
                                      )}
                                    </Box>
                                    {education.length > 1 && (
                                      <>
                                        <DragHandleIcon />
                                        <Button
                                          id={`${id}-${index}-remove`}
                                          startIcon={<RemoveIcon />}
                                          color="secondary"
                                          onClick={() => remove(index)}
                                        >
                                          {t('Common.Remove')}
                                        </Button>
                                      </>
                                    )}
                                  </Stack>
                                  <FastTextField
                                    name={`education.${index}.organization`}
                                    id={`${id}-${index}-school`}
                                    label={t('VerifyCv.School')}
                                    fullWidth
                                  />
                                  <FastTextField
                                    name={`education.${index}.accreditation.education`}
                                    id={`${id}-${index}-major`}
                                    label={t('VerifyCv.Major')}
                                    fullWidth
                                  />
                                  <FastPatternFormatField
                                    format="#### - ####"
                                    name={`education.${index}.dates.years`}
                                    id={`${id}-${index}-when`}
                                    label={t('VerifyCv.When')}
                                    placeholder="yyyy-yyyy"
                                    fullWidth
                                  />
                                </Stack>
                              </Paper>
                            )}
                          </Draggable>
                        )
                      })}
                      {droppableProvided.placeholder}
                    </Box>
                    <Button
                      id={`${id}-add`}
                      startIcon={<AddIcon />}
                      color="secondary"
                      onClick={() => push(createNewEducation())}
                      sx={{ mt: 1 }}
                    >
                      {t('VerifyCv.AddAnother')}
                    </Button>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
        }}
      />
    </div>
  )
}

export const createNewEducation = () => {
  return {
    id: generateUniqueId(),
    organization: '',
    accreditation: {
      education: '',
    },
    dates: {
      years: '',
    },
  }
}
