import { styled, Pagination } from '@mui/material'

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '.MuiButtonBase-root': {
    width: 38,
    height: 38,
  },
  '.MuiPaginationItem-previousNext': {
    color: theme.palette.secondary.main,
    backgroundColor: 'white',
  },
  '.MuiPaginationItem-page': {
    color: theme.palette.primary[40],
    mx: '1px',
    '&.Mui-selected, &.Mui-selected:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
  },
}))
