import React, { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography, CircularProgress, PaginationItem, Stack } from '@mui/material'
import {
  getPaginationOffset,
  getPaginationCount,
} from 'api/utils'
import {
  AssignmentSearchParams,
  useAssignmentsSearchMutation,
} from 'api/assignments/mutations'
import AssignmentCard from '../AssignmentCard'
import { StyledPagination } from '../common/StyledPagination'
import { getAssignmentSearchBody } from 'pages/consultant/job-listings'
import { SearchType, getDefaultValues } from 'components/Filter'

export default function AssignmentList() {
  const [searchParams] = useSearchParams()
  const parsedPage = Number.parseInt(searchParams.get('page') || '1', 10);
  const page = isNaN(parsedPage) ? 1 : parsedPage;
  const offset = getPaginationOffset(page)

  const {
    isLoading: isSearchDataLoading,
    data: { data: searchData = [], paginationParams } = {},
    mutateAsync,
  } = useAssignmentsSearchMutation()

  const total = paginationParams?.total || 10
  const paginationCount = getPaginationCount(total)

  const onSearch = async (body: AssignmentSearchParams) => {
    await mutateAsync(
      { body, offset },
      {
        onSuccess: () => { },
      }
    )
  }

  // perform search with default search params after converting them to AssignmentSearchBody
  useEffect(() => {
    onSearch(getAssignmentSearchBody(getDefaultValues(SearchType.Assignment)))
  }, [offset])

  const { t } = useTranslation()

  return (
    <Box width='100%'>
      {isSearchDataLoading ? (
        <CircularProgress />
      ) : searchData?.length ? (
        <>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            sx={{ mb: 4 }}
          >
            <Typography variant="h3">
              {t('landing.YourMatchingAssignments')}
            </Typography>
            <StyledPagination
              id="pagination"
              page={page}
              count={paginationCount}
              shape="rounded"
              color="secondary"
              renderItem={(item) => (
                <PaginationItem component={Link} to={`${`?page=${item.page}`}`} {...item} />
              )}
              sx={{ flexShrink: 0 }}
            />
          </Stack>
          <Stack
            gap={2}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            {searchData.map((item) => (
              <AssignmentCard key={item.assignment_id} card={item} />
            ))}
          </Stack>
        </>
      ) : (
        <Typography>{t('landing.noMatchedAssignments')}</Typography>
      )}
    </Box>
  )
}
