import { useMutation } from 'react-query'
import { api } from 'api/api'
import { userKeys, UpdateUser, ApplyForJob, ContactConsultant } from './queries'
import { CreateCvDto } from 'api/cvs/queries'
import { SuccessDto } from 'api/types'

export const updateConsultant = async (user: UpdateUser): Promise<void> => {
  const response = await api.patch('user', user)
  return response.data
}

export const createConsultantCv = async (user: CreateCvDto): Promise<void> => {
  const response = await api.post('user', user)
  return response.data
}

export const applyForJob = async (body: ApplyForJob) => {
  const response = await api.post('user/apply-for-a-job', body)
  return response.data
}

export const contactConsultant = async (body: ContactConsultant) => {
  const response = await api.post('user/contact-consultant', body)
  return response.data
}

export const deleteConsultantProfile = async ({ user_id }: { user_id?: string | number }) => {
  if (user_id) {
    const response = await api.delete('user/consultant')
    return response.data
  }
  return Promise.resolve(undefined)
}

export const sendVerifyEmailRequest = async (): Promise<SuccessDto> => {
  const response = await api.post('user/send-verify-email-request')
  return response.data
}

export const renewToken = async () => {
  const response = await api.get('user/renew-token')
  return response.data
}

export const useUpdateConsultantMutation = () => useMutation(userKeys.user(), updateConsultant)
export const useCreateConsultantCvMutation = () => useMutation(userKeys.createCv(), createConsultantCv)
export const useConsultantApplyForJobMutation = () => useMutation(userKeys.applyForJob(), applyForJob)
export const useContactConsultantMutation = () =>
  useMutation(userKeys.contactConsultant(), contactConsultant)
export const useConsultantDeleteProfileMutation = () =>
  useMutation(userKeys.deleteProfile(), deleteConsultantProfile)
export const useSendVerifyEmailRequestMutation = () =>
  useMutation(userKeys.sendVerifyEmailRequest(), sendVerifyEmailRequest)
export const useRenewTokenMutation = () => useMutation(userKeys.renewToken(), renewToken)
