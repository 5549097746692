import { useMutation } from 'react-query'
import { api } from '../api'
import { companyKeys, UpdateCompany } from './queries'

const updateCompany = async (body: UpdateCompany) => {
  const response = await api.patch('company', body)
  return response.data
}

export const useUpdateCompanyMutation = () => useMutation(companyKeys.company, updateCompany)
