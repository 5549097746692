import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import { format } from 'date-fns'
import { styled, Theme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import RoutePathNames from 'routes/routePathNames'
import { UserCvsBody } from 'api/cvs/queries'
import { useContactConsultantMutation } from 'api/user/mutations'
import { useAuthContext } from 'context/auth-context'
import pencilIcon from 'static/svg/pencil.svg'
import mailIcon from 'static/svg/mail-black.svg'
import Chip from '../ui/Chip'
import ChipWithIcon from '../ui/ChipWithIcon/ChipWithIcon'
import ReadMoreText from '../ReadMoreText/ReadMoreText'
import { checkIsSkillPrimary, sortPrimarySkills } from '../../api/utils'
import AlertDialog from '../ui/AlertDialog/AlertDialog'
import { StyledScrollBox } from 'styles/component.styles'

const MoreChipsTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    boxShadow: '4px 4px 12px 0px rgba(50, 50, 50, 0.25)',
    color: theme.palette.primary.main,
    maxWidth: 350,
    maxHeight: 'none',
    padding: '0px',
    borderRadius: 6,
    border: '1px solid',
    borderColor: '#efeffa',
    marginTop: '10px !important',
    '& button': {
      backgroundColor: '#fff',
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&::before': {
        backgroundColor: '#fff',
        border: '1px solid #efeffa',
      },
    },
  },
}))

const getWorkloadStatus = (workload: any) =>
  workload === 100 ? 'landing.fullTime' : 'landing.partTime'

const avatarSx = (theme: Theme) => ({
  width: 280,
  height: 332,
  borderRadius: '0',
  border: '12px solid',
  borderColor: 'background.color1',
  order: { lg: '2', xs: '1' },
  mt: { lg: '0', xs: '32px' },
})

type ProfileProps = UserCvsBody & {
  manager_email?: string
}

export default function Profile(props: ProfileProps) {
  const { t } = useTranslation()
  const {
    fkUserId,
    skills: profileSkills = [],
    roles = [],
    workLoad = 0,
    remoteDaysPerWeek = 0,
    summary = '',
    totalYearsExperience = 0,
    availableFrom: available,
    distance = 0,
    // hourlyRate,
    workExperience = [],
    education = [],
    languages = [],
    name,
    profile_photo = '',
  } = props || {}

  const availableFrom = 
    new Date(available).getTime() > new Date().getTime()
    ? format(available ? new Date(available) : new Date(), 'yyyy-MM-dd')
    : t('ProfileCommon.Now')

  const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false)

  const navigate = useNavigate()

  const skills = useMemo(
    () => (profileSkills?.length ? sortPrimarySkills(profileSkills) : []),
    [profileSkills]
  )

  const contactConsultant = useContactConsultantMutation()

  const {
    user,
    isUserRoleConsultant,
    isUserRoleCompany,
    isUserRoleManager,
    deleteConsultantProfile,
    deleteConsultantProfileMutation,
  } = useAuthContext()
  const { data: userData } = user

  const fullName = isUserRoleConsultant ? userData?.name : name?.raw || ''
  const user_photo = isUserRoleConsultant ? userData?.profile_photo || '' : profile_photo || ''
  const city = props?.location ? props?.location?.city : ''

  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const confirmAskQuestionModal = async () => {
    if (fkUserId) {
      const body = {
        consultant_id: fkUserId,
      }
      try {
        await contactConsultant.mutateAsync(body, {
          onSuccess: (data) => {
            if (data) {
              if (data?.success) {
                enqueueSnackbar(t('general.mailSent'), { variant: 'success' })
              } else if (!data?.success) {
                enqueueSnackbar(t('general.mailProviderError'), { variant: 'error' })
              }
            }
            closeModal()
          },
          onError: () => {
            closeModal()
          },
        })
      } catch (error: any) {}
    }
  }

  const onAlertDialogOk = () => {
    deleteConsultantProfile(() => {
      enqueueSnackbar(t('general.consultantProfileDeletedByManagerSuccessMsg'), {
        variant: 'success',
      })
      navigate(RoutePathNames.manager.home)
    })
  }

  const onDeleteProfile = () => {
    setIsAlertDialogOpen(true)
  }

  const onAlertDialogClose = () => {
    setIsAlertDialogOpen(false)
  }


  return (
    <>
      <AlertDialog
        open={isAlertDialogOpen}
        title="Are you sure you want to delete the consultant profile?"
        onClose={onAlertDialogClose}
        onOk={onAlertDialogOk}
        loading={deleteConsultantProfileMutation.isLoading}
      />
      {isUserRoleCompany && (
        <Dialog
          maxWidth="xs"
          open={modalIsOpen}
          onClose={closeModal}
          PaperProps={{
            sx: { maxWidth: '460px' },
          }}
        >
          <DialogContent sx={{ p: '60px', position: 'relative' }}>
            <IconButton
              onClick={closeModal}
              sx={{
                p: 0,
                top: 16,
                right: 16,
                position: 'absolute',
              }}
            >
              <CloseIcon id="close" sx={{ fontSize: '18px' }} />
            </IconButton>
            <Typography variant="h3" align="center" mb={2}>
              {t('Common.ContactConsultant')}
            </Typography>
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              display="inline-block"
              mb={4}
            >
              {t('ProfileCommon.ProfileModalText')}
            </Typography>
            <Box sx={{ maxWidth: '108px', mx: 'auto' }}>
              <Button
                id="confirm"
                onClick={confirmAskQuestionModal}
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
              >
                {t('Common.Confirm')}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <Box component="div" sx={{ mb: '80px', pt: "10px" }}
      >
        <Container maxWidth="2lg" sx={{ px: { sm: 10 } }}>
          <Stack
            direction={{ lg: 'row', xs: 'column' }}
            justifyContent="space-between"
            gap={{ lg: '8px', xs: '0' }}
          >
            <Stack
              direction={{ lg: 'row', xs: 'column' }}
              flexWrap="wrap"
              flexGrow={1}
              maxWidth="922px"
              alignContent="baseline"
              sx={{ order: { lg: '1', xs: '2' } }}
            >
              <Typography
                width="100%"
                variant="h3"
                mb={{ lg: '16px', xs: '32px' }}
                mt={{ lg: '80px', xs: '32px' }}
              >
                {fullName ? fullName : <Skeleton width={200} />}
              </Typography>

              <Stack width="100%" direction={{ md: 'row', xs: 'column' }} gap={3}>
                <Box width="50%" flexShrink={1}>
                  <Stack width="100%" direction="row" mb="24px" gap={1.5} flexWrap="wrap">
                    <ChipWithIcon label={city} img="location" imgType="svg" />
                    <ChipWithIcon
                      label={t(getWorkloadStatus(workLoad))}
                      img="clock"
                      imgType="svg"
                    />
                    {remoteDaysPerWeek === 5 && (
                      <ChipWithIcon label={'Remote (Worldwide)'} img="earth" imgType="svg" />
                    )}
                  </Stack>

                  {summary && (
                    <Box pb={4}>
                      <ReadMoreText
                        text={summary}
                        trimText={160}
                        variant="body"
                        lineHeight="137%"
                        color="primary.60"
                        collapseText="ProfileCommon.Collapse"
                        readMoreText="ProfileCommon.ReadMore"
                      />
                    </Box>
                  )}

                  {isUserRoleConsultant && (
                    <Link to={RoutePathNames.consultant.settings}>
                      <Button
                        id="edit"
                        onClick={openModal}
                        variant="contained"
                        color="secondary"
                        sx={{ mb: 4 }}
                        startIcon={
                          <Avatar
                            alt=""
                            src={pencilIcon}
                            sx={{ width: 16, height: 16, borderRadius: '0', mr: '1px' }}
                          />
                        }
                      >
                        {t('consultant.editProfile')}
                      </Button>
                    </Link>
                  )}

                  {isUserRoleCompany && (
                    <Button
                      id="contact"
                      onClick={openModal}
                      variant="outlined"
                      color="primary"
                      size="large"
                      startIcon={
                        <Avatar
                          alt=""
                          src={mailIcon}
                          sx={{ width: 19, height: 16, borderRadius: '0', mr: '1px' }}
                        />
                      }
                      sx={(theme) => ({
                        borderColor: theme.palette.primary[40],
                      })}
                    >
                      {t('Common.ContactConsultant')}
                    </Button>
                  )}
                </Box>
                <Box width="50%" minWidth="xs" pb={{ lg: '0', xs: '32px' }}>
                  <Stack direction="row" mb={1.5} alignItems="baseline" gap={2}>
                    <Stack direction="row" alignItems="baseline">
                      <Typography variant="h5" mr="8px">
                        {t('Common.Skills')}
                      </Typography>
                      <Typography variant="body-sm" color="primary.60">
                        {t('Common.SelectedPrimary')}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={(theme) => ({
                        borderRadius: theme.shape['rounded-md'],
                        border: '1px solid',
                        borderColor: theme.palette.secondary[20],
                      })}
                    >
                      <Typography
                        variant="h5"
                        mr="8px"
                        sx={(theme) => ({
                          backgroundColor: theme.palette.secondary[106],
                          p: '4px 6px',
                          borderRadius: theme.shape['rounded-md'],
                        })}
                      >
                        {totalYearsExperience}+
                      </Typography>
                      <Typography variant="body-sm" color="primary.60" padding="0 12px 0 0">
                        Years of experience
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" mb={4} flexWrap="wrap" gap={1} maxWidth="350px">
                    {skills.length <= 5 ? (
                      skills.map((m: any) => (
                        <Chip
                          key={m.name}
                          variant={checkIsSkillPrimary(m, true) ? 'filled' : 'outlined'}
                          label={m.name}
                        />
                      ))
                    ) : (
                      <>
                        {skills.slice(0, 5).map(({ isPrimary, name }) => (
                          <Chip
                            key={name}
                            variant={
                              checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'
                            }
                            label={name}
                          />
                        ))}
                        <MoreChipsTooltip
                          title={
                            <StyledScrollBox sx={{ maxHeight: 300, padding: '6px 12px' }}>
                              <Stack direction="row" flexWrap="wrap" gap="8px" width="100%">
                                {skills.slice(5).map((m: any) => (
                                  <Chip
                                    key={m.name}
                                    variant={checkIsSkillPrimary(m, true) ? 'filled' : 'outlined'}
                                    label={m.name}
                                  />
                                ))}
                              </Stack>
                            </StyledScrollBox>
                          }
                          placement="bottom-end"
                          arrow
                        >
                          <Typography
                            variant="body-sm"
                            sx={(theme) => ({
                              padding: '4px 8px',
                              border: '1px solid rgba(57, 55, 192, 0.2)',
                              borderRadius: theme.shape['rounded-md'],
                              cursor: 'help',
                            })}
                          >
                            {' '}
                            + {skills.slice(5).length} {t('Common.more')}
                          </Typography>
                        </MoreChipsTooltip>
                      </>
                    )}
                  </Stack>
                  <Stack direction="row" mb={1.5} alignItems="baseline" gap={2}>
                    <Stack direction="row" alignItems="baseline">
                      <Typography variant="h5" mr="8px">
                        {t('Common.Roles')}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" mb={4} flexWrap="wrap" gap={1} maxWidth="350px">
                    {roles.length <= 5 ? (
                      roles.map((role: string) => (
                        <Chip
                          key={role}
                          variant={'filled'}
                          label={role}
                        />
                      ))
                    ) : (
                      <>
                        {roles.slice(0, 5).map((role: string) => (
                          <Chip
                            key={role}
                            variant={'filled'}
                            label={role}
                          />
                        ))}
                        <MoreChipsTooltip
                          title={
                            <StyledScrollBox sx={{ maxHeight: 300, padding: '6px 12px' }}>
                              <Stack direction="row" flexWrap="wrap" gap="8px" width="100%">
                                {roles.slice(5).map((role: string) => (
                                  <Chip
                                    key={role}
                                    variant={'filled'}
                                    label={role}
                                  />
                                ))}
                              </Stack>
                            </StyledScrollBox>
                          }
                          placement="bottom-end"
                          arrow
                        >
                          <Typography
                            variant="body-sm"
                            sx={(theme) => ({
                              padding: '4px 8px',
                              border: '1px solid rgba(57, 55, 192, 0.2)',
                              borderRadius: theme.shape['rounded-md'],
                              cursor: 'help',
                            })}
                          >
                            {' '}
                            + {roles.slice(5).length} {t('Common.more')}
                          </Typography>
                        </MoreChipsTooltip>
                      </>
                    )}
                  </Stack>
                  <Stack direction="row" gap={3.125} mb={4}>
                    <Stack direction="column" alignItems="baseline">
                      <Typography variant="h5" mb={1.5}>
                        {t('ProfileCommon.AvailableForWork')}
                      </Typography>
                      <ChipWithIcon
                        label={availableFrom}
                        img="clock"
                        imgType="svg"
                      />
                    </Stack>
                    <Stack direction="column" alignItems="baseline">
                      <Typography variant="h5" mb={1.5}>
                        {t('ProfileCommon.CommutingDistanceWork')}
                      </Typography>
                      <ChipWithIcon label={`${distance}km`} img="location" imgType="svg" />
                    </Stack>
                  </Stack>
                  {/*<Typography variant="body" mb={1.5} sx={{ display: 'block' }}>*/}
                  {/*  <strong>{t('ProfileCommon.MinimumHourlyRate')}: </strong>{' '}*/}
                  {/*  {t('ProfileCommon.Sekh', { number: hourlyRate?.price })}*/}
                  {/*</Typography>*/}
                </Box>
              </Stack>
            </Stack>
            <Avatar src={user_photo} alt="Profile photo" variant="square" sx={avatarSx} />
            {/*{user_photo ? (*/}
            {/*  <Avatar src={user_photo} alt="Profile photo" variant="square" sx={avatarSx} />*/}
            {/*) : (*/}
            {/*  <Skeleton variant="rectangular" sx={avatarSx} />*/}
            {/*)}*/}
          </Stack>
        </Container>
      </Box>

      <Box px={3.75} mb={16.25} sx={{ maxWidth: 1340, mx: 'auto', width: '100%' }}>
        <Stack direction={{ md: 'row', xs: 'column' }} gap={4}>
          <Box sx={{ flexGrow: 3 }}>
            <Typography variant="h4" sx={{ display: 'block' }} mb={2} lineHeight={{ xs: '137%' }}>
              {t('ProfileCommon.Assignment')}
            </Typography>

            <Box
              sx={(theme) => ({
                backgroundColor: '#fff',
                p: 4,
                borderRadius: theme.shape['rounded-md'],
              })}
            >
              {workExperience.map((m: any) => (
                <div key={m.id}>
                  <Typography
                    variant="h4"
                    sx={{ display: 'block' }}
                    mb={0.75}
                    lineHeight={{ xs: '125%' }}
                  >
                    {m.organization}
                  </Typography>

                  <Typography
                    variant="h5"
                    sx={{ display: 'block' }}
                    mb={0.75}
                    lineHeight={{ xs: '125%' }}
                    fontWeight="fontWeightRegular"
                  >
                    {m.jobTitle}
                  </Typography>

                  <Typography
                    variant="body"
                    sx={{ display: 'block' }}
                    color="secondary.main"
                    mb={1.5}
                    lineHeight={{ xs: '140%' }}
                  >
                    {m.dates?.years}
                  </Typography>

                  <Typography
                    variant="body"
                    sx={{ display: 'block' }}
                    color="primary.60"
                    mb={4}
                    maxWidth="844px"
                    lineHeight={{ xs: '140%' }}
                  >
                    {m.jobDescription}
                  </Typography>
                </div>
              ))}
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} maxWidth={{ lg: 335, md: '35%', xs: '100%' }}>
            {education && (
              <>
                <Typography
                  variant="h4"
                  sx={{ display: 'block' }}
                  mb={2}
                  mt={6}
                  lineHeight={{ xs: '137%' }}
                >
                  {t('ProfileCommon.Education')}
                </Typography>

                <Stack direction="row" gap={4} flexWrap="wrap">
                  {education.map((m: any) => (
                    <Box
                      key={m.id}
                      width={education.length > 1 ? { md: '42%', xs: '30%' } : '100%'}
                    >
                      <Typography
                        variant="h5"
                        sx={{ display: 'block', wordWrap: 'break-word' }}
                        mb={0.75}
                        lineHeight={{ xs: '125%' }}
                      >
                        {m.organization}
                      </Typography>

                      <Typography
                        variant="body"
                        sx={{ display: 'block', wordWrap: 'break-word' }}
                        mb={1.5}
                        lineHeight={{ xs: '137%' }}
                      >
                        {m.accreditation?.education}
                      </Typography>

                      <Typography
                        variant="body"
                        sx={{ display: 'block' }}
                        color="secondary.main"
                        mb={1.5}
                        lineHeight={{ xs: '140%' }}
                      >
                        {m.dates?.years}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </>
            )}

            {languages && (
              <>
                <Typography
                  variant="h4"
                  sx={{ display: 'block' }}
                  mb={2}
                  mt={6}
                  lineHeight={{ xs: '137%' }}
                >
                  {t('ProfileCommon.Languages')}
                </Typography>

                {languages.map((m: any) => (
                  <Typography
                    key={m}
                    variant="body"
                    sx={{ display: 'block' }}
                    color="primary.80"
                    mb={2}
                    lineHeight={{ xs: '140%' }}
                  >
                    {m}
                  </Typography>
                ))}
              </>
            )}

            {isUserRoleManager && userData?.email === props?.manager_email && (
              <Box ml="-11px" mt="48px">
                <Button id="delete" color="error" size="large" onClick={onDeleteProfile}>
                  {t('general.deleteProfile')}
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  )
}
