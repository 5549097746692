import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TabType } from 'api/types'
import { getPaginationOffset } from 'api/utils'
import { tabs } from 'components/common/ListingTabs'

export default function useListingTabsSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = (searchParams.get('tab') || tabs[0].tab) as TabType
  const parsedPage = Number.parseInt(searchParams.get('page') || '1', 10);
  const page = isNaN(parsedPage) ? 1 : parsedPage;
  const offset = getPaginationOffset(page)

  return useMemo(
    () => ({ tab, page, offset, setSearchParams }),
    [tab, page, offset, setSearchParams]
  )
}
