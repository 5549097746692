import React from 'react'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[8],
    fontSize: 11,
    padding: '8px 12px',
    '& .MuiTooltip-arrow:before': {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #dadde9',
    },
  }
}));
