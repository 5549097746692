import React from 'react'
import { useParams } from 'react-router-dom'
import { usePublicConsultantCvByIdQuery } from 'api/cvs/queries'
import Profile from 'components/common/Profile'

export default function PublicConsultantProfile() {
  const { userId } = useParams<{ userId: string }>()
  const { data } = usePublicConsultantCvByIdQuery(userId)
  const body = data?.body || {}
  // @ts-ignore
  return <Profile {...body} />
}
