import { Provider, createContext, useContext } from 'react'

export { createCtx }

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function createCtx<A extends Record<string, unknown> | {} | unknown[] | null>(): readonly [
  () => A,
  Provider<A | undefined>
] {
  const ctx = createContext<A | undefined>(undefined)

  function useCtx(): A {
    const c = useContext(ctx)
    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value')
    }
    return c
  }

  return [useCtx, ctx.Provider] as const // 'as const' makes TypeScript infer a tuple
}
